import { Injectable } from '@angular/core';
import {
	CanActivate,
	UrlTree,
	Router,
	ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@video-sharing/app/common/services/auth/auth.service';
import { ErrorMessages } from '@video-sharing/app/access-denied/access-denied.constants';

@Injectable({
	providedIn: 'root'
})
export class SessionURLGuard implements CanActivate {
	constructor (
		public auth: AuthService,
		private router: Router
	) {}

	// eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
	canActivate (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (route.queryParamMap.get('session') === null) {
			this.router.navigate(['/blocked'], { state: { errorMessage: ErrorMessages.InvalidUrl }});
			return false;
		}

		return true;
	}
}
