<span
	class="dot-indicator"
	*ngIf="shouldShowAttentionNeeded()"
	[style.min-width.px]="size"
	[style.min-height.px]="size"
	[style.max-width.px]="size"
	[style.max-height.px]="size"
	[style.border-radius.px]="size"
	[class.bordered]="hasBorder"
	[ngClass]="[color, getBorderColor(), getAnimation()]"
	[goTooltip]="getTooltip() | translate"
	[attr.aria-label]="getTooltip() | translate"
	>
</span>
