import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@video-sharing/app/common/services/auth/auth.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public session: any;
	public user: any;
	public userGroup: any;
	public options: any;
	public title: string = 'video-sharing';

	constructor (
		public auth: AuthService,
		public router: Router,
		public environmentVarService: EnvironmentVarsService
	) {}

	public ngOnInit(): void {
		this.environmentVarService.set(EnvironmentVarsService.VAR.ENVIRONMENT, {
			name: ENVIRONMENTS.DEFAULT
		});
	}
}
