<card-container
	#cardContainer
	*ngIf="session"
	(contextmenu)="openMenu($event)"
	[attr.aria-label]="cardAriaLabel()"
	class="card-container"
	[ngClass]="{ 'viewed': !unviewedState(session) }"
	(click)="goToSession.emit(session)"
	(keyup.enter)="goToSession.emit(session)"
	data-cy="session-row-or-card"
	[matTooltip]="getTooltip(session)"
	matTooltipClass="formatTooltip"
	tabindex="0">
	<div main class="main">
		<!-- #region Recorded Thumbnail -->
		<ng-container *ngIf="session.isRecorded()">
			<dot-indicator
				[session]="session"
				[size]="12"
				[hasBorder]="true">
			</dot-indicator>

			<div *ngIf="!ngxSessionService.getMedia(session); else hasMediaImg" class="pending-thumbnail">
				<span *ngIf="selectedService.getActivity().isConversationBoard(); else notConversationBoard">
					<img class="logo not-loading"
						alt="GoReact Logo"
						src="https://staticassets.goreact.com/logo-goreact-spot-2022.svg"/>
				</span>
				<ng-template #notConversationBoard>
					<loading [width]="'70px'" [height]="'70px'"></loading>
				</ng-template>
			</div>

			<ng-template #hasMediaImg>
				<thumbnail *ngIf="ngxSessionService.getMedia(session).isReady()"
					data-cy="media-ready"
					[src]="ngxSessionService.getMedia(session).thumbnail_url"
					[duration]="ngxSessionService.getMedia(session).duration"
					[isPractice]="session.isPractice() && selectedService.getActivity().allowsPractice()"
					[fallbackIcon]="'ficon-app-camcorder'"
					class="recorded-thumbnail"
				>
				</thumbnail>

				<div *ngIf="ngxSessionService.getMedia(session).isPending()" class="pending-thumbnail">
					<loading [width]="'70px'" [height]="'70px'"></loading>
				</div>

				<div *ngIf="ngxSessionService.getMedia(session).isFailed()" class="failed-thumbnail">
					<i class="ficon-app-warning-circle-outline"></i>
				</div>
			</ng-template>
		</ng-container>
		<!-- endregion -->

		<!-- #region Live Thumnbnail -->
		<ng-container *ngIf="!session.isRecorded()">
			<dot-indicator
				[session]="session"
				[size]="12"
				[color]="(session.isLive()) ? 'red' : 'green'"
				[hasBorder]="!session.isLive()">
			</dot-indicator>
			<div *ngIf="ngxSessionService.shouldDisplayJoinButton(session)" class="live-thumbnail">
				<button class="join-btn secondary-btn">{{'session-join' | translate}}</button>
			</div>
		</ng-container>
		<!-- endregion -->
	</div>

	<div info-first-line data-cy="session-description">
		{{session.description}}
		<span *ngIf="!session.description?.length">&#8212;</span>
	</div>

	<div info-second-line>
		<ellipsis-with-count [nameList]="nameList"></ellipsis-with-count>
		<span *ngIf="!nameList.length">
			<span
				*ngIf="selectedService.getActivity().isConversationBoard(); else notConversationBoard"
				class="group-discussion"
				translate="filter-presenter-names_group">
			</span>
			<ng-template #notConversationBoard>
				&#8212;
			</ng-template>
		</span>
	</div>

	<div info-third-line>
		<!-- #region Recorded information -->
		<div *ngIf="session.isRecorded()">
			<span *ngIf="!ngxSessionService.getMedia(session); else hasMediaStatus" class="pending-text">
				<span *ngIf="selectedService.getActivity().isConversationBoard(); else noRecording">—</span>
				<ng-template #noRecording>
					<span translate="single-attempt-activity_no-recording" data-cy="allow-retake-link"></span>
				</ng-template>
			</span>

			<ng-template #hasMediaStatus>
				<ng-container *ngIf="ngxSessionService.getMedia(session).isReady()">
					<span *ngIf="session.isPosted()" class="date-posted">
						<div>
							<span translate="session-card_date-posted-label"></span>: {{ "session-card_date-posted" | translate: {"date": session.posted_at | date:'shortDate', "time": session.posted_at | date:'shortTime'} }}
						</div>

						<ng-container *ngIf="ngxSessionService.shouldDisplayScore(session)">
							<span class="middle-dot"></span>
							<span *ngIf="session.isScoreAssigned()" class="score">
								{{session.score}}/{{selectedService.getActivity().getTotalPoints()}}
							</span>

							<span *ngIf="!session.isScoreAssigned()"
								class="unscored"
								translate="common_unscored">
							</span>
						</ng-container>
					</span>

					<span *ngIf="!session.isPosted()"
						class="post-processing-text red-text"
						translate="common_post-recording">
					</span>
				</ng-container>

				<span *ngIf="ngxSessionService.getMedia(session).isPending()" class="pending-text" translate="common_processing_processing" data-cy="processing-text"></span>
				<span *ngIf="ngxSessionService.getMedia(session).isFailed()" class="failed-text red-text" translate="common_processing_failed"></span>
			</ng-template>
		</div>
		<!-- endregion -->

		<!-- #region Live information -->
		<div *ngIf="!session.isRecorded()">
			<span *ngIf="session.isAwaitingStart()" class="awaiting-text" translate="common_processing_awaiting-start"></span>
			<span *ngIf="session.isLive()" class="is-live-text red-text" translate="common_processing_in-progress"></span>
		</div>
		<!-- endregion -->
	</div>

	<div info-third-line-action-btns (keyup.enter)="$event.stopPropagation();">
		<!-- #region Comment Button -->
		<span
		 	class="comment-btn"
			role="button"
			*ngIf="session.num_comments > 0">
			<i class="ficon-app-comment-outline"></i>

			<span class="num-comments">
				{{session.num_comments}}
			</span>
		</span>
		<!-- endregion -->

		<!-- #region Menu Button -->
		<button
			class="menu-btn btn-none"
			data-cy="session-dropdown-menu"
			(click)="openMenu($event)"
			[attr.aria-label]="'session-list-item_view-options' | translate"
			*ngIf="ngxSessionService.shouldDisplayOptionsMenu(session)"
			(keyup.enter)="$event.stopPropagation();">
			<i class="ficon-app-dots-vertical"></i>
		</button>
		<!-- endregion -->

		<!-- #region Menu -->
		<menu>
			<session-menu [session]="session"></session-menu>
		</menu>
		<!-- endregion -->
	</div>
</card-container>
