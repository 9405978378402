import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, finalize, takeUntil } from 'rxjs';

@Component({
	selector: 'session-analytic-item-view',
	templateUrl: './session-analytic-item-view.component.html',
	styleUrls: ['./session-analytic-item-view.component.scss']
})
export class SessionAnalyticItemViewComponent implements OnInit, OnDestroy {
	@Input() public title: string;
	@Input() public dataSummary;
	@Input() public isExpanded = false;
	@Input() public shouldTease?: boolean = false;
	@Output() public onExpanded = new EventEmitter<boolean>();

	public contentSizeChanged$$ = new Subject();
	public hideSummary = false;

	private componentDestroyed$$ = new Subject();

	constructor (@Inject('Window') private window, private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}


	public ngOnInit () {
		this.observeResizeObserver();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public observeResizeObserver () {
		const resizeObserver = new this.window.ResizeObserver((entries: any) => {
			this.contentSizeChanged$$.next(entries);
		});

		this.contentSizeChanged$$
			.pipe(finalize(() => resizeObserver.disconnect()), takeUntil(this.componentDestroyed$$), debounceTime(100))
			.subscribe((entries: any) => {
				this.hideSummary = +entries[0].target.offsetWidth <= 230;
				this.cdr.detectChanges();
			});

		resizeObserver.observe(this.elementRef.nativeElement.querySelector('mat-expansion-panel'));
	}

	public toggleExpand () {
		this.isExpanded = !this.isExpanded;
		this.onExpanded.emit(this.isExpanded);
	}
}
