<div class="fd-analytics-information">
	<div class="session-analytics-menu-container">
		<h5 class="sub-header-title" translate="feedback-session-feedback-display_analytics-header"></h5>
		<ng-container *ngIf="(analytics$ | async) as loadedAnalytics">
			<mat-form-field goSelect *ngIf="loadedAnalytics.length > 1">
				<mat-select
					[(ngModel)]="currentAnalytic"
					(selectionChange)="selectAnalytic($event)"
					disableOptionCentering
					panelClass="offsetPanel">
					<mat-select-trigger>
						<span class="selected-analytic" *ngIf="(selectedAnalytic$ | async) as selectedAnalytic">
							<ng-container *ngIf="selectedAnalytic.speaker_label.length === 1">
								{{ 'transcript-viewer_speaker' | translate : { speaker: selectedAnalytic.speaker_label } }}
							</ng-container>
							<ng-container *ngIf="selectedAnalytic.speaker_label.length > 1">
								{{ selectedAnalytic.speaker_label }}
							</ng-container>
						</span>
					</mat-select-trigger>
					<mat-option *ngFor="let analytic of loadedAnalytics" [value]="analytic">
						<ng-container *ngIf="analytic.speaker_label.length === 1">
							{{ 'transcript-viewer_speaker' | translate : { speaker: analytic.speaker_label } }}
						</ng-container>
						<ng-container *ngIf="analytic.speaker_label.length > 1">
							{{ analytic.speaker_label }}
						</ng-container>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>
		<span *ngIf="showBetaLabel && !(isLoading$ | async)" class="beta-label" translate="common_beta"></span>
	</div>

	<div class="close-panel">
		<ng-content select="[close-panel]" class="close-panel" *ngIf="!(isLoading$ | async)"></ng-content>
	</div>
</div>

<div class="loading-view" *ngIf="isLoading$ | async; else notLoaded">
	<i class="ficon-spinner ficon-spin"></i>
	<span translate="common_loading"></span>
</div>

<!-- Error Fetching Transcript -->
<ng-template #notLoaded>
	<ngx-zero-state *ngIf="requestErrorOccurred$ | async">
		<div zero-state-title translate="transcript-viewer-zero-state_loading-failed"></div>
		<div zero-state-subtext translate="session-analytics-zero-state_loading-failed-subtext"></div>
		<div zero-state-graphic-bottom>
			<img class="zero-state-image" src="https://staticassets.goreact.com/floating-astronaut.svg" alt="{{'transcript-viewer-zero-state_loading-failed-image-alt' | translate}}">
		</div>
	</ngx-zero-state>
</ng-template>

<section class="transcript-container" *ngIf="parsedMediaTranscript$ | async as transcript">
	<feedback-graph
		(onExpanded)="onExpanded(analyticItems.FEEDBACK)"
		[isExpanded]="isExpanded(analyticItems.FEEDBACK) | async"
		[comments]="comments"
		[duration]="media.duration"
		[playerSync]="playerSync"
		[tagsList]="tagsList">
	</feedback-graph>

	<ng-container *ngIf="maySeeTranscriptionAnalytics() && transcript.status === TranscriptStatus.COMPLETED">
		<word-metric-graph
			*teaseWall="analyticsTeaseWallConfig; hide: !(isExpanded(analyticItems.FILLER) | async)"
			(onExpanded)="onExpanded(analyticItems.FILLER)"
			[isExpanded]="isExpanded(analyticItems.FILLER) | async"
			[type]="WordGraphType.FILLER"
			[analytic]="selectedAnalytic$ | async"
			[media]="media"
			[playerSync]="playerSync"
			[shouldTease]="shouldTease">
		</word-metric-graph>
		<ng-container *ngIf="$any({value: selectedAnalytic$ | async}) as selectedAnalytic">
			<pause-metric-graph *teaseWall="analyticsTeaseWallConfig; hide: !(isExpanded(analyticItems.PAUSE) | async)"
				(onExpanded)="onExpanded(analyticItems.PAUSE)"
				[isExpanded]="isExpanded(analyticItems.PAUSE) | async"
				[media]="media"
				[playerSync]="playerSync"
				[pauseInstances]="selectedAnalytic.value?.pause_instances || []"
				[totalPauses]="selectedAnalytic.value?.pause_total || 0"
				[totalLengthyPauses]="selectedAnalytic.value?.pause_lengthy_total || 0"
				[shouldTease]="shouldTease">
			</pause-metric-graph>
		</ng-container>
		<pace-metric-graph
			*teaseWall="analyticsTeaseWallConfig; hide: !(isExpanded(analyticItems.PACE) | async)"
			(onExpanded)="onExpanded(analyticItems.PACE)"
			[isExpanded]="isExpanded(analyticItems.PACE) | async"
			[media]="media"
			[playerSync]="playerSync"
			[utterances]="selectedUtterances$ | async"
			[shouldTease]="shouldTease">
		</pace-metric-graph>
		<word-metric-graph
			*teaseWall="analyticsTeaseWallConfig; hide: !(isExpanded(analyticItems.HEDGING) | async)"
			(onExpanded)="onExpanded(analyticItems.HEDGING)"
			[isExpanded]="isExpanded(analyticItems.HEDGING) | async"
			[type]="WordGraphType.HEDGING"
			[analytic]="selectedAnalytic$ | async"
			[media]="media"
			[playerSync]="playerSync"
			[shouldTease]="shouldTease">
		</word-metric-graph>
	</ng-container>
</section>

