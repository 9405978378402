import {
	ChangeDetectionStrategy,
	Component, Inject, Input
} from '@angular/core';
import { helpUrlsLookup } from 'go-modules/help-urls/help-urls.constant';
import { Reasons } from 'go-modules/video-scene/state-emitter/state-emitter';
import { CONTINUE_WITHOUT_AUDIO_KEY } from 'go-modules/video-scene/constants';

export interface StatusOverlayable {
	readonly reason: Reasons;
	readonly browser?: string;
	readonly action: (options?: object) => void;
}

@Component({
	selector: 'status-overlay',
	templateUrl: './status-overlay.component.html',
	styleUrls: ['./status-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusOverlayComponent {
	@Input() public status: StatusOverlayable;
	public helpUrls = helpUrlsLookup;
	public reasons = Reasons;

	constructor (
		@Inject('Window') private window: Window
	) {}

	public continueWithoutAudio () {
		this.window.localStorage.setItem(CONTINUE_WITHOUT_AUDIO_KEY, 'true');
		this.status.action({ continueWithoutAudio: true });
	}
}
