import { LIBRARY_TYPES, CONTENT_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { DESTINATION_MODES } from './collection-destination.constants';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { MatDialog } from '@angular/material/dialog';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { ManageLibraryCollectionFolderComponent } from '../manage-library-collection-folder/manage-library-collection-folder.component';

export interface CollectionDestinationData {
	collections: any;
	selectedCollection: any;
	contentType: string;
	mode: DESTINATION_MODES;
	defaultFolderId?: number | null;
	allowSkip?: boolean;
	name?: string | null;
	showNameField?: boolean;
}

@Component({
	selector: 'ngx-collection-destination',
	templateUrl: './collection-destination.component.html',
	styleUrls: ['./collection-destination.component.scss']
})
export class CollectionDestinationComponent implements OnInit {
	public collections: any = [];
	public folders: any = [];
	public form = new FormGroup({
		name: new FormControl('', []),
		selectedCollection: new FormControl(null, [Validators.required]),
		selectedFolder: new FormControl(null, [])
	});
	public foldersLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public hasFolderAdded: boolean = false;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: CollectionDestinationData,
		private libraryService: LibraryService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private modal: GoModalService,
		private elementRef: ElementRef,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService
	) {}

	public ngOnInit () {
		if (this.data.selectedCollection) {
			this.form.controls.selectedCollection.setValue(this.data.selectedCollection);
		}
		if (this.data.name) {
			this.form.controls.name.setValue(this.data.name);
		}
		if (this.data.showNameField) {
			this.form.controls.name.addValidators(Validators.required);
		}
		this.collections = this.data.collections.filter((collection) => {
			const removeTypes = [LIBRARY_TYPES.ARCHIVE, LIBRARY_TYPES.LEGACY, LIBRARY_TYPES.DIRECT_SHARE];
			if (!this.userService.currentUser.is_root_content) {
				removeTypes.push(LIBRARY_TYPES.MANAGED);
			}
			return !removeTypes.includes(collection.type);
		}).sort((a, b) => {
			return a.name.localeCompare(b.name);
		});

		if (this.data.selectedCollection.type === LIBRARY_TYPES.ARCHIVE ||
			(this.data.selectedCollection.type === LIBRARY_TYPES.MANAGED &&
				!this.userService.currentUser.is_root_content) ||
			this.data.selectedCollection.type === LIBRARY_TYPES.LEGACY ||
			this.data.selectedCollection.type === LIBRARY_TYPES.DIRECT_SHARE) {
			this.form.controls.selectedCollection.setValue(this.data.collections[0]);
		}

		this.loadFolders();
	}

	public loadFolders () {
		this.foldersLoading$.next(true);
		this.form.controls.selectedFolder.setValue(null);
		this.libraryService.getCollectionFolders(this.form.value.selectedCollection.id)
			.subscribe((folders) => {
				this.folders = folders.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				this.folders.unshift({id: 0, name: this.translate.instant('common_none')});
				this.foldersLoading$.next(false);
				if (this.data.defaultFolderId) {
					const selectedFolder = this.folders.find((folder) => {
						return folder.id === this.data.defaultFolderId;
					});
					if (selectedFolder) {
						this.form.controls.selectedFolder.setValue(selectedFolder);
					} else {
						this.form.controls.selectedFolder.setValue(null);
					}
				}
			});
	}

	public openAddFolderModal () {
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_CREATE_FOLDER_CLICK, {});

		if (this.folders.length >= 100) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_max-folders-title'),
					message: this.translate.instant('library-collections_max-folders-message')
				}
			});
		}

		return this.modal.open(ManageLibraryCollectionFolderComponent, false, {
			data: {
				createMode: true,
				existingFolders: this.folders,
				collections: this.collections,
				selectedCollection: this.form.value.selectedCollection
			}
		}).afterClosed().subscribe((res) => {
			const toggleButton = this.elementRef.nativeElement.querySelector('.add-btn');
			toggleButton.focus();
			if (res.dismissed) {
				return;
			}
			if (res.request_failed) {
				return this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('library-collections_add-folder-fail-title'),
						message: this.translate.instant('library-collections_add-folder-fail-message')
					}
				});
			}
			const newFolder = res;
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_FOLDER_CREATED, {});
			this.hasFolderAdded = true;

			if (this.form.value.selectedCollection.id === newFolder.library_collection_id) {
				// remove the "None" option first so we can push and sort newly added folder to the list
				if (this.folders.length > 0) {
					this.folders.splice(0, 1);
				}
				this.folders.push(newFolder);
				this.folders = this.folders.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				this.folders.unshift({id: 0, name: this.translate.instant('common_none')});
				const selectedFolder = this.folders.find((folder) => {
					return folder.id === newFolder.id;
				});
				this.form.controls.selectedFolder.setValue(selectedFolder);
			} else {
				const selectedCollection = this.collections.find((collection) => {
					return collection.id === newFolder.library_collection_id;
				});
				this.form.controls.selectedCollection.setValue(selectedCollection);
				this.data.defaultFolderId = newFolder.id;
				this.loadFolders();
			}
		});
	}

	public dismiss () {
		if (this.hasFolderAdded) {
			this.dialogRef.close({
				dismissed: true,
				hasFolderAdded: this.hasFolderAdded,
				collection: this.form.value.selectedCollection
			});
		} else {
			this.dialogRef.close({dismissed: true});
		}
	}

	public close () {
		if (this.data.contentType === CONTENT_TYPES.ACTIVITIES && this.data.showNameField) {
			if (this.form.value.name && this.form.value.name !== '') {
				this.dialogRef.close({
					collection: this.form.value.selectedCollection,
					folder: this.form.value.selectedFolder,
					name: this.form.value.name
				});
			}
		} else {
			this.dialogRef.close({
				collection: this.form.value.selectedCollection,
				folder: this.form.value.selectedFolder
			});
		}
	}

	public autoSelect () {
		this.dialogRef.close({
			autoSelect: true
		});
	}
}
