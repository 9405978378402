<div id="email-verification-dialog-container" class="modal-dialog">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<span></span>
			<button type="button" class="close-btn" (click)="close()" attr.aria-label="{{ 'common_close' | translate }}">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body" >
			<div class="message" *ngIf="loading || error">
				<div *ngIf="error" class="error">
					<p translate="common_unknown-error"></p>
					<button class="primary-btn" (click)="sendVerification()" translate="common_retry"></button>
				</div>
				<p *ngIf="loading" translate="common_please-wait_loading"></p>
			</div>

			<email-verification
				*ngIf="!loading && !error"
				[email]="data.email"
				(onComplete)="close($event)">
			</email-verification>
		</div>
	</div>
</div>
