import { Component } from '@angular/core';

@Component({
	selector: 'analytic-graph-zero-state',
	templateUrl: './analytic-graph-zero-state.component.html',
	styleUrls: ['./analytic-graph-zero-state.component.scss']
})
export class AnalyticGraphZeroStateComponent {

}
