import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { ModifyCollectionsPanelComponent } from 'ngx/go-modules/src/components/library/modify-collections-panel/modify-collections-panel.component';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { BehaviorSubject, Subject, Subscription, filter } from 'rxjs';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_SIDEPANEL_DATA } from 'ngx/go-modules/src/services/go-sidepanel/component/go-sidepanel.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { LIBRARY_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LibraryCollection } from 'ngx/go-modules/src/interfaces/library/library-collection';

@Component({
	selector: 'ngx-manage-collections-panel',
	templateUrl: './manage-collections-panel.component.html',
	styleUrls: ['./manage-collections-panel.component.scss']
})
export class ManageCollectionsPanelComponent implements OnInit, OnDestroy {
	public collections: LibraryCollection[];
	public pendingSave: boolean = false;
	public saving$ = new BehaviorSubject(false);
	public libraryTypes = LIBRARY_TYPES;
	public readonly componentDestroyed$$ = new Subject();

	public activeItem: number;
	public listSubscription: Subscription;
	public originalIndex: { [key: string]: number } = {};
	@ViewChildren('listItem') public listItems: QueryList<ElementRef>;

	constructor (
		@Inject(GO_SIDEPANEL_DATA) public sidepanelData: {
			options: {
				inputs: {
					collections: LibraryCollection[]
				}
			};
		},
		public ngxGoSidepanelService: GoSidepanelService,
		private dialog: MatDialog,
		private dialogRef: GoDialogRef,
		private translate: TranslateService,
		private elementRef: ElementRef,
		private libraryService: LibraryService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService
	) {}

	public ngOnInit () {
		this.collections = this.sidepanelData.options.inputs.collections;
		this.updateOriginalIndex();
	}

	public ngAfterViewInit () {
		this.listSubscription = this.listItems.changes.subscribe(() => {
			this.setFocus();
		});
	}

	public ngOnDestroy (): void {
		this.listSubscription.unsubscribe();
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public toggleCollectionVisibility (permission) {
		permission.is_hidden = !permission.is_hidden;
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_TOGGLE_VISIBILITY, {});
		this.pendingSave = true;
	}

	public cancel (): void {
		this.dialogRef.close();
	}

	public saveCollectionPermissions () {
		if (this.pendingSave) {
			const payload = {
				permissions: this.collections.map((collection) => this.findMyPermission(collection))
			};
			this.saving$.next(true);
			this.libraryService.updateCollectionPermission(payload).subscribe(() => this.saving$.next(false));
		}
		this.dialogRef.close(this.collections);
	}

	public canEditCollection (collection) {
		return collection.type === 'shared' && collection.created_by === this.userService.currentUser.user_id;
	}

	public canDeleteCollection (collection) {
		return collection.type === 'shared';
	}

	public deleteCollection (collection) {
		const message = collection.created_by === this.userService.currentUser.user_id ?
			'library-collections_manage-collections-delete-owned' : 'library-collections_manage-collections-delete-shared';
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
			ConfirmDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_manage-collections-delete-title'),
					message: this.translate.instant(message)
				} as ConfirmDialogData
			});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm)
		).subscribe(() => {
			this.libraryService.removeCollection(collection)
				.subscribe({
					next: () => {
						this.collections.splice(this.collections.indexOf(collection), 1);
					},
					error: (err) => {
						let errMessage = 'library-collections_remove-collection-failed-message';
						let title = 'library-collections_remove-collection-failed-title';

						if (err.status === 409) {
							title = 'library-collections_remove-collection-with-items-title';
							errMessage = 'library-collections_remove-collection-with-items-message';
						}

						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant(title),
								message: this.translate.instant(errMessage)
							}
						});
					}
				});
		});
	}

	public modifyCollection (collection = null) {
		if (!collection && this.collections.filter((coll) => coll.type === 'shared' && coll.created_by === this.userService.currentUser.user_id).length >= clientSettings.SharedCollectionLimit) {
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_modify-collections-too-many-title'),
					message: this.translate.instant('library-collections_modify-collections-too-many-message')
				}
			});
		} else {
			if (collection === null) {
				this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_CREATE_NEW_COLLECTION, {});
			}
			const manageCollectionsPanel = this.elementRef.nativeElement;
			this.ngxGoSidepanelService.open(ModifyCollectionsPanelComponent, {
				inputs: {
					collection
				},
				noFooter: true,
				panelClass: ['modify-collections-panel']
			}, manageCollectionsPanel).afterClosed().subscribe((returnedCollection) => {
				if (returnedCollection) {
					const i = this.collections.findIndex((x) => x.id === returnedCollection.id);
					if (i > -1) this.collections[i] = returnedCollection;
					else this.collections.push(returnedCollection);
				}
			});
		}
	}

	public findMyPermission = (collection) => {
		return collection.library_collection_permissions.find((permission) =>
			permission.user_id === this.userService.currentUser.user_id);
	};

	public setFocus () {
		if (this.activeItem >=0 && this.listItems.toArray()[this.activeItem]) {
			this.listItems.toArray()[this.activeItem].nativeElement.focus();
		}
	}

	public drop (event: CdkDragDrop<[]>) {
		moveItemInArray(this.collections, event.previousIndex, event.currentIndex);
		this.activeItem = event.currentIndex;
		this.setFocus();
		this.updateOriginalIndex();
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_COLLECTIONS_REORDERED, {});
		this.pendingSave = true;
	}

	public onActiveItemChange ({currentIndex, newIndex}) {
		moveItemInArray(this.collections, currentIndex, newIndex);
		this.activeItem = newIndex;
		this.setFocus();
	}

	public onFinalizePosition () {
		this.updateOriginalIndex();
		this.pendingSave = true;
	}

	public updateOriginalIndex () {
		this.collections.forEach((collection, index) => {
			this.originalIndex[collection.id] = index;
		});
	}

	public onCancelReorder ({currentIndex, itemId}) {
		const originalIndex = this.originalIndex[itemId];
		moveItemInArray(this.collections, currentIndex, originalIndex);
		this.activeItem = originalIndex;
		this.setFocus();
	}
}
