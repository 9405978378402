import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ngx-suggestion-panel',
	templateUrl: './suggestion-panel.component.html',
	styleUrls: ['./suggestion-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxSuggestionPanelComponent {
	constructor () {}
}
