<div class="product-card" [ngClass]="{'last-item': isLast}">
	<div class="recommended-item-label" *ngIf="isLast">{{ 'product-card_recommended-label' | translate }}</div>
	<div class="product-card-content" [ngClass]="{'last-item': isLast}">
		<div class="product-card-header">{{ product.display_name }}</div>
		<div class="product-card-pricing">
			<span class="price">{{ getPrice() | currency: 'USD' : digitsInfo: (getPrice() % 1 === 0 ? '1.0-0' : '1.2-2') }}</span>
			<span class="big-slash">/</span>
			<span class="duration">{{ product.licenseProduct.duration_months | monthsToYearsMonths }}</span>
			<span class="big-slash">/</span>
			<span class="seat-type" translate>
				<span *ngIf="product.licenseProduct.seat_type === 'user'; else userFolder" translate>common_user</span>
				<ng-template #userFolder>
					<span translate>common_user</span>
					<span class="big-slash">/</span>
					<span translate>common_course</span>
				</ng-template>
			</span>
		</div>
		<span
			[tabindex]="!action.observers.length ? 0 : -1"
			[matTooltip]="'product-card_current-plan' | translate"
			[matTooltipDisabled]="action.observers.length > 0"
			[attr.aria-disabled]="!action.observers.length || isDisabled"
			matTooltipPosition="above"
			class="action-button-container"
			role="button">
			<button class="action-button"
				[ngClass]="getButtonClass()"
				(click)="action.emit()"
				[disabled]="!action.observers.length || isDisabled"
				[attr.aria-hidden]="!action.observers.length">
				<i *ngIf="product.upgradeRequestSent" class="ficon-app-checkmark-circle-outline">
				</i>{{ actionTranslation | translate }}
			</button>
		</span>
		<mat-divider></mat-divider>
		<div class="features">
			<div class="feature video-quality">
				<mat-icon class="ficon-app-checkmark"></mat-icon>
				<span class="description">{{ 'product-card_video-quality' | translate: {quality: product.licenseProduct.video_quality === '1280x720' ? 'HD' : 'SD'} }}</span>
			</div>
			<div class="feature upload-limit">
				<mat-icon class="ficon-app-checkmark"></mat-icon>
				<span class="description">{{ 'product-card_upload-size' | translate: {size: getUploadSize()} }}</span>
			</div>
			<div class="feature">
				<mat-icon class="ficon-app-checkmark"></mat-icon>
				<span class="description" translate>product-card_recording</span>
			</div>
			<div class="feature">
				<mat-icon class="ficon-app-checkmark"></mat-icon>
				<span class="description" translate>product-card_timestamp</span>
			</div>
			<div class="feature">
				<mat-icon class="{{ product.licenseProduct.transcriptions_enabled ? 'ficon-app-checkmark' : 'ficon-app-x' }}"></mat-icon>
				<span class="description" [ngClass]="{'unavailable': !product.licenseProduct.transcriptions_enabled}" translate>product-card_transcript</span>
			</div>
			<div class="feature">
				<mat-icon class="{{ product.licenseProduct.transcript_analytics_enabled ? 'ficon-app-checkmark' : 'ficon-app-x' }}"></mat-icon>
				<span class="description" [ngClass]="{'unavailable': !product.licenseProduct.transcript_analytics_enabled}" translate>product-card_analytics</span>
			</div>
			<div class="feature">
				<mat-icon class="{{ product.licenseProduct.ai_prompts_enabled ? 'ficon-app-checkmark' : 'ficon-app-x' }}"></mat-icon>
				<span class="description" [ngClass]="{'unavailable': !product.licenseProduct.ai_prompts_enabled}" translate>product-card_markers</span>
			</div>
			<div class="feature">
				<mat-icon class="{{ product.licenseProduct.ai_prompts_enabled ? 'ficon-app-checkmark' : 'ficon-app-x' }}"></mat-icon>
				<span class="description" [ngClass]="{'unavailable': !product.licenseProduct.ai_prompts_enabled}" translate>product-card_comments</span>
			</div>
		</div>
	</div>
</div>
