import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { TransactionFactory, transactionFactoryToken } from 'go-modules/models/transaction/transaction.factory';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { TransactionModel as Transaction, TransactionMethod, TransactionPaymentType } from 'ngx/go-modules/src/interfaces/transactions/transaction.schema';
import { BehaviorSubject, forkJoin, from, Subject, takeUntil } from 'rxjs';
import { userToken } from 'go-modules/models/user/user.factory';
import type { User as UserFactory } from 'go-modules/models/user/user.factory';
import * as dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';

@Component({
	selector: 'billing-history',
	templateUrl: './billing-history.component.html',
	styleUrls: ['./billing-history.component.scss']
})

export class BillingHistoryComponent implements OnInit, OnDestroy {
	public componentDestroyed$$ = new Subject();
	public isLoading$ = new BehaviorSubject<boolean>(true);
	public transactions$ = new BehaviorSubject<Transaction[]>(null);
	public credits$ = new BehaviorSubject<any>(null);
	public plan$$ = new BehaviorSubject<any>(null);
	public plan$ = this.plan$$.asObservable();

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		@Inject(userToken) private User: ReturnType<typeof UserFactory>,
		@Inject(transactionFactoryToken) public transactionFactory: TransactionFactory,
		public cd: ChangeDetectorRef,
		private dialog: MatDialog,
		private translate: TranslateService
	) {}

	public ngOnInit () {
		this.loadCreditsAndTransactions();
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public sendEmail (transaction: Transaction) {
		if (!transaction.emailSending) {
			transaction.emailSending = true;
			this.transactionFactory.resendEmail(transaction.created_by, transaction.trans_id)
				.then(() => {
					this.dialog.open(MessageDialogComponent, {
						data: {
							title: this.translate.instant('modal-billing-history_controller-email-sent'),
							message: this.translate.instant('modal-billing-history_controller-success-sent')
						}
					});
				}).finally(() => {
					transaction.emailSending = false;
				});
		}
	}

	private loadCreditsAndTransactions () {
		const transactions$ = from(this.transactionFactory.getTransactions(
			this.userService.currentUser.user_id,
			[TransactionMethod.CARD, TransactionMethod.CHECK],
			[TransactionPaymentType.PURCHASE, TransactionPaymentType.REFUND]
		));

		const credits$ = from(this.User.getBillingBalances({ userId: this.userService.currentUser.user_id }).$promise);
		forkJoin({ transactions: transactions$, credits: credits$ })
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe({
				next: (results: any) => {
					this.plan$$.next(this.checkForPlan(results.credits.plan));
					this.credits$.next(results.credits.credits);
					this.transactions$.next(results.transactions.sort((a, b) => b.created_at - a.created_at));
				},
				complete: () => {
					this.isLoading$.next(false);
					this.cd.detectChanges();
				}
			});
	}

	private checkForPlan (plan) {
		if (plan) {
			plan.starts_at = dayjs.utc(plan.starts_at).toDate();
			plan.ends_at = dayjs.utc(plan.ends_at);
			plan.remaining = plan.ends_at.fromNow(true);
			plan.ends_at = plan.ends_at.toDate();
		}
		return plan;
	}
}
