import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Media } from 'go-modules/services/attachment/media';

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import * as Highcharts from 'highcharts';
import Accessibility from 'highcharts/modules/accessibility';
import Xrange from 'highcharts/modules/xrange';
import type { PlayerSync } from 'ngx/go-modules/src/interfaces/media/media';
Accessibility(Highcharts);
Xrange(Highcharts);

@Component({
	selector: 'pause-metric-graph',
	templateUrl: './pause-metric-graph.component.html',
	styleUrls: ['./pause-metric-graph.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PauseMetricGraphComponent implements OnChanges {
	@Output() public onExpanded = new EventEmitter<boolean>();

	@Input() public isExpanded: boolean;
	@Input() public media: Media;
	@Input() public playerSync: PlayerSync | null;
	@Input() public totalPauses: number;
	@Input() public totalLengthyPauses: number;
	@Input() public pauseInstances = [];
	@Input() public shouldTease: boolean;

	public Highcharts: typeof Highcharts = Highcharts;
	public chartOptions: Highcharts.Options;

	constructor (
		private translate: TranslateService
	) {}

	public ngOnChanges () {
		const seriesName = this.translate.instant('feedback-session-feedback-display_analytics_pauses_metric-label');
		const seriesData = this.pauseInstances.map((data) => {
			return {
				x: data.start,
				x2: data.start + data.duration,
				y: 0
			};
		});
		const player = this.playerSync;
		const convertMillisecondsToTimestamp = this.convertMillisecondsToTimestamp;

		this.chartOptions = {
			accessibility: {
				enabled: !this.shouldTease
			},
			chart: {
				type: 'xrange',
				height: 110,
				margin: [0,0,0,0]
			},
			credits: {
				enabled: false
			},
			title: {
				text: ''
			},
			xAxis: {
				type: 'linear',
				labels: {
					formatter () {
						return convertMillisecondsToTimestamp(this.value);
					}
				},
				min: 0,
				max: this.media.duration,
				offset: -50,
				lineColor: '#FFFFFF',
				tickLength: 1,
				startOnTick: true,
				endOnTick: true
			},
			yAxis: {
				title: {
					text: ''
				},
				labels: {
					enabled: false
				},
				gridLineColor: '#F7F7F7',
				gridLineWidth: 10
			},
			plotOptions: {
				series: {
					cursor: 'pointer',
					events: {
						click (event) {
							const timeStamp =  event.point.x;
							player.seek(timeStamp);
						}
					},
					accessibility: {
						enabled: true,
						keyboardNavigation: {
							enabled: true
						},
						point: {
							descriptionFormatter (point) {
								return `${seriesName} ${convertMillisecondsToTimestamp(point.x)} to ${convertMillisecondsToTimestamp(point.x2)}`;
							}
						}
					}
				}
			},
			series: [{
				name: seriesName,
				showInLegend: false,
				type: 'xrange',
				borderRadius: 5,
				pointWidth: 10,
				colors: ['#3B88C6'],
				data: seriesData,
				tooltip: {
					headerFormat: '',
					pointFormatter () {
						return `${convertMillisecondsToTimestamp(this.x)} to ${convertMillisecondsToTimestamp(this.x2)}`;
					}
				}
			}]
		};
	}

	public convertMillisecondsToTimestamp (durationInMs) {
		const timeStamp = dayjs.duration(durationInMs);

		if (timeStamp.hours() > 0) {
			return timeStamp.format('HH:mm:ss');
		} else {
			return timeStamp.format('mm:ss');
		}
	}
}
