<div
	class="go-react-toast"
	[ngClass]="{'toast-mobile': UADetect.isMobile(), 'undismissible': !hideToastTime }"
	*ngIf="shouldShowToast()">

	<div
		class="toast-content"
		[ngClass]="{
			'success': toastDetails.type === ToastStatusTypes.SUCCESS,
			'warning': toastDetails.type === ToastStatusTypes.WARNING,
			'error': toastDetails.type === ToastStatusTypes.ERROR
		}">
		<span class="toast-icon">
			<i class="ficon-app-checkmark-circle-outline"
				*ngIf="toastDetails.type === ToastStatusTypes.SUCCESS">
			</i>
			<i class="ficon-app-information-outline"
				*ngIf="toastDetails.type === ToastStatusTypes.WARNING">
			</i>
			<i class="ficon-app-warning-circle-outline"
				*ngIf="toastDetails.type === ToastStatusTypes.ERROR">
			</i>
		</span>
		<p class="toast-message-wrap">
			<span class="toast-message" [innerHTML]="toastDetails.message | translate" tabindex="0"></span>
			<span *ngIf="toastDetails.actionLink">
				&nbsp;<a class="action-link" href="#" (click)="toastDetails.actionLink.action(); closeToast()" [innerHTML]="toastDetails.actionLink.title | translate"></a>
			</span>
		</p>

		<button
			*ngIf="toastDetails.undo"
			type="button"
			class="btn-none undo-btn tertiary-btn"
			(click)="undoAction()"
			translate="common_undo"
			wcag-ignore>
		</button>
		<button
			*ngIf="!!hideToastTime"
			type="button"
			class="btn-none close-btn"
			(click)="closeToast()"
			wcag-ignore>
			<i class="ficon-app-x"></i>
		</button>
	</div>
</div>
