<auth-template>
	<div class="login-wrap">
		<section class="left">
			<img alt="{{ 'header_logo' | translate }}" class="go-logo" src="https://staticassets.goreact.com/logo-goreact-dash-2022.svg">
			<div class="welcome-back-left">
				<img src="https://staticassets.goreact.com/logo-goreact-spot-2022.svg" alt="{{ 'header_logo' | translate }}">
				<h1 translate="email-and-password_welcome-back"></h1>
			</div>
			<p class="description" translate="email-and-password_description"></p>
			<img class="left-image" src="https://staticassets.goreact.com/login.svg" alt="{{ 'email-and-password_video-feedback' | translate }}">
		</section>

		<section class="right" #content>
			<div class="sign-in-wrapper">
				<span translate="email-and-password_no-account"></span>
				<button type="button" class="signup-link goreact-btn tertiary-btn color-red" (click)="onSignUp.emit()" data-cy="create-account" translate="email-and-password_signup"></button>
			</div>

			<div class="welcome-back-right">
				<img src="https://staticassets.goreact.com/logo-goreact-spot-2022.svg" alt="{{ 'header_logo' | translate }}">
				<h1 translate="email-and-password_welcome-back"></h1>
			</div>
			<form [formGroup]="form" (ngSubmit)="login()">
				<mat-form-field appearance="outline">
					<mat-label translate="login-form_email-label"></mat-label>
					<input id="email" type="email" name="email" data-cy="login-email-field" matInput [readonly]="email" formControlName="username" maxlength="100" class="fullstory-ignore"/>
					<mat-error *ngIf="form.controls.username.hasError('email')" translate="sign-up-form-validation-email-invalid"></mat-error>
					<mat-error *ngIf="form.controls.username.hasError('required')" translate="sign-up-form-validation-email-required"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label translate="login-form_password-label"></mat-label>
					<input id="password" type="password" name="password" data-cy="login-password-field" matInput formControlName="password" class="fullstory-ignore" />
					<mat-error *ngIf="form.controls.password.invalid" translate="login-form_validation-password-invalid"></mat-error>
				</mat-form-field>

				<button data-cy="forgot-password" type="button" class="tertiary-btn forgot-passwd-lnk" translate="forgot-password_title" (click)="onForgotPassword.emit()"></button>

				<span class="terms-and-privacy" [innerHTML]="
					'terms-privacy-with-links-login' | translate:{
						terms: 'https://get.goreact.com/terms',
						privacy: 'https://get.goreact.com/privacy'
					}">
				</span>

				<div role="alert" class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>

				<div class="login-options-wrapper">
					<button
						data-cy="login-button"
						type="submit"
						class="submit-btn primary-btn"
						translate="log-in"
						[disabled]="disabledButton">
					</button>

					<sso-provider-button
						(onLoginWithSso)="loginWithProvider($event)"></sso-provider-button>
				</div>
			</form>

			<div class="sign-in-wrapper-bottom">
				<span translate="email-and-password_no-account"></span>
				<a (click)="onSignUp.emit()" translate="email-and-password_signup"></a>
			</div>
		</section>
	</div>
</auth-template>
