<div class="goreact-modal share-library-item-dialog-container">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="goreact-modal-header">
				<h4 class="modal-title" translate="modal-share-library-item_title"></h4>
				<button type="button" class="close-btn" (click)="cancel()" [attr.aria-label]="'common_close' | translate">
					<i class="ficon-app-x"></i>
				</button>
			</div>
			<div class="goreact-modal-body" #content [formGroup]="form">
				<p translate="modal-share-library-item_message"></p>
				<mat-form-field appearance="outline" floatLabel="always" class="emails">
					<mat-label translate="invite-user-dialog_email-label"></mat-label>
					<textarea matInput formControlName="emails"></textarea>
					<mat-hint translate="invite-user-dialog_email-hint"></mat-hint>

					<mat-error
						*ngIf="form.controls['emails'].hasError('required')"
						translate="modal-share-library-item_emails-required_error_message">
					</mat-error>
					<mat-error
						*ngIf="form.controls['emails'].hasError('multi')">
						{{
							'common-validation_emails-invalid' | translate:{emails: getInvalidEmails()}
						}}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="goreact-modal-footer">
				<button
					type="button"
					class="cancel-btn tertiary-btn"
					(click)="cancel()"
					translate="common_cancel">
				</button>
				<button
					type="button"
					class="confirm-button primary-btn"
					(click)="submit()"
					[disabled]="isSaving$ | async"
					[focusOnFirstInvalidField]="{formGroup: form, formWrapper: content}">
					{{
						((isSaving$ | async) ?
							'common_sharing' :
							'common_share') | translate
					}}
				</button>
			</div>
		</div>
	</div>
</div>
