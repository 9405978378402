import * as dayjs from 'dayjs';
import { LIBRARY_TYPES, SORT_OPTIONS } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { filter } from 'rxjs';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { MessageDialogComponent, MessageDialogData } from '../../dialogs/message-dialog/message-dialog.component';
import { SearchPipe } from 'ngx/go-modules/src/pipes/search/search.pipe';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { ManageLibraryCollectionFolderComponent } from '../manage-library-collection-folder/manage-library-collection-folder.component';

@Component({
	selector: 'ngx-collection-folder-viewer',
	templateUrl: './collection-folder-viewer.component.html',
	styleUrls: ['./collection-folder-viewer.component.scss']
})
export class CollectionFolderViewerComponent implements OnInit, OnChanges {
	@Input() public items: any[] = [];
	public filteredItems: any[] = [];
	@Input() public folder: any;
	@Input() public expanded: any;
	@Input() public expandedFolders: any[];
	@Input() public mode: string;
	@Output() public selectItem: EventEmitter<any> = new EventEmitter();
	@Input() public collections: any;
	@Input() public archiveCollection: any;
	@Input() public selectedCollection: any;
	@Input() public selectedContentType: any;
	@Output() public loadNewItems: EventEmitter<any> = new EventEmitter();
	@Input() public collectionItems: any;
	@Input() public sortBy: string;
	@Input() public searchStr: string;
	@Input() public multiSelect: boolean;
	@Output() public setSelectAllChecked: EventEmitter<boolean> = new EventEmitter();
	@Input() public expandedHeight: number;
	@Input() public currentlySelected: any;
	@Output() public setActivityPanelIsOpen: EventEmitter<boolean> = new EventEmitter();
	@Input() public orgSettings: any;
	@Output() public setCurrentlySelected: EventEmitter<any> = new EventEmitter();

	constructor (
		private elementRef: ElementRef,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService,
		private dialog: MatDialog,
		private libraryService: LibraryService,
		private translate: TranslateService,
		private searchPipe: SearchPipe,
		@Inject(userServiceToken) private userService: UserService,
		private modal: GoModalService
	) {}

	public ngOnInit () {
		this.items = this.filteredItems = this.collectionItems.items.filter((item) => {
			return item.library_collection_folder_id === this.folder.id;
		});
	}

	public ngOnChanges (changes) {
		if (changes.searchStr && !changes.searchStr.isFirstChange()) {
			this.elementRef.nativeElement.classList.remove('hidden');

			this.filteredItems = this.searchPipe.transform(this.items, changes.searchStr.currentValue, ['name', 'description', 'creator_name']);

			if (!this.emptyString(changes.searchStr.currentValue) && this.filteredItems.length === 0) {
				this.elementRef.nativeElement.classList.add('hidden');
			}

			if (!this.emptyString(changes.searchStr.currentValue) && this.filteredItems.length > 0 &&
				!this.expandedFolders.includes(this.folder.id)) {
				this.expandFolder(this.folder);
			}

			if (
				!this.emptyString(changes.searchStr.previousValue) &&
				this.emptyString(changes.searchStr.currentValue) &&
				this.expandedFolders.includes(this.folder.id)
			) {
				this.expandFolder(this.folder);
			}
		}
	}

	public updateItems () {
		this.items = this.filteredItems = this.collectionItems.items.filter((item) => {
			return item.library_collection_folder_id === this.folder.id;
		});
	}

	public managedCollectionAccess () {
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		//if not a managed collection just return true
		return true;
	}

	public formatDate (date) {
		return dayjs(date).format('MMM D, YYYY');
	}

	public getSortKey () {
		let itemField: string = 'name';

		if (this.sortBy === SORT_OPTIONS.ALPHANUMERICREVERSE) {
		  itemField = '-name';
		} else if (this.sortBy === SORT_OPTIONS.LASTMODIFIED) {
		  itemField = '-modified_at';
		}

		return itemField;
	}

	public expandFolder (folder) {
		const expanded = this.expandedFolders;
		const id = folder.id;
		if (expanded.includes(id)) {
			expanded.splice(expanded.indexOf(id), 1);
		} else {
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_FOLDER_OPENED, {});
			expanded.push(id);
		}
		this.setCurrentlySelected.emit({id});
	}

	public removeFolder (folder) {
		let message = this.translate.instant('modal-remove-collection-folder_message');
		if (this.folder.item_counts.media ||
			this.folder.item_counts.rubrics ||
			this.folder.item_counts.markers ||
			this.folder.item_counts.documents) {
			message = this.translate.instant('modal-remove-collection-folder_message-with-items', {
				media: this.folder.item_counts.media,
				rubrics: this.folder.item_counts.rubric,
				markers: this.folder.item_counts.markers,
				documents: this.folder.item_counts.document
			});
		}
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
			ConfirmDialogComponent, {
				data: {
					title: this.translate.instant('modal-remove-collection-folder_title'),
					message,
					confirmText: this.translate.instant('common_remove'),
					cancelText: this.translate.instant('common_cancel')
				} as ConfirmDialogData
			});

		return dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm)
		).subscribe(() => {
			this.libraryService.removeFolder(folder.id)
				.subscribe({
					next: () => {
						const index = this.collectionItems.folders.findIndex((each)=> each.id === folder.id);
						this.collectionItems.folders.splice(index, 1);
						this.collectionItems.folders = [...this.collectionItems.folders];
					},
					error: () => {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('modal-remove-collection-folder_error-title'),
								message: this.translate.instant('modal-remove-collection-folder_error-message'),
								confirmText: this.translate.instant('common_close')
							} as MessageDialogData
						});
					}
				});
		});
	}

	public openEditFolderModal () {
		return this.modal.open(ManageLibraryCollectionFolderComponent, false, {
			data: {
				createMode: false,
				existingFolders: this.collectionItems.folders,
				folder: this.folder
			}
		}).afterClosed().subscribe((res) => {
			if (res.dismissed) {
				return;
			}
			if (res.request_failed) {
				return this.dialog.open(MessageDialogComponent, {
					data: {
						title: this.translate.instant('library-collections_edit-folder-fail-title'),
						message: this.translate.instant('library-collections_edit-folder-fail-message'),
						confirmText: this.translate.instant('common_close')
					} as MessageDialogData
				});
			}
			this.folder.name = res.name;
			this.folder.updated_at = res.updated_at;
		});
	}

	private emptyString (value) {
		return value == null || value === '';
	}
}
