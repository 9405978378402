import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageRefService {
	constructor (@Inject(PLATFORM_ID) private platformId) {}

	public get localStorage (): Storage {
		if (!this.isBrowser()) {
			throw new Error('Must be in a browser platform to use localStorage');
		}
		return this.getLocalStorage();
	}

	public get sessionStorage (): Storage {
		if (!this.isBrowser()) {
			throw new Error('Must be in a browser platform to use sessionStorage');
		}
		return this.getSessionStorage();
	}

	public isBrowser (): boolean {
		return isPlatformBrowser(this.platformId);
	}

	private getLocalStorage (): Storage {
		return localStorage;
	}

	private getSessionStorage (): Storage {
		return sessionStorage;
	}
}
