<section class="main-header">
	<div class="license-info">
		<ng-container *ngIf="license">
			<span>{{ license.salesforce_license.name | titlecase }} – </span>
			<span>{{
				(license.salesforce_license.seat_type === "user"
					? "license-management_multi-course"
					: "license-management_single-course"
				) | translate
			}}</span>
			<span class="bullet">&#8226;</span>
			<span
				translate="{{ license.salesforce_license.seat_overage_policy === SeatOveragePolicies.RESTRICTED ?
					'license-management_seats_used' : 'license-management_seats_consumed' }}"
				[translateParams]="{
					consumed: license.salesforce_license.total_seats_consumed,
					total: license.salesforce_license.total_seats
				}"
			></span>
		</ng-container>
	</div>

	<search-input *ngIf="dataSource"
		attr.aria-label="{{'common_search' | translate}}"
		(updateEvent)="doSearch($event)"
		[viewByUser]="viewByUser$$ | async"></search-input>
</section>

<div class="content" (scroll)="onScroll($event)">
	<table
		*ngIf="dataSource"
		mat-table
		matSort
		[@.disabled]="true"
		[dataSource]="dataSource"
		multiTemplateDataRows
		class="main-table"
		(matSortChange)="sortData($event)"
	>
		<ng-container matColumnDef="rolesList">
			<td
				mat-cell
				*matCellDef="let element"
				[attr.colspan]="dataSource.displayColumns.length"
				class="collapsible-td"
			>
				<table
					[dataSource]="element.roles"
					mat-table
					class="roles-table"
					*ngIf="element.expanded"
				>
					<ng-container matColumnDef="course_name">
						<td mat-cell *matCellDef="let element">
							{{ element.course_name }} ({{ element.instructor_name | titlecase }})
						</td>
					</ng-container>

<!-- This may come back -->
<!--					<ng-container matColumnDef="role">-->
<!--						<td mat-cell *matCellDef="let element">{{ element.role | translate }}</td>-->
<!--					</ng-container>-->

					<ng-container matColumnDef="last_login_at">
						<td mat-cell *matCellDef="let element"></td>
					</ng-container>

					<ng-container matColumnDef="total_courses">
						<td mat-cell *matCellDef="let element"></td>
					</ng-container>

					<ng-container matColumnDef="session_count">
						<td mat-cell *matCellDef="let element">
							{{ element.session_count }}
						</td>
					</ng-container>

					<ng-container matColumnDef="remove_action">
						<td mat-cell *matCellDef="let roleElement">
							<button *ngIf="canBeRemoved(element, roleElement)" mat-button class="action-button remove-single-course-button" (click)="removeFromCourse(element, roleElement)">
								<i class="ficon-app-x" *ngIf="!roleElement.removing"></i>
								<i class="ficon-spinner ficon-spin" *ngIf="roleElement.removing"></i>
							</button>
						</td>
					</ng-container>

					<tr
						mat-row
						*matRowDef="let row; columns: dataSource.roleColumns"
					></tr>
				</table>
			</td>
		</ng-container>

		<!-- fullname Column -->
		<ng-container matColumnDef="full_name" [sticky]="!UADetect.isMobile()">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="name" disableClear class="sort-by-name-header"  [class.borderRight]="(isScrolled$ | async) && !UADetect.isMobile()">
				<span translate="common_user"></span>
			</th>
			<td mat-cell *matCellDef="let element" [class.borderRight]="(isScrolled$ | async) && !UADetect.isMobile()">
				<button
					(click)="dataSource.toggleRoleList(element)"
					mat-button
					class="role-list-toggle-button"
					[attr.aria-label]="'license-management_role-list-toggle-aria-label' | translate: {userFullName: element.full_name}"
					[attr.aria-expanded]="element.expanded"
				>
					<i *ngIf="!element.expanded" class="ficon-app-chevron-down"></i>
					<i *ngIf="element.expanded" class="ficon-app-chevron-down rotated"></i>
				</button>

				{{ element.full_name | titlecase }} ({{ element.email }})
			</td>
		</ng-container>

		<!-- highest_role Column: This may come back -->
<!--		<ng-container matColumnDef="highest_role">-->
<!--			<th mat-header-cell *matHeaderCellDef>-->
<!--				<span>-->
<!--					<span translate="modal-edit-user_role"></span>-->
<!--					<button-->
<!--						mat-icon-button-->
<!--						class="btn-none filter-btn"-->
<!--						attr.aria-label="{{'license-management_seats-filter-role' | translate}}"-->
<!--						aria-haspopup="menu"-->
<!--						[matMenuTriggerFor]="rolesMenu">-->
<!--						<i class="ficon-filter-list"></i>-->
<!--					</button>-->
<!--				</span>-->
<!--				<mat-menu class="roles-menu" #rolesMenu="matMenu" yPosition="below">-->
<!--					<ng-container *ngFor="let role of roles">-->
<!--						<button mat-menu-item class="role-menu-item"-->
<!--							(click)="filterRoles(role)">-->
<!--							{{role | translate}}-->
<!--						</button>-->
<!--					</ng-container>-->
<!--				</mat-menu>-->
<!--				<div class="pill-btn" *ngIf="dataSource.role | async as role">-->
<!--					<span tabindex="0">{{role | translate}}</span>-->
<!--					<button class="remove-filter" attr.aria-label="{{'common_remove' | translate}}" (click)="removeRole()"><i class="ficon-app-x"></i></button>-->
<!--				</div>-->
<!--			</th>-->
<!--			<td mat-cell *matCellDef="let element">{{ $any((element.roles | highestRole)).role | translate }}</td>-->
<!--		</ng-container>-->

		<!-- last_login_at Column -->
		<ng-container matColumnDef="last_login_at">
			<th mat-header-cell *matHeaderCellDef>
				<span>
					<span translate="group-settings_last-login"></span>
					<button
						mat-icon-button
						class="btn-none filter-btn"
						attr.aria-label="{{'license-management_seats-filter-login' | translate}}"
						aria-haspopup="menu"
						(click)="toggleDatePicker()"
						cdkOverlayOrigin
						#trigger="cdkOverlayOrigin">
						<i class="ficon-filter-list"></i>
					</button>
				</span>
				<ng-template
					cdkConnectedOverlay
					[cdkConnectedOverlayOrigin]="trigger"
					[cdkConnectedOverlayOpen]="datePickerOpen"
					[cdkConnectedOverlayHasBackdrop]="true"
					[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
					(backdropClick)="toggleDatePicker()">
					<div class="seats-date-picker mat-menu-panel mat-elevation-z4">
						<mat-form-field appearance="outline" (click) = "$event.stopPropagation()">
							<mat-label translate="license-management_seats-select-dates"></mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matInput matStartDate placeholder="Start date" #dateRangeStart>
								<input matInput matEndDate placeholder="End date" #dateRangeEnd (dateChange)="filterLogin(dateRangeStart, dateRangeEnd)">
							</mat-date-range-input>
							<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
							<mat-datepicker-toggle matSuffix [for]="picker" (keydown.Tab)="toggleDatePicker()"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</div>
				</ng-template>
				<div class="pill-btn" *ngIf="dataSource.login | async as login">
					<span tabindex="0">{{login.lower | date: "shortDate"}} — {{login.upper | date: "shortDate"}}</span>
					<button class="remove-filter" (click)="removeLogin()" attr.aria-label="{{'common_remove' | translate}}"><i class="ficon-app-x"></i></button>
				</div>
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.last_login_at | date: "short" }}
			</td>
		</ng-container>

		<!-- total_courses Column -->
		<ng-container matColumnDef="total_courses">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="courses" disableClear class="sort-by-course-count-header"><span translate="license-management_seats-course-count"></span></th>
			<td mat-cell *matCellDef="let element">{{ element.total_courses }}</td>
		</ng-container>

		<!-- total_sessions Column -->
		<ng-container matColumnDef="total_sessions">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="videos" disableClear class="sort-by-video-count-header"><span translate="license-management_seats-video-count"></span></th>
			<td mat-cell *matCellDef="let element">{{ element.total_sessions }}</td>
		</ng-container>

		<!-- remove_action Column -->
		<ng-container matColumnDef="remove_action">
			<th mat-header-cell *matHeaderCellDef>
				<span translate="common_remove"></span>
			</th>
			<td mat-cell *matCellDef="let element">
				<button mat-button class="action-button remove-from-all-courses-button" *ngIf="dataSource.canBeRemove(element)" (click)="removeFromAllCourses(element)">
					<i class="ficon-app-x" *ngIf="!element.removing"></i>
					<i class="ficon-spinner ficon-spin" *ngIf="element.removing"></i>
				</button>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="dataSource.displayColumns; sticky: true"
		></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: dataSource.displayColumns; let element"
			[class.expanded]="element.expanded"
		></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: ['rolesList']; let element"
			class="role-list"
			[class.expanded]="element.expanded"
		></tr>
	</table>
	<!-- check both because we don't want the zero state showing when a search or filter is active -->
	<ngx-zero-state *ngIf="license?.salesforce_license.total_seats_consumed === 0 && dataSource?.dataLength === 0">
		<p zero-state-title>{{'license-management_no-users-title' | translate}}</p>
		<p zero-state-subtext>{{ 'license-management_no-users-subtitle' | translate }}</p>
		<div zero-state-body>
			<img class="zero-state-image" src="https://staticassets.goreact.com/waving-astronaut.svg" alt="No Users">
		</div>
	</ngx-zero-state>
</div>
<div class="footer" [hidden]="license?.salesforce_license.total_seats_consumed === 0 && dataSource?.dataLength === 0">
	<div></div> <!-- placeholder div for spacing -->
	<div>
		<span *ngIf="dataSource && !(dataSource.isLoaded | async)" class="ficon-spinner ficon-spin" aria-live="assertive" attr.aria-label="{{'common_loading'| translate}}"></span>
	</div>
	<div>
		<span>
			{{  'license-management_seats_page-indicator' | translate: {
					'current': dataSource?.currentPage || 1,
					'all': dataSource?.lastPage || 1
				}
			}}
		</span>
		<button
			mat-icon-button
			class="first-page"
			attr.aria-label="{{'license-management_seats_go-to-first-page' | translate}}"
			(click)="goToFirstPage()"
			[disabled]="!dataSource || dataSource?.currentPage === 1">
			<mat-icon class="ficon-app-first-page"></mat-icon>
		</button>
		<button
			mat-icon-button
			class="previous-page"
			attr.aria-label="{{'license-management_seats_go-to-previous-page' | translate}}"
			(click)="goToPreviousPage()"
			[disabled]="!dataSource || dataSource?.currentPage === 1">
			<mat-icon class="ficon-app-chevron-left"></mat-icon>
		</button>
		<button
			mat-icon-button
			class="next-page"
			attr.aria-label="{{'license-management_seats_go-to-next-page' | translate}}"
			(click)="goToNextPage()"
			[disabled]="!dataSource || dataSource?.currentPage === dataSource?.lastPage">
			<mat-icon class="ficon-app-chevron-right"></mat-icon>
		</button>
		<button
			mat-icon-button
			class="last-page"
			attr.aria-label="{{'license-management_seats_go-to-last-page' | translate}}"
			(click)="goToLastPage()"
			[disabled]="!dataSource || dataSource?.currentPage === dataSource?.lastPage">
			<mat-icon class="ficon-app-last-page"></mat-icon>
		</button>
	</div>
</div>
