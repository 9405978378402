<session-analytic-item-view
	(onExpanded)="onExpanded.emit($event)"
	[isExpanded]="isExpanded"
	title="{{ 'session-analytics_pace-graph' | translate }}"
	[dataSummary]="'session-analytics_pace-graph-summary' | translate: {averagePace: getAveragePace()}"
	[shouldTease]="shouldTease">
	<div graph
		class="pace-graph-container"
		role="application">
		<ng-container *ngIf="utterances.length; else zeroState">
			<highcharts-chart
				#chart
				[Highcharts]="Highcharts"
				[options]="chartOptions"
			></highcharts-chart>
		</ng-container>

		<ng-template #zeroState>
			<analytic-graph-zero-state>
				{{(media.isYoutube() ? 'transcript-viewer-zero-state_youtube-subtext' : 'session-analytics_pace-graph-zero-state') | translate}}
			</analytic-graph-zero-state>
        </ng-template>
	</div>
</session-analytic-item-view>

