import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { UniqueIdGeneratorUtil } from 'ngx/go-modules/src/utilities/unique-id-generator/unique-id-generator.util';

interface MaxCharactersExceeded {
	maxExceeded: boolean;
	amountExceededBy: number;
}

@Component({
	selector: 'ngx-rich-text-editor',
	templateUrl: './rich-text-editor.component.html',
	styleUrls: ['./rich-text-editor.component.scss'],
	encapsulation : ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
		  provide: NG_VALUE_ACCESSOR,
		  useExisting: forwardRef(() => NgxRichTextEditorComponent),
		  multi: true
		}
	  ]
})
export class NgxRichTextEditorComponent implements OnInit, ControlValueAccessor {
	@Input() public content: any = null;
	@Input() public placeholder: string;
	@Input() public autoSize: boolean = false;
	@Input() public autoFocus: boolean = false;
	@Input() public toolbarBelow: string = '';
	@Input() public toolbarOptions: string = 'bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist';
	@Input() public toolbarGroups: boolean = false;
	@Input() public showTopBorder: boolean = true;
	@Output() public enterEvent: EventEmitter<any> = new EventEmitter();
	@Output() public updateEvent: EventEmitter<string> = new EventEmitter();
	@Output() public maxCharactersEvent: EventEmitter<MaxCharactersExceeded> = new EventEmitter();

	public tinymceOptions: any;
	public editorContent: any = null;
	public onChangeFn: any;
	public onTouchedFn: any;
	public maxCharacters: number = clientSettings.CommentTextCharacterLimit;
	public currentEditor: any;
	public uniqueId = null;

	constructor () {
		this.uniqueId = UniqueIdGeneratorUtil.getId();
	}

	public ngOnInit () {
		this.editorContent = this.content;
		this.tinymceOptions = {
			selector: `#${this.uniqueId} textarea`,
			browser_spellcheck: true,
			menubar: false,
			statusbar: false,
			height: 225,
			plugins: 'lists advlist',
			toolbar: this.toolbarOptions,
			forced_root_block: false,
			object_resizing : false,
			inline_styles: false,
			formats: {
				underline: { inline: 'u', exact : true }
			},
			content_style: `
				.mce-content-body { font-family: proxima-nova, "Open Sans", sans-serif; font-size: 14px; margin: 8px; min-height: 0 }
				.mce-content-body img { border-radius: 10px; width: 100%; max-width: 450px; min-width: 268px; }
			`
		};
		if (this.autoSize) {
			this.tinymceOptions.plugins = this.tinymceOptions.plugins + ' autoresize';
			this.tinymceOptions.height = 35;
			this.tinymceOptions.autoresize_min_height = 35;
			this.tinymceOptions.autoresize_max_height = 271;
			this.tinymceOptions.autoresize_bottom_margin = 0;
		}
		// TODO implement on upgrade to angular13 and tinymce5.2
		if (this.toolbarGroups) {
			this.tinymceOptions.toolbar_groups = {
				formatting: {
					icon: 'more-drawer',
					tooltip: 'More Options',
					items: 'bold italic underline strikethrough | aligncenter alignleft alignright bullist numlist'
				}
			};
		}
	}

	public blurHandler ($event: EventObj<FocusEvent>) {
		this.placeholderHandler();
		if (this.editorContent && this.editorContent.includes('class="placeholder')) {
			$event.editor.setContent(this.editorContent);
		} else {
			if (this.editorContent) {
				this.updateEvent.emit($event.editor.getContent());
				this.onChange($event);
			}
		}
	}

	public focusHandler ($event: EventObj<FocusEvent>) {
		if (this.editorContent && this.editorContent.includes('class="placeholder')) {
			this.editorContent = '';
		}
		if (this.editorContent) {
			$event.editor.setContent(this.editorContent);
			this.focusOnField();
		}
	}

	public keyupHandler ($event: EventObj<KeyboardEvent>) {
		let node = $event.editor.selection.getNode();
		const nodeName = node.nodeName.toLowerCase();

		// styles applied to text are wrapped in span, this checks if span or styled text belongs under list element
		const isOnListNode = this.isRangeOnListNode(node);

		/*
			TODO: Remove these parts once we've updated tinymce to higher versions
			We should be able to have more options and control on how to alter line breaks behavior
		*/
		// to determine when to add/remove bullets, we check the range
		// (blinking focus " | ") on textarea if its on a list node
		if ($event.event.key === 'Enter' && $event.event.shiftKey && (nodeName === 'li' || isOnListNode)) {
			// tinymce sometimes adds <br> inside li element so we can't determine whether li contains values or not
			// this removes extraneous br content inside the li element
			node.querySelectorAll('br').forEach((element) => {
				element.remove();
			});
			node = $event.editor.selection.getNode();

			// check li content if empty or not
			// remove li if content is empty
			if (node.innerHTML.length > 0) {
				$event.editor.insertContent('<li></li>');

				// when adding/inserting new element, tinymce creates <br> with attr
				// [data-mce-bogus] when an added element is empty
				// this adds an extra list element with <br> into the list
				// instead of adding 1, we have 2 newly inserted li so we should remove the extra one
				/* eslint-disable-next-line max-len */
				// https://stackoverflow.com/questions/48882595/tinymce-br-data-mce-bogus-1-randomly-set-on-empty-textarea
				const bogusElem = $event.editor.getBody().querySelector('li [data-mce-bogus]');
				const listElements = $event.editor.getBody().querySelectorAll('li');
				const emptyElements = Array.from(listElements).find((li: HTMLElement) => {
					return li.childNodes.length === 0;
				});
				if (bogusElem && emptyElements) {
					const listElement = bogusElem.parentElement;
					const range = new Range();
					// since we'll be removing the bogus element, we should return cursor to previous sibling element
					range.setStart(listElement.previousElementSibling, 0);
					range.setEnd(listElement.previousElementSibling, 0);
					$event.editor.selection.setRng(range);
					listElement.remove();
				}
			} else {
				node.remove();
				$event.editor.setContent($event.editor.getContent() + '<p></p>');
				$event.editor.selection.select($event.editor.getBody().lastChild, true);
			}
		}

		this.updateEvent.emit($event.editor.getContent());
		this.onChange($event);
	}

	public keydownHandler ($event) {
		const nodeName = $event.editor.selection.getNode().nodeName.toLowerCase();

		if ($event.event.key === 'Enter') {
			if (!$event.event.shiftKey) {
				this.onChange($event);
				this.enterEvent.emit($event);
			} else if ($event.event.shiftKey && nodeName === 'li') {
				$event.event.preventDefault();
			}
		}
	}

	public initHandler ($event: EventObj<any>) {
		this.currentEditor = $event.editor;
		this.currentEditor.shortcuts.add('meta+alt+o', 'OrderedList', () => {
			this.currentEditor.execCommand('InsertOrderedList');
		});
		this.currentEditor.shortcuts.add('meta+alt+u', 'UnorderedList', () => {
			this.currentEditor.execCommand('InsertUnorderedList');
		});
		this.placeholderHandler();
		if (this.editorContent) {
			this.currentEditor.setContent(this.editorContent);
		}
		if (this.autoFocus) {
			this.focusOnField();
		}
	}

	public focusOnField () {
		setTimeout(() => {
			this.currentEditor.focus();
			// if content then do trickery to put cursor at end of content
			if (this.editorContent) {
				setTimeout(() => {
					this.currentEditor.focus();
					this.currentEditor.selection.select(this.currentEditor.getBody(), true);
					this.currentEditor.selection.collapse(false);
				});
			}
		});
	}

	// TODO move to init on upgrade to angular13 and tinymce5.2 so it is an actual placeholder instead of our hack
	public placeholderHandler () {
		if (!this.editorContent && !this.autoFocus) {
			this.editorContent = '<p style="color: #707070" class="placeholder">' + this.placeholder + '</p>';
		}
	}

	/**
	 * Implements ControlValueAccessor
	 */
	public onChange ($event) {
		if (this.onChangeFn) this.onChangeFn($event.editor.getContent());
		this.updateCharacterCount($event.editor.getContent({format: 'text'}));
	}

	public updateCharacterCount (textContent) {
		if (textContent.length > this.maxCharacters) {
			this.maxCharactersEvent.emit({
				maxExceeded: true,
				amountExceededBy: textContent.length - this.maxCharacters
			});
		} else {
			this.maxCharactersEvent.emit({
				maxExceeded: false,
				amountExceededBy: 0
			});
		}
	}

	public onTouched () {
		if (this.onTouchedFn) this.onTouchedFn();
	}

	public registerOnChange (onChange: any): void {
		this.onChangeFn = onChange;
	}

	public registerOnTouched (onTouched: any): void {
		this.onTouchedFn = onTouched;
	}

	public writeValue (text: string): void {
		if (this.currentEditor) {
			this.currentEditor.setContent(text);
		}
		this.editorContent = text;
	}

	private isRangeOnListNode (node) {
		let isOnListNode = false;
		while(node.parentNode) {
			node = node.parentNode;
			if (node.nodeName.toLowerCase() === 'li') {
				isOnListNode = true;
				break;
			}
		}
		return isOnListNode;
	}
}
