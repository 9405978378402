<div class="ngx-dialog">
	<div class="dialog-header">
		<span translate="invite-user-dialog_title"></span>
	</div>
	<div class="dialog-content" [formGroup]="form" #content>
		<p translate="invite-user-dialog_instructors"></p>
		<section>
			<mat-form-field appearance="outline" floatLabel="always" class="emails">
				<mat-label translate="invite-user-dialog_email-label"></mat-label>
				<textarea matInput formControlName="emails"></textarea>
				<mat-hint translate="invite-user-dialog_email-hint"></mat-hint>

				<mat-error
					*ngIf="form.controls['emails'].hasError('required')"
					translate="invite-user-dialog_email-error-required">
				</mat-error>
				<mat-error
					*ngIf="form.controls['emails'].hasError('multi')">
					{{
						'common-validation_emails-invalid' | translate:{emails: getInvalidEmails()}
					}}
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always" class="permission">
				<mat-label translate="license-management_permissions"></mat-label>
				<mat-icon
					matSuffix
					class="ficon-app-help-circle-outline"
					[goTooltip]="'invite-user-dialog_permission-tooltip' | translate"
					goTooltipPlacement="top|right">
				</mat-icon>
				<mat-select formControlName="admin">
					<mat-option
						[value]="false">
						<span translate="license-management_permissions-instructor"></span>
					</mat-option>
					<mat-option
						[value]="true">
						<span translate="license-management_permissions-admin"></span>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
	</div>
	<div class="dialog-footer">
		<button type="button" class="cancel-button" mat-button (click)="cancel()">
			<span translate="common_cancel"></span>
		</button>
		<button
			mat-flat-button
			color="warn"
			type="button"
			class="submit-btn"
			(click)="inviteHolders$.next()"
			[disabled]="submitting$ | async"
			[focusOnFirstInvalidField]="{formGroup: form, formWrapper: content}">
			{{
				((submitting$ | async) ?
					'invite-user-dialog_sending_invitations' :
					'invite-user-dialog_send_invitations') | translate
			}}
		</button>
		<mat-error
			*ngIf="errorMessage$ | async as errorKey"
			[translate]="errorKey">
		</mat-error>
	</div>
</div>
