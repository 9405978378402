<div class="filter-container comment-filter-marker" [formGroup]="form">
	<mat-form-field goSelect>
		<mat-label>{{ 'library-collections_add-markers' | translate }}</mat-label>
		<mat-select
			multiple
			formControlName="selectedMarkers"
			disableOptionCentering
			[placeholder]="'marker-filter-dropdown-placeholder' | translate"
			panelClass="offsetPanel markerFilterPanel">
			<mat-select-trigger>
				<!-- need this class for goSelect prepend label -->
				<span class="mat-select-min-line">
					<span *ngIf="allSelected.selected; else numSelected">{{ 'common_all' | translate }}</span>
					<ng-template #numSelected>
						<span class="num-selected-badge">{{ form.value.selectedMarkers.length }}</span>
					</ng-template>
				</span>
			</mat-select-trigger>
			<mat-option
				#allSelected
				(click)="toggleAll()"
				[value]="0">
				{{ 'common_all' | translate}}
			</mat-option>
			<mat-divider *ngIf="aiMarkers.length"></mat-divider>
			<mat-optgroup [label]="'activity-editor-new_ai-markers-modal-ai-markers' | translate" *ngIf="aiMarkers.length" class="ai-marker-group">
				<mat-option
					*ngFor="let marker of aiMarkers"
					(click)="toggleOne()"
					[value]="marker.tag_id">
					<span class="marker-with-count">
						<marker [marker]="marker" [aiStyle]="true" [withName]="true"></marker>
						<small>({{ marker.count }})</small>
					</span>
				</mat-option>
			</mat-optgroup>
			<mat-divider *ngIf="activityMarkers.length"></mat-divider>
			<mat-optgroup [label]="'marker-filter-activity-markers' | translate" *ngIf="activityMarkers.length" class="activity-marker-group">
				<mat-option
					*ngFor="let marker of activityMarkers"
					(click)="toggleOne()"
					[value]="marker.tag_id">
					<span class="marker-with-count">
						<marker [marker]="marker" [withName]="true"></marker>
						<small>({{ marker.count }})</small>
					</span>
				</mat-option>
			</mat-optgroup>
		</mat-select>
	</mat-form-field>
</div>
