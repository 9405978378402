<div class="manage-collections-panel-menu">
	<div class="manage-collections-panel-header">
		<h1
			translate="library-collections_manage-collections"
			class="manage-collections-panel-title">
		</h1>
	</div>
	<div class="manage-collections-panel-content">
		<div class="manage-collections-subheader">
			<h2
				translate="library-collections_manage-collections-subtitle"
				class="manage-collections-subtitle">
			</h2>
			<h2
				translate="library-collections_manage-collections-visible"
				class="manage-collections-subtitle">
			</h2>
		</div>
		<div
			cdkDropList
			class="collection-cards"
			role="list"
			(cdkDropListDropped)="drop($event)">
			<div
				#listItem
				cdkDrag
				tabindex="0"
				class="collection-card"
				role="listitem"
				*ngFor="let collection of collections; let i = index"
				[attr.aria-label]="'aria-ng-sortable_inform-reorderable-name' | translate: {name: collection.name}"
				accessibleDropList
				[count]="collections.length"
				[currentIndex]="i"
				[name]="collection.name"
				[itemId]="collection.id"
				(activeItemChange)="onActiveItemChange($event)"
				(cancelReorder)="onCancelReorder($event)"
				(finalizePosition)="onFinalizePosition()">
					<div class="collection-card-handle" cdkDragHandle>
						<i class="collection-card-drag-handle ficon-app-gripper-dots-vertical"></i>
					</div>
					<div class="collection-card-title">
						{{collection.name}}
					</div>
					<button
						*ngIf="canEditCollection(collection)"
						(click)="modifyCollection(collection)"
						type="button"
						class="btn btn-none edit-collection-btn"
						data-cy="edit-collection-btn"
						[attr.aria-label]="'library-collections_manage-collections-edit' | translate: {collection: collection.name}">
						<i class="ficon-app-edit"></i>
					</button>
					<button
						*ngIf="canDeleteCollection(collection)"
						(click)="deleteCollection(collection)"
						type="button"
						class="btn btn-none remove-collection-btn"
						data-cy="delete-collection-btn"
						[attr.aria-label]="'library-collections_manage-collections-remove' | translate: {name: collection.name}">
						<i class="ficon-app-x"></i>
					</button>
					<mat-checkbox
						*ngIf="collection.type !== libraryTypes.MINE"
						color="primary"
						class="visibility-check"
						id="visible-check-{{collection.id}}"
						[checked]="!findMyPermission(collection).is_hidden"
						(change)="toggleCollectionVisibility(findMyPermission(collection))"
						[attr.aria-label]="'library-collections_manage-collections-visibility' | translate: {collection: collection.name}">
					</mat-checkbox>
				</div>
		</div>
		<button
			type="button"
			(click)="modifyCollection()"
			class="link-btn add-btn add-collection-btn"
			data-cy="add-collection-btn"
			wcag-ignore
			translate="library-collections_manage-collections-add">
		</button>
	</div>
	<footer class="manage-collections-panel-footer">
		<button
			type="button"
			class="tertiary-btn cancel-btn"
			wcag-ignore
			(click)="cancel()"
			translate="common_cancel">
		</button>

		<button
			type="submit"
			class="primary-btn submit-btn"
			wcag-ignore
			[disabled]="saving$ | async"
			(click)="saveCollectionPermissions()"
			translate>
			common_save-and-close
			<i *ngIf="saving$ | async" class="ficon-spinner ficon-spin" aria-live="polite" attr.aria-label="{{'feedback-session_controller-saving'| translate}}"></i>
		</button>
	</footer>
</div>
