<mat-expansion-panel
	[expanded]="isExpanded"
	togglePosition="before"
	>
	<mat-expansion-panel-header
		collapsedHeight="41px"
		expandedHeight="41px"
		(click)="toggleExpand()"
		(keyup.enter)="toggleExpand()">
		<mat-panel-title>
			<span>{{title}}</span>
		</mat-panel-title>
		<mat-panel-description
			*ngIf="!hideSummary"
			[attr.aria-hidden]="shouldTease">
			<span>{{dataSummary}}</span>
		</mat-panel-description>
	</mat-expansion-panel-header>

	<div class="graph">
		<ng-content select="[graph]"></ng-content>
	</div>
</mat-expansion-panel>
