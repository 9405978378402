import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LICENSE_TRANSACTION_TYPE } from 'go-modules/payment-panel/payment-panel.controller';
import type { Product } from 'go-modules/services/group/product';
import { ProductTier } from 'ngx/go-modules/src/interfaces/licenses/product-tier';
import {
	InvertedSalesforceLicense,
	NgxLicenseUpgradeService
} from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';

export interface LicenseProductChooserData {
	license: InvertedSalesforceLicense;
	transactionType: LICENSE_TRANSACTION_TYPE;
	products: Product[];
	launchAsInfo?: boolean;
	launchAsNonAdminRequest?: boolean;
}
@Component({
	selector: 'compare-products-dialog',
	templateUrl: './compare-products-dialog.component.html',
	styleUrls: ['./compare-products-dialog.component.scss']
})
export class CompareProductsDialogComponent implements OnInit {
	public products: Product[];
	public license: InvertedSalesforceLicense;
	public transactionType: string;
	public alreadySentUpgradeRequest: boolean = false;

	constructor (
		public dialogRef: MatDialogRef<CompareProductsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: LicenseProductChooserData,
		@Inject(userServiceToken) private userService: UserService
	) {}

	// A small helper to open the dialog with all the correct typing
	public static open (
		dialog: MatDialog,
		config: MatDialogConfig<LicenseProductChooserData>
	): MatDialogRef<CompareProductsDialogComponent> {
		if (config.data.transactionType === LICENSE_TRANSACTION_TYPE.SEATS) {
			throw new Error('Transaction type of SEATS is not supported.');
		}

		if (config.data.license === null && config.data.transactionType !== LICENSE_TRANSACTION_TYPE.INITIAL) {
			throw new Error('Bad configuration.');
		}

		return dialog.
			open<CompareProductsDialogComponent, LicenseProductChooserData>(CompareProductsDialogComponent, config);
	}

	public ngOnInit () {
		if (this.data.launchAsNonAdminRequest) {
			this.alreadySentUpgradeRequest = localStorage.getItem(
				NgxLicenseUpgradeService.createTeaseWallUpgradeRememberKey(
					// license.license.id assumes the inverted sf license is passed into this dialog
					// when launching as non-admin request
					this.userService.currentUser.user_id, this.data.license.license.id
				)
			) != null;
		}
		// STAB-1458 See note in constructCurrentLicenseProductToProductFormat
		this.license = this.data.license;
		this.transactionType = this.data.transactionType;
		// STAB-1458 This gets mutated. Other components that rely on these products might be relying on this mutation
		this.products = this.data.products;

		if (this.license !== null) {
			// No need for this line when STAB-1455 is done.
			this.license.licenseProduct = this.license.licenseProduct ?? this.license.license_product;

			if (this.transactionType === LICENSE_TRANSACTION_TYPE.UPGRADE && !this.data.launchAsInfo) {
				if (this.license.self_pay) {
					// Filter out cheaper price than the current one
					this.products = this.products.filter((product) =>
						parseFloat(product.price) >= parseFloat(this.license.licenseProduct.product.price));

					// true if license product is a currently available product
					// false if license product is an old product that is not available anymore
					const isAvailableLicenseProduct = this.products.some((product) =>
						product.product_id === this.license.licenseProduct.product_id);

					if (!isAvailableLicenseProduct) {
						// Even though the current license product is not available anymore, we still want to show it
						// as the "current plan"
						this.products.push(this.constructCurrentLicenseProductToProductFormat());
					}

					// Remove products with the same tier except for the license
					// product which is used to show current plan
					this.products = this.products.filter((product) => {
						return product.licenseProduct.tier_name !== this.license.licenseProduct.tier_name ||
							product.product_id === this.license.licenseProduct.product_id;
					});
				}
			}
		}

		// Add action translation
		this.products = this.products.map((product) => {
			let actionTranslation = '';
			let hasAction = false;
			let upgradeRequestSent = false;

			if (this.license === null) {
				actionTranslation = 'common_purchase';
				hasAction = true;
			} else if (product.product_id === this.license.licenseProduct.product_id) {
				actionTranslation = 'product-card_current-plan-label';

				// Enable current plan button for renewal, or the modal is launched as info modal
				if (this.data.launchAsInfo || this.transactionType === LICENSE_TRANSACTION_TYPE.INITIAL) {
					hasAction = true;
					actionTranslation = 'license-plan-upgrade_renew_plan';
				}
			} else if (product.licenseProduct.tier_name === this.license.licenseProduct.tier_name) {
				hasAction = true;
				actionTranslation = 'common_upgrade';

				if (!this.license.self_pay) {
					hasAction = false;
					actionTranslation = 'license-plan-upgrade-essentials_plan';
				} else if (parseFloat(product.price) < parseFloat(this.license.licenseProduct.product.price)) {
					actionTranslation = 'common_downgrade';
				}
			} else {
				actionTranslation = 'common_downgrade';
				hasAction = true;

				if (this.isHigherTier(product.licenseProduct.tier_name, this.license.licenseProduct.tier_name)) {
					actionTranslation = 'common_upgrade';

					if (this.data.launchAsNonAdminRequest && this.alreadySentUpgradeRequest) {
						hasAction = false;
						actionTranslation = 'license-plan-upgrade_request-sent';
						upgradeRequestSent = true;
					}
				}
			}

			return {
				...product,
				actionTranslation,
				hasAction,
				upgradeRequestSent
			};
		});

		this.products.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
	}

	public close () {
		this.dialogRef.close(null);
	}

	public selectPackage (product) {
		this.dialogRef.close(product);
	}

	private constructCurrentLicenseProductToProductFormat (): Product {
		// STAB-1458 Any license that gets passed into this dialog potentially gets mutated.
		// It might be making other components rely on this mutation
		const licenseProduct = this.license.licenseProduct;
		const product = licenseProduct.product;
		product.licenseProduct = licenseProduct;
		return product;
	}

	private isHigherTier (tierName1: ProductTier, tierName2: ProductTier): boolean {
		return Object.values(ProductTier).indexOf(tierName1) > Object.values(ProductTier).indexOf(tierName2);
	}
}
