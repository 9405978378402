<div class="ngx-dialog goreact-modal">
	<div class="goreact-modal-header">
		<h4 class="modal-title">
			{{ data.title }}
		</h4>
		<button type="button" class="close-btn" (click)="close()" [attr.aria-label]="'common_close' | translate">
			<i class="ficon-app-x"></i>
		</button>
	</div>
	<div class="goreact-modal-body">
		<p [innerHTML]="data.message"></p>
		<p class="note" *ngIf="data.note" [ngClass]="{'warning': data.warningNote}" [innerHTML]="data.note"></p>
	</div>
	<div class="goreact-modal-footer">
		<button class="confirm-button" [ngClass]="[(data.confirmStyle || 'primary-btn')]" (click)="confirm()" data-cy="resolve-button">
			{{ data.confirmText || ('common_close' | translate) }}
		</button>
	</div>
</div>
