<form class="email-notifications" [formGroup]="form" (ngSubmit)="saveNotificationSettings(form)">
	<div class="content">
		<span class="content-header" translate="account-settings-panel_email-notifications"></span>
		<span *ngIf="user.email"
			class="content-subheader with-email"
			translate="account-settings-panel_email-notification-text"
			[translateParams]="{email: user.email}"></span>

		<section class="content-body">
			<ng-container *ngIf="user.email">
				<mat-form-field class="assignment-submitted-email-frequency-field" appearance="outline" *ngIf="assignmentSubmittedEmailFrequency && hasReviewerRoleOrHigher()">
					<mat-label translate="account-settings-panel_unviewed-assignment-submissions"></mat-label>
					<mat-select
						formControlName="assignmentSubmittedEmailFrequency"
						disableOptionCentering
						panelClass="offsetPanel">
						<mat-option
							*ngFor="let frequency of frequenciesNotImmediately"
							[value]="frequency">
							{{ frequency.name }}
						</mat-option>
					</mat-select>
					<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
				</mat-form-field>

				<mat-form-field class="feedback-submitted-email-frequency-field" appearance="outline" *ngIf="feedbackSubmittedEmailFrequency">
					<mat-label translate="account-settings-panel_unviewed-feedback"></mat-label>
					<mat-select
						formControlName="feedbackSubmittedEmailFrequency"
						disableOptionCentering
						panelClass="offsetPanel">
						<mat-option
							*ngFor="let frequency of frequenciesNotImmediately"
							[value]="frequency">
							{{ frequency.name }}
						</mat-option>
					</mat-select>
					<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
				</mat-form-field>

				<ng-container *ngIf="hasPresenterRole()">
					<mat-form-field class="new-assignment-email-frequency-field" appearance="outline" *ngIf="newAssignmentEmailFrequency">
						<mat-label translate="account-settings-panel_new-assignment"></mat-label>
						<mat-select
							formControlName="newAssignmentEmailFrequency"
							disableOptionCentering
							panelClass="offsetPanel">
							<mat-option
								*ngFor="let frequency of frequenciesNotImmediately"
								[value]="frequency">
								{{ frequency.name }}
							</mat-option>
						</mat-select>
						<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
					</mat-form-field>

					<mat-form-field class="assignment-due-email-frequency-field" appearance="outline" *ngIf="assignmentDueEmailFrequency && !isLti()">
						<mat-label translate="account-settings-panel_assignment-due"></mat-label>
						<mat-select
							formControlName="assignmentDueEmailFrequency"
							disableOptionCentering
							panelClass="offsetPanel">
							<mat-option
								*ngFor="let frequency of frequenciesNotWeekly"
								[value]="frequency">
								{{ frequency.name }}
							</mat-option>
						</mat-select>
						<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
					</mat-form-field>

					<mat-form-field class="assignment-graded-email-frequency-field" appearance="outline" *ngIf="assignmentGradedEmailFrequency">
						<mat-label translate="account-settings-panel_assignment-graded"></mat-label>
						<mat-select
							formControlName="assignmentGradedEmailFrequency"
							disableOptionCentering
							panelClass="offsetPanel">
							<mat-option
								*ngFor="let frequency of frequenciesNotHourly"
								[value]="frequency">
								{{ frequency.name }}
							</mat-option>
						</mat-select>
						<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
					</mat-form-field>
				</ng-container>
			</ng-container>
		</section>
	</div>

	<div class="content-footer" *ngIf="shouldShowSaveButton$ | async">
		<button
			class="primary-btn submit-btn"
			type="submit"
			translate="common_save-changes">
		</button>
	</div>
</form>
