<div class="media-thumbnail" [ngSwitch]="type" (click)="openMediaPreview()" data-cy="play-video" tabindex="0" [attr.aria-label]="'feedback-creator_media-thumbnail' | translate: {title: media.title}">
	<div class="image-container" *ngSwitchCase="'image'" [ngClass]="{'gray-background': media.media_status !== 'ready'}">
		<img
			class="image-thumbnail"
			*ngIf="media.media_status === 'ready'"
			src="{{media.media_url}}"
			alt="Image Thumbnail" />
		<i class="ficon-image-filled" *ngIf="media.media_status !== 'ready'"></i>
	</div>
	<div class="video-container" *ngSwitchCase="'video'">
		<img
			class="video-thumbnail"
			*ngIf="media.thumbnail_url"
			[attr.crossorigin]="media.isYoutube() ? null : 'anonymous'"
			src="{{media.thumbnail_url}}"
			alt="Media Thumbnail" />
		<div class="play-overlay">
			<i class="ficon-play"></i>
		</div>
	</div>
	<div class="audio-container" *ngSwitchCase="'audio'">
		<i class="ficon-microphone-outlined"></i>
		<div class="play-overlay">
			<i class="ficon-play"></i>
		</div>
	</div>
	<div class="document-container" *ngSwitchCase="'document'">
		<i class="ficon-document-filled"></i>
	</div>
	<div class="default-container" *ngSwitchDefault>
		<i class="ficon-spinner ficon-spin"></i>
	</div>
</div>
<button
	*ngIf="removeEvent.observers.length > 0"
	type="button"
	class="remove-btn"
	attr.aria-label="{{'common_remove'| translate}}"
	[goTooltip]="'feedback-creator_remove-attachment' | translate"
	goTooltipPlacement="top"
	(click)="removeEvent.emit(media)">
	<i class="ficon-app-x"></i>
</button>
