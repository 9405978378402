<div class="header">
	<h3 translate="{{titleTranslationKey}}"></h3>
	<button
		class="close-btn"
		type="button"
		(click)="close()"
		[attr.aria-label]="'common_close' | translate">
		<i class="ficon-app-x"></i>
	</button>
</div>
<div class="media-container">
	<ng-container *ngIf="data.mode === LicenseUpgradeSnackbarType.UPGRADE; else gifTemplate">
		<iframe width="100%"
			height="195"
			src="https://www.youtube.com/embed/Wfo-2Q-K9Js"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
			allowfullscreen></iframe>
	</ng-container>
	<ng-template #gifTemplate>
		<img src="https://staticassets.goreact.com/ai-upgrade-success.gif" [alt]="'license-upgrade-snackbar_success-gif-alt' | translate">
	</ng-template>
</div>
<p class="message">{{ messageTranslationKey | translate }}</p>
<div class="action-wrapper">
	<a [href]="learnMoreLink" class="tertiary-btn" target="_blank" rel="noopener noreferrer" translate="common_learn-more" wcag-ignore></a>
	<button class="primary-btn" [disabled]="loading$ | async" (click)="openPaymentPanelOrClose()" translate="{{actionButtonTranslationKey}}" wcag-ignore></button>
</div>
