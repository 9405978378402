<div class="license-container" *ngIf="(licenses$ | async)?.licenses as licenses; else loading">
	<div class="license-content" *ngIf="licenses.length; else zeroState">
		<table class="license-table">
			<thead>
				<tr >
					<th translate="license-management_license-name"></th>
					<th translate="license-management_start-date"></th>
					<th translate="license-management_end-date"></th>
					<th>
						<div>
							{{'license-management_seat-used' | translate}}
							<span
								[goTooltip]="'license-management-seat-used-tooltip' | translate"
								goTooltipPlacement="bottom|left"
								class="ficon-help"
								>
							</span>
						</div>
					</th>
					<th class="license-plan-cell" *ngIf="showPlanColumn$ | async" translate="license-management_plan"></th>
					<th class="action-cell"></th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let salesforceLicense of licenses">
					<tr class="license-row" [ngClass]="{'expanded': expandedLicenses[salesforceLicense.id]}">
						<td class="license-name">
							<button [attr.aria-label]="'license-management_expand-row' | translate" class="expand-button" mat-icon-button (click)="toggleLicenseHolders(salesforceLicense)">
								<span *ngIf="!expandedLicenses[salesforceLicense.id]" class="ficon-app-chevron-down"></span>
								<span *ngIf="expandedLicenses[salesforceLicense.id]" class="ficon-app-chevron-down rotated"></span>
							</button>
							{{salesforceLicense.name}} -
							<span class="seat-type">{{(salesforceLicense.seat_type === 'user' ? 'license-management_multi-course' : 'license-management_single-course') | translate}}</span>
						</td>
						<td>{{ salesforceLicense.license.current_access_date | date: 'mediumDate' }}</td>
						<td>
							<div class="license-end-date">
								<span>{{ salesforceLicense.license.ends_at | date: 'mediumDate' }}</span>
								<ng-template #tooltipTemplate>
									{{ salesforceLicense.dotIcon.expirationDot.toolTip.key | translate: {
										days: salesforceLicense.dotIcon.expirationDot.toolTip.value,
										renewalText: salesforceLicense.dotIcon.expirationDot.toolTip.renewalText
									} }}

									<p *ngIf="showRenewLicenseButton(salesforceLicense)">
										<button
											(click)="renewLicense(salesforceLicense)"
											[translate]="salesforceLicense.is_free_trial ? 'license-management_create-licenses': 'license-management_renew-license'">
										</button>
									</p>
								</ng-template>

								<div class="license-dot"
									*ngIf="showLicenseEndDateDot(salesforceLicense)"
									[ngClass]="salesforceLicense.dotIcon.expirationDot.color"
									[goTooltip]="tooltipTemplate"
									goTooltipPlacement="top"></div>
							</div>
						</td>
						<td>
							<div class="seats-usage">
								<a *ngIf="showLicenseMenu(salesforceLicense)"
									class="seats-used-lnk"
									(click)="navigateToLicenseSeatsManagement(salesforceLicense.license_id)">
									{{ salesforceLicense.total_seats_consumed }}
									<span *ngIf="salesforceLicense.seat_overage_policy === SeatOveragePolicies.RESTRICTED">
										/ {{salesforceLicense.total_seats}}
									</span>
								</a>
								<div *ngIf="!showLicenseMenu(salesforceLicense)">
									{{ salesforceLicense.total_seats_consumed }}
									<span *ngIf="salesforceLicense.seat_overage_policy === SeatOveragePolicies.RESTRICTED">
										/ {{salesforceLicense.total_seats}}
									</span>
								</div>
								<div class="license-dot"
									*ngIf="!expiredOnly && salesforceLicense.seat_overage_policy === SeatOveragePolicies.RESTRICTED"
									[ngClass]="salesforceLicense.dotIcon.usageDot.color"
									[goTooltip]="salesforceLicense.dotIcon.usageDot.toolTip.key | translate: {usagePercent: salesforceLicense.dotIcon.usageDot.toolTip.value, seatCount: salesforceLicense.dotIcon.usageDot.toolTip.seatCount}"
									goTooltipPlacement="top"></div>
							</div>
						</td>
						<td class="license-plan-cell" *ngIf="showPlanColumn$ | async">
							<license-plan-badge
								[license]="salesforceLicense.license"
								[disabled]="licenseBadgesDisabled$ | async"
								(actionClick)="onLicensePlanBadgeClicked(salesforceLicense)"
								[hideAction]="hideLicensePlanBadgeActionButton(salesforceLicense)"
							>
							</license-plan-badge>
						</td>
						<td class="license-action-btns-container" *ngIf="showLicenseMenu(salesforceLicense)">
							<div class="license-action-btns">
								<button
									(click)="purchaseSeats(salesforceLicense)"
									class="primary-btn inline-seats-btn"
									*ngIf="showPurchaseSeatsButton(salesforceLicense)">
									<span translate="license-management_purchase-add-seats"></span>
								</button>
								<button
									mat-icon-button
									class="action-menu-btn"
									[matMenuTriggerFor]="actionsMenu"
									[attr.aria-label]="'license-management_purchase-more-seats' | translate">
									<i class="ficon-app-dots-vertical"></i>
								</button>
								<mat-menu #actionsMenu class="action-menu">
									<button
										mat-menu-item
										(click)="navigateToLicenseSeatsManagement(salesforceLicense.license_id)"
										class="view-all-seats-btn">
										<span translate="license-management_view-all-seats"></span>
									</button>
									<button
										mat-menu-item
										(click)="purchaseSeats(salesforceLicense)"
										class="additional-seats-btn"
										*ngIf="showPurchaseSeatsButton(salesforceLicense)">
										<span translate="license-management_purchase-more-seats"></span>
									</button>
									<button
										mat-menu-item
										(click)="renewLicense(salesforceLicense)"
										class="renew-btn"
										*ngIf="showRenewLicenseMenuButton(salesforceLicense)">
										<span [translate]="salesforceLicense.is_free_trial ? 'license-management_create-licenses': 'license-management_renew-license'"></span>
									</button>
								</mat-menu>
							</div>
						</td>
					</tr>
					<tr class="instructors-row" *ngIf="expandedLicenses[salesforceLicense.id]">
						<td colspan="6" class="license-holder-content">
							<license-holder
								[salesforceLicense]="salesforceLicense"
								(viewSeatsEvent)="navigateToLicenseSeatsManagement(salesforceLicense.license_id, $event)"></license-holder>
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>

	<div *ngIf="showCreateLicenseButton && licenses.length" class="add-license-btn-wrapper">
		<button class="link-btn request-invoice-btn" (click)="createLicense(false)">
			{{'payment-panel-footer-switch-mode-invoice-label' | translate}}
		</button>
		<button class="secondary-btn add-license-btn" (click)="createLicense(true)">
			{{'license-management_create-licenses' | translate}}
		</button>
	</div>

	<ng-template #zeroState>
		<ngx-zero-state>
			<p zero-state-title>{{(expiredOnly ? 'license-management_zero-expired-title' : 'license-management_zero-title') | translate}}</p>
			<p zero-state-subtext>{{(expiredOnly ? 'license-management_zero-expired-subtitle' : 'license-management_zero-subtitle') | translate }}</p>
			<div zero-state-body>
				<button *ngIf="!expiredOnly" class="primary-btn" (click)="createLicense(true)">{{'license-management_create-licenses' | translate}}</button>
				<img class="middle-image" [src]="expiredOnly ? 'https://staticassets.goreact.com/astronaut-astroid.svg' : 'https://staticassets.goreact.com/floating-astronaut.svg'" alt="No Results">
			</div>
		</ngx-zero-state>
	</ng-template>

</div>

<ng-template #loading>
	<img src="https://staticassets.goreact.com/logo-goreact-spot-2022.svg" class="loading" alt="loading" />
</ng-template>
