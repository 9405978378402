import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@video-sharing/environments/environment';
import { fineUploaderToken, FineUploaderService } from 'go-modules/fine-uploader/fine-uploader.service';
import { pubnubToken, PubnubService } from 'go-modules/go-pubnub/pubnub.service';
import { Observable, tap } from 'rxjs';
import { AppData } from '@video-sharing/app/common/interfaces/app/app-data';
import { ExternalSessionPublicDetails } from '@video-sharing/app/common/interfaces/app/external-session-public-data';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	public details: ExternalSessionPublicDetails|null = null;

	constructor(
		private http: HttpClient,
		@Inject(fineUploaderToken) private fineUploader: FineUploaderService,
		@Inject(pubnubToken) private pubnub: PubnubService
	) {}

	public getData (): Observable<AppData> {
		return this.http.get<AppData>(`${environment.apiBaseUrl}/video-sharing/data`);
	}

	public getPublicSessionDetails (sessionId: string): Observable<ExternalSessionPublicDetails> {
		return this.http.get<ExternalSessionPublicDetails>(`${environment.apiBaseUrl}/video-sharing/public-details/${sessionId}`)
			.pipe(
				tap((details) => this.details = details)
			);
	}

	public configure (data: AppData): void {
		this.pubnub.configure(data.pubnub);
		this.fineUploader.configure(data.fine_uploader);
	}
}
