import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthProvider, OpenIdConfiguration } from '../../interfaces/auth-providers';
import { NgxAuthService } from '../../services/auth/auth.service';

const DEFAULT_PROVIDER = 'google';

@Component({
	selector: 'sso-provider-button',
	templateUrl: './sso-provider-button.component.html',
	styleUrls: ['./sso-provider-button.component.scss']
})
export class SsoProviderButtonComponent implements OnInit, OnChanges {

	@Output() public onLoginWithSso: EventEmitter<string> = new EventEmitter();
	@Input() public excludedProviders: AuthProvider[] = [];

	public preferredSsoProvider: OpenIdConfiguration = null;
	public providersForDropdown: OpenIdConfiguration[] = [];
	public loading: boolean = true;
	private networkProviderList: OpenIdConfiguration[] = [];

	constructor (
		private authService: NgxAuthService,
		private cd: ChangeDetectorRef
	) {}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.excludedProviders && !changes.excludedProviders.isFirstChange()) {
			this.setup(this.networkProviderList);
		}
	}

	public ngOnInit (): void {

		this.loading = true;
		this.authService.getSsoProviders().subscribe((providers: OpenIdConfiguration[]) => {
			this.networkProviderList = providers;
			this.setup(providers);
		});
	}

	public loginWithProvider (config: OpenIdConfiguration) {
		this.updatePreferredProvider(config);
		this.onLoginWithSso.emit(config.auth_provider.provider_name);
	}

	private setup (providers: OpenIdConfiguration[]): void {
		// Update preferred provider since local storage details could be out-dated
		// It also could be an excluded provider, so determine what it should really be here.
		const newPreferredProvider = this.findPreferredProvider(providers);
		this.loading = false;

		// If no options available, just return here
		// When this state occurs, our parent is going
		// to just ng-if us out of existence
		if (!newPreferredProvider ) {
			this.cd.detectChanges();
			return;
		}

		// Only update provider if we aren't excluding anything
		if (this.excludedProviders.length === 0) {
			this.updatePreferredProvider(newPreferredProvider);
		}

		this.preferredSsoProvider = newPreferredProvider;
		const invertedProvider = Object.assign(
			{}, this.preferredSsoProvider.auth_provider, { open_id_configuration: this.preferredSsoProvider }
		);
		const exclusions = [...this.excludedProviders, invertedProvider];
		this.providersForDropdown = this.removeExcludedProviders(providers, exclusions);
		this.cd.detectChanges();
	}

	private updatePreferredProvider (config: OpenIdConfiguration) {
		if (this.excludedProviders.length === 0) {
			localStorage.setItem('preferred-sso-provider', config.id.toString());
		}
	}

	private removeExcludedProviders (
		loadedProviders: OpenIdConfiguration[],
		providersToExclude: AuthProvider[]
	): OpenIdConfiguration[] {
		return loadedProviders.filter((config) => !this.shouldBeExcluded(config, providersToExclude));
	}

	private shouldBeExcluded (config: OpenIdConfiguration, providersToExclude: AuthProvider []): boolean {
		return providersToExclude.some((provider) => provider.open_id_configuration.id === config.id);
	}

	private findDefaultProvider (configs: OpenIdConfiguration[]): OpenIdConfiguration | null {
		return configs.find((config) => config.auth_provider.provider_name === DEFAULT_PROVIDER) ?? configs[0] ?? null;
	}

	private findOpenIdConfig (configs: OpenIdConfiguration[], configId?: number) {
		return configs.find((listConfig) => listConfig.id === configId);
	}

	private findPreferredProvider (
		configs: OpenIdConfiguration[]
	): OpenIdConfiguration {

		// If we aren't excluding anything, the correct provider is the original preferred or "google" or first
		// If we use preferred, update it from list
		// If we are excluding, start from a list that has less in it.
		configs = this.removeExcludedProviders(configs, this.excludedProviders);

		// Try to set preferred provider based on localStorage so long as it isn't excluded
		const rawProviderId = parseInt(localStorage.getItem('preferred-sso-provider'), 10);
		const storedProvider = this.findOpenIdConfig(configs, rawProviderId);
		this.preferredSsoProvider = storedProvider && !this.shouldBeExcluded(storedProvider, this.excludedProviders) ?
			this.findOpenIdConfig(configs, storedProvider.id) : null;

		return this.preferredSsoProvider ?? this.findDefaultProvider(configs);
	}
}
