<div class="feedback-creator" [formGroup]="form" [ngClass]="mode">
	<section [ngClass]="{'feedback-creator-error': characterMaxExceeded}"
		class="feedback-creator-body">
		<button
			type="button"
			class="close-btn"
			*ngIf="showCloseBtn()"
			attr.aria-label="{{'common_close'| translate}}"
			(click)="closeEditor()">
			<i class="ficon-app-x"></i>
		</button>
		<div class="comment-creator-header"
			*ngIf="(expandedView && mode === 'add') || tagsList.length > 0">
			<ng-container *ngIf="!activity.isConversationBoard()">
				<span
					*ngIf="expandedView && time !== null && mode === 'add'"
					class="timestamp">
					<span tabindex="0">{{time | formatTime}}</span>
					<button class="remove-timestamp"
						(click)="removeTimestamp()"
						attr.aria-label="{{'feedback-creator_timestamp-remove' | translate}}">
						<i class="ficon-app-x"></i>
					</button>
				</span>
				<button
					type="button"
					class="add-timestamp"
					*ngIf="expandedView && time === null && mode === 'add'"
					translate="feedback-creator_timestamp"
					attr.aria-label="{{'feedback-creator_timestamp-add' | translate}}"
					(click)="addTimestamp()">
				</button>
			</ng-container>
			<div class="tags-container"
				*ngIf="tagsList.length > 0"
				[ngClass]="mode"
				[class.no-timestamp]="!expandedView || mode !== 'add'">
				<ng-container *ngFor="let tag of tagsList">
					<marker
						[marker]="tag"
						[showButton]="true"
						[disabled]="isTagSelected(tag.tag_id)"
						(select)="selectTag(tag)">
					</marker>
				</ng-container>
			</div>
			<div *ngIf="tagsOverflown">
				<button
					*ngIf="tagsList.length > 0"
					mat-icon-button
					class="btn-none tags-menu-btn"
					attr.aria-label="{{'feedback-creator_open-tag-overflow' | translate}}"
					aria-haspopup="menu"
					[matMenuTriggerFor]="tagsMenu">
					<i class="ficon-app-chevron-down flipped"></i>
				</button>
				<mat-menu class="tag-menu" #tagsMenu="matMenu" yPosition="above">
					<ng-container *ngFor="let tag of tagsList">
						<button mat-menu-item class="tag-menu-item"
							(click)="selectTag(tag)"
							[disabled]="isTagSelected(tag.tag_id)">
							<marker
								[marker]="tag"
								[disabled]="isTagSelected(tag.tag_id)">
							</marker>
							<span translate="{{tag.tag_name}}"></span>
						</button>
					</ng-container>
				</mat-menu>
			</div>
		</div>
		<div class="resource-tags" *ngIf="tagResources.length">
			<marker
				*ngFor="let tag of tagResources"
				[marker]="tag.item"
				[withName]="true"
				[showRemove]="true"
				(remove)="removeTag(tag)">
			</marker>
		</div>
		<ngx-rich-text-editor
			*ngIf="expandedView"
			formControlName="commentText"
			[autoSize]="true"
			[autoFocus]="true"
			[toolbarBelow]="mediaResources.length ? 'extra' :'withBorder'"
			[toolbarOptions]="'bold underline bullist'"
			[toolbarGroups]="true"
			(enterEvent)="submitComment($event)"
			(maxCharactersEvent)="updateCharacterCount($event)"
		></ngx-rich-text-editor>
		<div class="resource-thumbnails" *ngIf="mediaResources.length">
			<ngx-media-thumbnail
				*ngFor="let resource of mediaResources"
				(removeEvent)="removeAttachment(resource)"
				[media]="resource.item"
			></ngx-media-thumbnail>
		</div>
		<div class="comment-creator-footer"
			[ngClass]="{
				'no-top-border': (expandedView || tagsList.length > 0),
				'expanded': expandedView,
				'compact': (mode === 'reply' || mode === 'edit')
			}">
			<span class="pull-left text-comment-container">
				<button
					data-cy="commentCreator-expand"
					*ngIf="!expandedView"
					type="button"
					(click)="expandCommentCreator(true)"
					translate="feedback-creator_add-comment"
					class="add-comment-btn">
				</button>
			</span>
			<span class="pull-right feedback-actions-container" *ngIf="!typesAreUpdating">
				<button
					class="btn-none emoji-btn recent-feedback-type-btn"
					[attr.aria-label]="'feedback-creator_open-emoji-picker' | translate"
					[matMenuTriggerFor]="emojiMenu"
					#emojiMenuTrigger="matMenuTrigger"
					(click)="focusSearchField()"
					[matTooltip]="'feedback-creator_open-emoji-picker' | translate">
					<mat-icon class="ficon-smile-o"></mat-icon>
				</button>
				<mat-menu class="emoji-menu" #emojiMenu="matMenu" yPosition="above">
					<div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
						<emoji-mart
							[darkMode]="false"
							[color]="'#00A39A'"
							[totalFrequentLines]="1"
							[isNative]="true"
							[showPreview]="false"
							[title]=""
							[autoFocus]="true"
							[useButton]="true"
							(emojiClick)="addEmoji($event)">
						</emoji-mart>
					</div>
				</mat-menu>
				<ng-container *ngFor="let recentFeedbackType of recentFeedbackTypes">
					<button
						class="btn-none recent-feedback-type-btn"
						[attr.aria-label]="recentFeedbackType.label | translate"
						[matTooltip]="recentFeedbackType.label | translate"
						(click)="selectFeedbackOption(recentFeedbackType.type)">
						<mat-icon class="{{recentFeedbackType.icon}}"></mat-icon>
					</button>
				</ng-container>
				<button
					mat-icon-button
					class="feedback-types-btn"
					[attr.aria-label]="'feedback-creator_open-feedback-options' | translate"
					[matMenuTriggerFor]="feedbackTypesMenu"
					[matTooltip]="'feedback-creator_open-feedback-options' | translate">
					<mat-icon class="ficon-add"></mat-icon>
				</button>
				<mat-menu #feedbackTypesMenu="matMenu" yPosition="above">
					<ng-container *ngFor="let feedbackType of feedbackTypes">
						<button mat-menu-item
							(click)="selectFeedbackOption(feedbackType.type)">
							<mat-icon class="{{feedbackType.icon}}"></mat-icon>
							<span translate="{{feedbackType.label}}"></span>
						</button>
					</ng-container>
				</mat-menu>
				<span *ngIf="saving$ | async" class="ficon-spinner ficon-spin"></span>
				<button
					*ngIf="!(saving$ | async)"
					data-cy="commentCreator-send"
					type="submit"
					(click)="submitComment()"
					[disabled]="!isUnsaved()"
					[attr.tabindex]="isUnsaved() ? 0 : -1"
					[ngClass]="{'light-up': isUnsaved()}"
					attr.aria-label="{{'feedback-creator_submit' | translate}}"
					class="btn-none submit-comment-btn">
					<i class="ficon-app-send"></i>
				</button>
			</span>
		</div>
	</section>
	<mat-error
		id="feedback-creator-error"
		class="character-count-error"
		role="alert"
		*ngIf="characterMaxExceeded">
		{{
			'feedback-creator_add-comment-error_exceeds_character_limit' | translate:{characterLimit, amountExceededBy}
		}}
	</mat-error>
</div>
