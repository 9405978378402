<mat-form-field goSelect>
	<mat-select
		[hidden]="rubrics.length <= 1"
		[(ngModel)]="selected"
		(selectionChange)="updated($event)"
		disableOptionCentering
		[disabled]="rubrics.length <= 1"
		panelClass="offsetPanel">
		<mat-select-trigger>
			<span>{{selected.user.last_name}}, {{selected.user.first_name}}</span>
		</mat-select-trigger>
		<mat-option *ngFor="let rubric of rubrics" [value]="rubric">
			<div class="rubric-filter-dropdown-option-template">
				<span>{{rubric.user.last_name}}, {{rubric.user.first_name}}</span>
				<span *ngIf="!rubric.isPublished()" translate="rubric-feedback-viewer_unposted-badge" class="rubric-feedback-viewer__dropdown-template--published goreact-badge"></span>
				<span *ngIf="!rubric.hideScore && rubric.points">{{rubric.points}} / {{rubric.total}}</span>
			</div>
		</mat-option>
	</mat-select>
	<div [hidden]="rubrics.length > 1">{{rubrics[0].user.last_name}}, {{rubrics[0].user.first_name}}</div>
</mat-form-field>
