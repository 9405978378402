<ng-container *ngIf="mode === modes.DISPLAY || mode === modes.FEEDBACK">
	<span
		*ngFor="let reaction of uniqueReactions"
		(click)="toggleReaction($event, reaction.emoji)"
		[goTooltip]="getTooltip(reaction)"
		goTooltipPlacement="top"
		[ngClass]="{'reacted': reaction.i_reacted, 'emoji-btn': mode === modes.FEEDBACK && reaction.count === 0}"
		class="grouped-emoji">
		<span class="emoji">{{reaction.emoji}}</span>
		<span *ngIf="reaction.count > 0" class="count">{{reaction.count}}</span>
	</span>
	<small *ngIf="mode === modes.FEEDBACK" class="ai-feedback" translate="feedback-panel-ai-feedback"></small>
</ng-container>
<button
	*ngIf="mode !== modes.FEEDBACK"
	class="btn-none emoji-btn"
	[attr.aria-label]="'feedback-panel-add-reaction' | translate"
	[matMenuTriggerFor]="emojiMenu"
	#emojiMenuTrigger="matMenuTrigger"
	(click)="focusSearchField($event)"
	[goTooltip]="'feedback-panel-add-reaction' | translate"
	[goTooltipDisabled]="mode === modes.ADD"
	goTooltipPlacement="top">
	<mat-icon class="ficon-smile-o"></mat-icon>
</button>
<mat-menu class="emoji-menu" #emojiMenu="matMenu" yPosition="above">
	<ng-template matMenuContent>
		<div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
			<emoji-mart
				[darkMode]="false"
				[color]="'#00A39A'"
				[totalFrequentLines]="1"
				[isNative]="true"
				[showPreview]="false"
				title=""
				emoji=""
				[autoFocus]="true"
				[useButton]="true"
				(emojiClick)="addReaction($event)">
			</emoji-mart>
		</div>
	</ng-template>
</mat-menu>
