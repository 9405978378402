<div *ngIf="account && (defaultActivity$$ | async) && activityForm" [formGroup]="activityForm" class="content">
	<mat-form-field appearance="outline" class="w-100">
		<mat-label translate="default-activity-settings_activity-type-select-label"></mat-label>
		<mat-select
			formControlName="template"
			(selectionChange)="onActivityValueChanged()"
			disableOptionCentering
			panelClass="offsetPanel">
			<mat-select-trigger>
				{{activityForm.controls.template.value.type}}
			</mat-select-trigger>

			<mat-option class="activity-settings-option" *ngFor="let template of activityTemplates"
				[value]="template"
			>
				<span class="option-title">{{template.type}}</span>
				<p>{{template.description}}</p>
			</mat-option>
		</mat-select>
		<mat-error
			*ngIf="activityForm.controls.template.hasError('required')"
			translate="common_required_field">
		</mat-error>
		<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
	</mat-form-field>

	<mat-form-field appearance="outline" class="w-100">
		<mat-label translate="default-activity-settings_privacy-type-select-label"></mat-label>
		<mat-select
			#privacySelect
			formControlName="privacyType"
			disableOptionCentering
			panelClass="offsetPanel">
			<mat-select-trigger [translate]="activityForm.controls.privacyType.value?.title">
			</mat-select-trigger>

			<mat-option class="activity-settings-option" *ngFor="let privacy of privacyTypes"
				[value]="privacy">
				<span class="option-title" [translate]="privacy.title"></span>
				<p [translate]="privacy.description"></p>
			</mat-option>
		</mat-select>
		<mat-error
				*ngIf="activityForm.controls.privacyType.hasError('required')"
				translate="common_required_field">
			</mat-error>
		<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
	</mat-form-field>

	<!-- Markers -->
	<section
		*ngIf="featureFlag.isAvailable('ACTIVITY_EDITOR_SHOW_DEFAULT_MARKER')"
		class="form-group markers-group">
		<section *ngIf="!selectedMarkerSet">
			<button
				type="button"
				aria-label="marker set add"
				class="link-btn add-btn markers-btn"
				translate="activity-editor-new_markers"
				(click)="openMarkerSelectionModal()">
			</button>
		</section>

		<marker-set-panel
			class="tagset-section"
			*ngIf="selectedMarkerSet"
			[markerSet]="selectedMarkerSet"
			[activity]="defaultActivity$$.getValue()"
			(onEdited)="handleMarkerSetEdited($event)"
			(onRemoved)="handleMarkerSetRemoved()">
		</marker-set-panel>
	</section>

	<mat-slide-toggle
		color="primary"
		formControlName="is_feedback_published">
		{{'activity-list_activity-options-menu-publish-feedback-and-grades' | translate}}
	</mat-slide-toggle>
</div>

<div class="content-footer" *ngIf="showSaveButton$ | async">
	<button	(click)="save()"
		[disabled]="this.isSaving$ | async"
		class="primary-btn submit-btn"
		type="submit"
		translate="common_save-changes">
	</button>
</div>
