<form
	(ngSubmit)="submit(form)"
	[formGroup]="form">

	<div class="ngx-dialog goreact-modal change-password-dialog-container">
		<div class="goreact-modal-header">
			<h4 class="modal-title"
					translate="{{ data.isUnlinking ? 'unlink-sso-dialog_title' : 'modal-change-password_change-password' }}"
					[translateParams]="{provider: data.authProvider?.open_id_configuration.display_name}">
				</h4>
			<button type="button" class="close-btn" (click)="close()" [attr.aria-label]="'common_close'| translate">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body">
				<p *ngIf="data.isUnlinking" translate="modal-change-password_unlink-sso" [translateParams]="{provider: data.authProvider?.open_id_configuration.display_name}"></p>
				<mat-form-field appearance="outline" *ngIf="shouldShowOldPasswordField()" class="old-password">
					<mat-label translate="modal-change-password_old-password"></mat-label>
					<input type="password" matInput formControlName="oldPassword" class="fullstory-ignore" />
					<mat-error *ngIf="form.controls.oldPassword.hasError('required')" translate="modal-change-password_error-enter-old-password"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label translate="modal-change-password_new-password"></mat-label>
					<input type="password" matInput formControlName="newPassword" class="fullstory-ignore" />
					<mat-icon
							matSuffix
							class="ficon-app-help-circle-outline"
							[goTooltip]="'sign-up-password-rules' | translate"
							goTooltipPlacement="top|right"
					></mat-icon>
					<mat-error *ngIf="form.controls.newPassword.invalid" translate="common_password_error-password-requirements"></mat-error>
				</mat-form-field>
		</div>
		<div class="goreact-modal-footer">
			<span class="error-text" *ngIf="error">{{ error }}</span>
			<button
				type="button"
				class="cancel-btn tertiary-btn"
				(click)="close()"
				[attr.aria-label]="'common_cancel'| translate">
				{{'common_cancel' | translate}}
			</button>
			<button
				type="submit"
				class="primary-btn submit-btn"
				[attr.aria-label]="'modal-change-password_change-password' | translate"
				[disabled]="(loading$ | async)">
				<i class="ficon-spinner ficon-spin" *ngIf="loading$ | async"></i>
				<span translate="{{ data.isUnlinking ? 'modal-change-password_unlink-and-save' : 'modal-change-password_change-password' }}"></span>
			</button>
		</div>
	</div>
</form>
