<div class="card-main">
	<ng-content select="[main]"></ng-content>
</div>
<div class="card-info">
	<ng-content select="[info-first-line]"></ng-content>
	<ng-content select="[info-second-line]"></ng-content>
	<div>
		<ng-content select="[info-third-line]"></ng-content>
		<ng-content select="[info-third-line-action-btns]"></ng-content>
	</div>
</div>
