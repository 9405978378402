
<input type="text"
*ngFor="let control of inputControls.controls; let i = index"
	[attr.aria-label]="'code-input_input-position' | translate: {current: 1 + i, total: inputControls.length}"
	required
	(click)="onClick()"
	(input)="valueChange($event, control)"
	(paste)="onPaste($event)"
	(keydown)="onKeyDown($event, control)"
	[formControl]="asFormControl(control)"
	[readonly]="disabled"
	type="number"
	pattern="\d*"
	[ngClass]="{readonly: disabled}"
	[attr.data-cy]="'email-verify-form-field-'+i"
/>
