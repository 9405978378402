import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	NgZone,
	OnInit,
	OnDestroy,
	Renderer2,
	EventEmitter,
	Output,
	ViewRef,
	OnChanges,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, delay, distinctUntilChanged, filter, finalize, interval, noop, Observable, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { commentModelToken, CommentModel } from 'go-modules/models/comment/comment.factory';
import { userServiceToken } from 'go-modules/models/user/user.service';
import type { UserService } from 'go-modules/models/user/user.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { goModalToken, goModal } from 'go-modules/modals/go-modal.factory';
import { umcChooserToken, UniversalMediaChooserFactory } from 'go-modules/universal-media-chooser/umc.factory';
import { CONTENT_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { COMMENT_RESOURCE_TYPES } from 'go-modules/models/comment/comment.constant';
import { ConfirmDialogComponent } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { NgxCommentService } from 'ngx/go-modules/src/services/comment/comment.service';
import * as dayjs from 'dayjs';
import { NgxUnsavedDataManagerService } from 'ngx/go-modules/src/services/unsaved-data-manager/unsaved-data-manager.service';
import type { UnsavedDataRegistration } from 'ngx/go-modules/src/services/unsaved-data-manager/unsaved-data-manager.service';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { NgxRichTextEditorComponent } from 'ngx/go-modules/src/components/rich-text-editor/rich-text-editor.component';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import { selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
export const RECENT_FEEDBACK_TYPES_STORAGE_KEY = 'recent-feedback-types';

@Component({
	selector: 'ngx-feedback-creator',
	templateUrl: './feedback-creator.component.html',
	styleUrls: ['./feedback-creator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class NgxFeedbackCreatorComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public session: any;
	@Input() public comments: any;
	@Input() public tagsList: any;
	@Input() public playerSync: any;
	@Input() public userGroup: any;
	@Input() public oldComment: any;
	@Input() public oldResources: any;
	@Input() public mode: any;
	@Input() public viewFrom: string;
	@Input() public oneClickMarker: boolean;
	@Output() public onUpdate: EventEmitter<any> = new EventEmitter();
	@Output() public onClose: EventEmitter<any> = new EventEmitter();
	@ViewChild(NgxRichTextEditorComponent, {static: false})
	public richTextEditor: NgxRichTextEditorComponent;
	@ViewChild(MatMenuTrigger) private emojiMenuTrigger: MatMenuTrigger;
	public umc: any;
	public resources: any[] = [];
	public tagResources: any[] = [];
	public mediaResources: any[] = [];
	public recentFeedbackTypes: any[];
	public numberOfRecentFeedbackTypes = 2;
	public pendingRecentFeedbackTypes: any[] = [ ];
	public typesAreUpdating: boolean = false;
	public feedbackTypesEnum = {
		VIDEO: 'video',
		UPLOAD: 'upload',
		LIBRARY: 'library',
		AUDIO: 'audio',
		YOUTUBE: 'youtube',
		ZOOM: 'zoom'
	};
	public feedbackTypes = [
		{
			type: this.feedbackTypesEnum.VIDEO,
			icon: 'ficon-app-camcorder',
			label: 'universal-media-chooser_record'
		},
		{
			type: this.feedbackTypesEnum.UPLOAD,
			icon: 'ficon-upload-outlined',
			label: 'universal-media-chooser_upload'
		},
		{
			type: this.feedbackTypesEnum.LIBRARY,
			icon: 'ficon-app-library',
			label: 'universal-media-chooser_library'
		},
		{
			type: this.feedbackTypesEnum.AUDIO,
			icon: 'ficon-microphone-outlined',
			label: 'universal-media-chooser_audio'
		},
		{
			type: this.feedbackTypesEnum.YOUTUBE,
			icon: 'ficon-youtube-icon',
			label: 'universal-media-chooser_youtube'
		},
		{
			type: this.feedbackTypesEnum.ZOOM,
			icon: 'ficon-zoom-icon',
			label: 'universal-media-chooser_zoom'
		}
	];
	public umcHeaderOptions = [
		this.UniversalMediaChooser.MEDIA_TYPE.RECORD_VIDEO,
		this.UniversalMediaChooser.MEDIA_TYPE.UPLOAD,
		this.UniversalMediaChooser.MEDIA_TYPE.LIBRARY,
		this.UniversalMediaChooser.MEDIA_TYPE.RECORD_AUDIO,
		this.UniversalMediaChooser.MEDIA_TYPE.YOUTUBE,
		this.UniversalMediaChooser.MEDIA_TYPE.ZOOM
	];
	public expandedView: boolean = false;
	public characterLimit: number = clientSettings.CommentTextCharacterLimit;
	public characterMaxExceeded: boolean = false;
	public amountExceededBy: number = 0;
	public form = new FormGroup({
		commentText: new FormControl('', [Validators.required])
	});
	public time: number = 0;

	public tagsOverflown: boolean = false;
	public saving$: Observable<boolean> = of(false);
	public addEndnotes: boolean = false;
	public pausedInitially: boolean = false;
	public activity;

	public unsavedDataRegistration: UnsavedDataRegistration = {
		isInUnsavedState: () => this.isUnsaved(),
		resolve: () => {
			const data = {
				title: this.translationPipe.transform('feedback-session-feedback-creator_comment_warn-on-exit-title'),
				message: this.translationPipe.transform('feedback-session-feedback-creator_comment_warn-on-exit-message'),
				confirmText: this.translationPipe.transform('feedback-session-feedback-creator_comment_warn-on-exit-resolve-btn'),
				cancelText: this.translationPipe.transform('feedback-session-feedback-creator_comment_warn-on-exit-reject-btn')
			};
			return new Promise((resolve, reject) => {
				this.ngZone.run(() => {
					const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
					dialogRef.afterClosed().subscribe((confirm) => {
						if (confirm) {
							return reject();
						}
						return resolve();
					});
				});
			});
		}
	};
	private resizeObserver: ResizeObserver;
	private globalEventsListener: Subscription;
	private componentDestroyed: Subject<boolean> = new Subject();

	constructor (
		@Inject(userServiceToken) private userService: UserService,
		@Inject(commentModelToken) private Comment: ReturnType<typeof CommentModel>,
		@Inject(goModalToken) private goModalService: ReturnType<typeof goModal>,
		@Inject(activityToken) private activityFactory,
		@Inject(selectedServiceToken) private selectedService,
		@Inject(umcChooserToken) private UniversalMediaChooser: ReturnType<typeof UniversalMediaChooserFactory>,
		private cdr: ChangeDetectorRef,
		private commentService: NgxCommentService,
		private elementRef: ElementRef,
		private renderer2: Renderer2,
		public unsavedDataManagerService: NgxUnsavedDataManagerService,
		private dialog: MatDialog,
		private ngZone: NgZone,
		private translationPipe: TranslatePipe,
		private ngxGoToastService: NgxGoToastService,
		private authService: NgxAuthService,
		private eventService: EventService,
		private featureFlag: NgxFeatureFlagService
	) {}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload ($event) {
		this.unsavedDataManagerService.checkUnload($event);
	}

	public ngOnInit (): void {
		this.umc = this.UniversalMediaChooser.get('umcModal');
		this.unsavedDataManagerService.register(this.unsavedDataRegistration);
		this.activity = this.activityFactory.model(this.session.activity);
		if (this.shouldHideLibraryFromFeedback()) {
			this.umcHeaderOptions.splice(
				this.umcHeaderOptions.indexOf(this.UniversalMediaChooser.MEDIA_TYPE.LIBRARY), 1);
			this.feedbackTypes = this.feedbackTypes.filter((feedbackType) => {
				return feedbackType.type !== this.feedbackTypesEnum.LIBRARY;
			});
		}

		// Set initial recent feedback types
		let sessionFeedbackTypes = JSON.parse(localStorage.getItem(RECENT_FEEDBACK_TYPES_STORAGE_KEY));
		if(sessionFeedbackTypes) {
			if (this.shouldHideLibraryFromFeedback()) {
				sessionFeedbackTypes = sessionFeedbackTypes.filter((feedbackType) => {
					return feedbackType.type !== this.feedbackTypesEnum.LIBRARY;
				});

				if (sessionFeedbackTypes.length < this.numberOfRecentFeedbackTypes) {
					const itemsNotOnRecentFeedbackList = this.feedbackTypes.filter((feedbackType) =>
						!sessionFeedbackTypes.some((_feedbackType) => feedbackType.type === _feedbackType.type)
					);
					sessionFeedbackTypes = sessionFeedbackTypes.concat(itemsNotOnRecentFeedbackList);
				}
			}
			this.recentFeedbackTypes = JSON.parse(JSON.stringify(sessionFeedbackTypes))
				.slice(0, this.numberOfRecentFeedbackTypes);
			this.cdr.detectChanges();
		} else {
			this.recentFeedbackTypes = JSON.parse(JSON.stringify(this.feedbackTypes))
				.slice(0, this.numberOfRecentFeedbackTypes);
			this.cdr.detectChanges();
			localStorage.setItem(
				RECENT_FEEDBACK_TYPES_STORAGE_KEY,
				JSON.stringify(this.recentFeedbackTypes)
			);
		}


		// Handle when editing an existing comment
		if (this.mode !== 'add') {
			this.form.controls.commentText.setValue(this.oldComment.text);
			if(this.oldComment.resource) {
				this.oldComment.resource.forEach((res) => {
					this.resources.push(res);
					if (res.resource_type === COMMENT_RESOURCE_TYPES.TAG) {
						this.tagResources.push(res);
					} else if (res.resource_type === COMMENT_RESOURCE_TYPES.MEDIA) {
						this.mediaResources.push(res);
					}
				});
			}
			this.expandCommentCreator();
		} else {
			this.form.get('commentText')
				.valueChanges
				.pipe(
					distinctUntilChanged(),
					filter((value) => value?.length)
				)
				.subscribe(() => {
					if (!this.pausedInitially) {
						this.pausedInitially = true;
						this.playerSync.halt();
						if (!this.addEndnotes && !this.time) {
							this.time = this.playerSync.getTime();
							this.cdr.detectChanges();
						}
					}
				});
		}

		this.observeSize();
	}

	public ngOnChanges (changes: SimpleChanges): void {
		// If there are changes in the resources
		const oldResources = changes.oldResources;
		if (oldResources) {
			if(this.mode === 'edit' && oldResources.currentValue !== oldResources.previousValue) {
				this.resources = changes.oldResources.currentValue ? changes.oldResources.currentValue : [];
			}
		}
		if (changes.tagsList) {
			setTimeout(() => {
				this.checkTagsOverflow();
			});
		}
	}

	public ngOnDestroy (): void {
		this.resizeObserver.disconnect();
		this.unsavedDataManagerService.unregister(this.unsavedDataRegistration);
		this.componentDestroyed.next(true);
		this.componentDestroyed.complete();
	}

	public expandCommentCreator (resetAddEndNote = false) {
		if(!this.expandedView) {
			this.expandedView = true;

			if(resetAddEndNote) {
				this.addEndnotes = false;
			}

			this.cdr.detectChanges();
			this.listenGlobalEvents();
		}
	}

	public collapseCommentCreator () {
		if(this.expandedView) {
			this.expandedView = false;
			this.pausedInitially = false;
			this.playerSync.resume();
			if(!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges();
			}
			this.globalEventsListener.unsubscribe();
		}
	}

	public removeTimestamp () {
		const previousTime = this.time;
		this.time = null;
		this.addEndnotes = true;
		this.ngxGoToastService.createToast({
			type: GoToastStatusType.WARNING,
			message: 'feedback-creator_timestamp-removed',
			undo: () => this.addTimestamp(previousTime)
		});
	}

	public addTimestamp (time?: number) {
		this.time = time ?? this.playerSync.getTime();
		this.addEndnotes = false;
		this.cdr.detectChanges();
	}

	public closeEditor () {
		if (this.isUnsaved()) {
			const data = {
				title: this.translationPipe.transform('common_discard-changes') + '?',
				message: this.translationPipe.transform('feedback-creator_discard-message'),
				confirmText: this.translationPipe.transform('common_discard-changes'),
				cancelText: this.translationPipe.transform('common_cancel')
			};
			const dialogRef = this.dialog.open(ConfirmDialogComponent, { data });
			dialogRef.afterClosed().subscribe((confirm) => {
				if (confirm) {
					this.onClose.emit();
				}
			});
		} else {
			this.onClose.emit();
		}
	}

	public focusSearchField () {
		interval(100)
			.pipe(
				take(1),
				delay(1)
			).subscribe(() => {
				const searchField: HTMLElement = document.getElementById('emoji-mart-search-1');
				searchField.focus();
			});
	}

	public addEmoji (event) {
		this.form.controls.commentText.setValue(this.form.controls.commentText.value + event.emoji.native);
		this.emojiMenuTrigger.closeMenu();
		if (this.expandedView) {
			this.richTextEditor.focusOnField();
		} else {
			this.expandCommentCreator();
		}
	}

	public selectFeedbackOption (mediaType: string) {
		this.time = this.playerSync.getTime();
		let appendTo = null;
		let headerOptions = this.umcHeaderOptions;
		if (this.featureFlag.isAvailable('MINI_UMC')) {
			if (mediaType === this.feedbackTypesEnum.VIDEO) {
				headerOptions = [
					this.UniversalMediaChooser.MEDIA_TYPE.RECORD_VIDEO
				];
				appendTo = 'ngx-feedback-creator';
			} else if (mediaType === this.feedbackTypesEnum.AUDIO) {
				headerOptions = [
					this.UniversalMediaChooser.MEDIA_TYPE.RECORD_AUDIO
				];
				appendTo = 'ngx-feedback-creator';
			}
		}
		this.goModalService.open({
			modal: 'umc',
			modalData: {
				options: {
					appendTo,
					defaultSection: this.getDefaultSection(mediaType),
					headerOptions,
					chooseAutomatically: true,
					useMenu: false,
					resourceType: 'comment',
					resourceId: this.session.session_id,
					groupId: this.session.group_id,
					blacklist: 'fine-uploader/src/partials/previewable-only-whitelist.json',
					goRecorder: {
						warnOnExit: true,
						videoQuality: this.selectedService.getLicense()?.salesforce_license.video_quality ??
						VideoQualityUtil.MINIMUM_RESOLUTION
					},
					libraryOptions: {
						filterType: [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS],
						previewable: true,
						collectionManagement: false
					}
				}
			},
			animation: false
		}).result
			.then((mediaComment) => {
				const mediaExists = this.resources.some((res) => {
					return res.resource_id === mediaComment.media_id &&
						res.resource_type === COMMENT_RESOURCE_TYPES.MEDIA;
				});
				if (mediaExists) {
					const data = {
						title: this.translationPipe.transform('feedback-creator_media-in-use-title'),
						message: this.translationPipe.transform('feedback-creator_media-in-use-message'),
						confirmText: this.translationPipe.transform('common_ok')
					};
					this.dialog.open(MessageDialogComponent, { data });
					return;
				}
				this.resources.push({
					resource_type: COMMENT_RESOURCE_TYPES.MEDIA,
					resource_id: mediaComment.media_id,
					item: mediaComment
				});
				this.mediaResources = this.resources.filter((res) => {
					return res.resource_type === COMMENT_RESOURCE_TYPES.MEDIA;
				});
				this.cdr.detectChanges();
				this.updateRecentFeedbackTypes();
				if (this.expandedView) {
					this.richTextEditor.focusOnField();
				} else {
					this.expandCommentCreator();
				}
			})
			.catch(noop);
	}

	public removeAttachment (resource) {
		const index = this.resources.findIndex((res) => {
			return res === resource;
		});
		this.resources.splice(index, 1);
		this.mediaResources = this.resources.filter((res) => {
			return res.resource_type === COMMENT_RESOURCE_TYPES.MEDIA;
		});
		this.cdr.detectChanges();
	}

	public showCloseBtn () {
		return this.mode === 'reply' || this.mode === 'edit';
	}

	public selectTag (tag: any) {
		if (!this.expandedView) {
			this.time = this.playerSync.getTime();
			if (!this.oneClickMarker) {
				this.playerSync.halt();
			}
		}

		if (!this.time && !this.addEndnotes) {
			this.time = this.playerSync.getTime();
			this.richTextEditor.focusOnField();
			if (!this.oneClickMarker) {
				this.playerSync.halt();
			}
		}

		if (tag.tag_preset_comment) {
			if (this.form.controls.commentText.value) {
				this.form.controls.commentText.setValue(this.form.controls.commentText.value + '<br />' + tag.tag_preset_comment);
			} else {
				this.form.controls.commentText.setValue(tag.tag_preset_comment);
			}
		}

		const comment = {
			resource_type: COMMENT_RESOURCE_TYPES.TAG,
			resource_id: tag.tag_id,
			item: tag
		};

		const tagExist = this.resources.some((elem) => elem.resource_id === comment.resource_id);
		if (tagExist) {
			return;
		} else {
			this.resources.push(comment);
		}

		if (this.oneClickMarker) {
			this.submitComment();
		} else {
			this.tagResources = this.resources.filter((res) => {
				return res.resource_type === COMMENT_RESOURCE_TYPES.TAG;
			});
			this.expandCommentCreator();
		}
	}

	public removeTag (tag: any) {
		const index = this.resources.findIndex((res) => {
			return res.item.tag_id === tag.item.tag_id;
		});
		this.resources.splice(index, 1);
		this.tagResources = this.resources.filter((res) => {
			return res.resource_type === COMMENT_RESOURCE_TYPES.TAG;
		});
		this.cdr.detectChanges();
	}

	public isTagSelected (tagId: any) {
		return this.tagResources.some((cTag) => cTag.item.tag_id === tagId);
	}

	public updateCharacterCount (characterData) {
		this.characterMaxExceeded = characterData.maxExceeded;
		this.amountExceededBy = characterData.amountExceededBy;
	}

	public submitComment (eventData?: any) {
		eventData?.event.preventDefault();

		if (this.characterMaxExceeded) {
			return;
		}

		if (!this.isUnsaved()) {
			return;
		}

		let currentComment = this.oldComment;

		if (this.mode === 'add') {
			currentComment = this.Comment.newInstance({
				text: this.form.controls.commentText.value,
				time: this.time,
				fullname: this.userService.currentUser.fullname,
				created_at: dayjs(),
				created_by: this.userService.currentUser.user_id,
				session_id: this.session.session_id,
				viewed_by_me: true,
				directed_at_me: false,
				numChildren: 0,
				first_name: this.userService.currentUser.first_name,
				last_name: this.userService.currentUser.last_name,
				saving: true,
				resource: this.resources.length ? this.resources : null
			});
			this.form.controls.commentText.reset('');
			this.playerSync.resume();
			this.time = null;
			this.comments.push(currentComment);
			this.cdr.detectChanges();
		} else {
			// Unnecessary property carried from using oldComment model, causes circular error
			if (currentComment.parent) {
				delete currentComment.parent;
			}
			// prevent circular error when saving parent comment with children
			if (currentComment.children) {
				delete currentComment.children;
			}

			currentComment.resource = this.resources.length ? this.resources : null;
			currentComment.text = this.form.controls.commentText.value;
			this.collapseCommentCreator();
			this.saving$ = of(true);
		}
		this.cdr.detectChanges();
		if (this.mode !== 'edit') {
			this.onUpdate.emit();
		}

		this.commentService.save(this.session.session_id, currentComment)
			.pipe(take(1))
			.pipe(takeUntil(this.componentDestroyed))
			.pipe(catchError(() => {
				this.saving$ = of(false);
				this.form.controls.commentText.setValue(currentComment.text);
				this.expandCommentCreator();
				this.time = currentComment.time;
				this.cdr.detectChanges();
				// remove comment from list
				if (this.mode !== 'edit') {
					const index = this.comments.findIndex((comm) => !comm.comment_id);
					if (index >= 0) {
						this.comments.splice(index, 1);
					} else {
						const parent = this.comments.find((comm) => {
							return parseInt(comm.comment_id, 10) === parseInt(currentComment.parent_id, 10);
						});
						const childIndex = parent.children.findIndex((comm) => !comm.comment_id);
						if (childIndex >= 0) {
							parent.children.splice(childIndex, 1);
						}
					}
				}

				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'feedback-creator_add-comment_failed'
				});
				return of(null);
			}))
			.subscribe((savedComment) => {
				this.saving$ = of(false);
				this.pausedInitially = false;
				if (savedComment) {
					// replace comment with actual comment now
					const index = this.comments.findIndex((comm) => {
						if (currentComment.comment_id) return comm.comment_id === currentComment.comment_id;
						return !comm.comment_id;
					});
					if (index >= 0) {
						this.comments.splice(index, 1);
						this.comments.push(this.Comment.newInstance(savedComment));
					} else {
						const parent = this.comments.find((comm) => {
							return parseInt(comm.comment_id, 10) === parseInt(currentComment.parent_id, 10);
						});
						const childIndex = parent.children.findIndex((comm) => {
							if (currentComment.comment_id) return comm.comment_id === currentComment.comment_id;
							return !comm.comment_id;
						});
						parent.children.splice(childIndex, 1);
						parent.children.push(this.Comment.newInstance(savedComment));
					}
					this.resources = [];
					this.mediaResources = [];
					this.tagResources = [];
					this.saveRecentFeedbackTypes();
					const comment = this.Comment.newInstance(savedComment);
					if (this.mode === 'add') {
						comment.saving = true;
					}
					this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED, comment);
					this.cdr.detectChanges();
					this.onUpdate.emit();
					this.onClose.emit();
					if (this.mode === 'add' && this.expandedView) {
						this.richTextEditor.focusOnField();
					}
				}
			});
	}

	public isUnsaved () {
		if (this.oldComment) {
			// if comment text isnt the same
			if (this.form.controls.commentText.value !== this.oldComment.text) {
				return true;
			}
			if (this.oldComment.resource) {
				// find values in old comment resources not in current resources
				const differences = this.oldComment.resource.filter((res) => {
					return !this.resources.some((res2) => {
						return res.resource_id === res2.resource_id &&
							res.resource_type === res2.resource_type;
					});
				});
				// find values in current resources not in old comment resources
				const differences2 = this.resources.filter((res) => {
					return !this.oldComment.resource.some((res2) => {
						return res.resource_id === res2.resource_id &&
							res.resource_type === res2.resource_type;
					});
				});
				//combine them
				const combinedDifferences = differences.concat(differences2);
				return combinedDifferences.length > 0;
			}
			return this.resources.length > 0;
		} else {
			// if comment text or resources length
			return this.form.controls.commentText.value?.length > 0 ||
				this.resources.length > 0;
		}
	}

	private getDefaultSection (mediaType: string) {
		let defaultSelection;
		switch (mediaType) {
			case this.feedbackTypesEnum.VIDEO:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.RECORD_VIDEO;
				break;
			case this.feedbackTypesEnum.UPLOAD:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.UPLOAD;
				break;
			case this.feedbackTypesEnum.LIBRARY:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.LIBRARY;
				break;
			case this.feedbackTypesEnum.AUDIO:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.RECORD_AUDIO;
				break;
			case this.feedbackTypesEnum.YOUTUBE:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.YOUTUBE;
				break;
			case this.feedbackTypesEnum.ZOOM:
				defaultSelection = this.UniversalMediaChooser.MEDIA_TYPE.ZOOM;
				break;
		}
		return defaultSelection;
	}

	private listenGlobalEvents () {
		const emitter = new Subject();

		const closeOnEscapeKeyHandler = ($event: KeyboardEvent) => {
			if($event.key === 'Escape' && !this.form.controls.commentText.value
				&& this.resources.length === 0 && this.mode !== 'edit' && this.mode !== 'reply') {
				this.collapseCommentCreator();
			}
		};

		const closeOnClickOutsideHandler = ($event: PointerEvent) => {
			const inOrTheTriggerElement = this.elementRef.nativeElement.contains($event.target as Element) ||
				this.elementRef.nativeElement === $event.target;

			const element = $event.target as HTMLElement;
			const isToastElement = element.closest('.go-react-toast');
			const isTagMenuElement = element.closest('.tag-menu');
			const isUmc = element.closest('.universal-media-chooser-modal');
			const isEmojiPicker = element.closest('.emoji-menu');

			if(!inOrTheTriggerElement && !this.form.controls.commentText.value
				&& !isToastElement && !isTagMenuElement && !isUmc && this.resources.length === 0
				&& this.mode !== 'edit' && this.mode !== 'reply' &&
				!isEmojiPicker) {
				this.collapseCommentCreator();
			}
		};

		const keydownEventDestroyFn = this.renderer2.listen(window, 'keydown', closeOnEscapeKeyHandler);
		const mousedownEventDestroyFn = this.renderer2.listen(window, 'mousedown', closeOnClickOutsideHandler);

		this.globalEventsListener = emitter.asObservable().pipe(
			finalize(() => {
				emitter.complete();
				keydownEventDestroyFn();
				mousedownEventDestroyFn();
			})
		).subscribe();
	}

	/*
		Remove library when user does not have instructor roles or above
		or when app is viewed from video-sharing
	*/
	private shouldHideLibraryFromFeedback () {
		return (!this.userGroup.hasInstructorRole(true) && !this.authService.isAdmin()) || this.viewFrom === 'video-sharing';
	}

	private updateRecentFeedbackTypes () {
		let feedbackType;
		if (this.umc.currentSection.name === this.UniversalMediaChooser.MEDIA_TYPE.RECORD_VIDEO.name) {
			feedbackType = this.feedbackTypesEnum.VIDEO;
		} else {
			feedbackType = this.umc.currentSection.name.replace('universal-media-chooser_','');
		}

		if (!this.pendingRecentFeedbackTypes.length) {
			this.pendingRecentFeedbackTypes = [...this.recentFeedbackTypes];
		}

		const typeIndex = this.feedbackTypes.findIndex(
			(type) => type.type === feedbackType
		);
		const recentTypeIndex = this.pendingRecentFeedbackTypes.findIndex(
			(type) => type.type === feedbackType
		);

		if(recentTypeIndex < 0) {
			this.pendingRecentFeedbackTypes.unshift(this.feedbackTypes[typeIndex]);
			this.pendingRecentFeedbackTypes.pop();
		} else {
			this.pendingRecentFeedbackTypes.splice(recentTypeIndex, 1);
			this.pendingRecentFeedbackTypes.unshift(this.feedbackTypes[typeIndex]);
		}
	}

	private saveRecentFeedbackTypes () {
		if (this.pendingRecentFeedbackTypes.length) {
			this.typesAreUpdating = true;
			this.cdr.detectChanges();

			this.recentFeedbackTypes = [...this.pendingRecentFeedbackTypes];
			this.typesAreUpdating = false;
			this.cdr.detectChanges();

			localStorage.setItem(
				RECENT_FEEDBACK_TYPES_STORAGE_KEY,
				JSON.stringify(this.recentFeedbackTypes)
			);
		}
	}

	// Create marker list observer to check for overflow
	private checkTagsOverflow () {
		let hasOverflow = false;
		const tagsContainer: HTMLElement = this.elementRef.nativeElement.querySelector('.tags-container');
		if (tagsContainer) {
			tagsContainer.querySelectorAll('button').forEach((tag) => {
				if (tag.offsetTop > (tagsContainer.offsetTop + 5)) {
					tag.style.visibility = 'hidden';
					hasOverflow = true;
				} else {
					tag.style.visibility = 'visible';
				}
			});
		}
		this.tagsOverflown = hasOverflow;
		this.cdr.detectChanges();
	}

	private observeSize () {
		const sizeChange = (entries) => {
			const footer = this.elementRef.nativeElement.querySelector('.comment-creator-footer');
			for (const css of footer.classList.values()) {
				if (css.startsWith('resize-sensor-')) {
					footer.classList.remove(css);
				}
			}

			[350, 320].forEach((width) => {
				if (entries[0].contentRect.width <= width) {
					footer.classList.add(`resize-sensor-${width}`);
				}
			});

			this.checkTagsOverflow();
		};

		const feedbackPanel = this.elementRef.nativeElement.querySelector('.feedback-creator-body');
		this.resizeObserver = new ResizeObserver(sizeChange);
		this.resizeObserver.observe(feedbackPanel);
	}
}
