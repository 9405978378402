import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@video-sharing/app/common/auth/auth.guard';
import { SessionURLGuard } from './common/guards/session-url/session-url.guard';

const routes: Routes = [
	{
		path: 'sessions/:uuid',
		loadChildren: () => import('@video-sharing/app/feedback-session/feedback-session.module')
			.then((m) => m.FeedbackSessionModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('./login-wrapper/login-wrapper.module').then((m) => m.LoginWrapperModule),
		canActivate: [SessionURLGuard]
	},
	{ path: 'blocked', loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule) },
	{
		path: 'sign-up',
		loadChildren: () => import('@video-sharing/app/signup/signup.module').then(m => m.SignupModule),
		canActivate: [SessionURLGuard]
	},
	{ path: 'forgot', loadChildren: () => import('@video-sharing/app/forgot-password-wrapper/forgot-password-wrapper.module').then(m => m.ForgotPasswordWrapperModule) },
	{ path: 'reset', loadChildren: () => import('@video-sharing/app/reset-password-wrapper/reset-password-wrapper.module').then(m => m.ResetPasswordWrapperModule) },
	{ path: '**', redirectTo: 'blocked' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
