import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { responsiveViewToken, Sizes, ResponsiveViewService } from 'go-modules/responsive-view/responsive-view.service';
import { zendeskToken, ZendeskService } from 'go-modules/services/zendesk/zendesk.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { AuthService } from '../../services/auth/auth.service';
import { EditUserDetailsDialogComponent } from 'ngx/go-modules/src/components/dialogs/edit-user-details-dialog/edit-user-details-dialog.component';
import { UserService } from '@video-sharing/app/common/services/user/user.service';
import { NgxSuggestionPanelComponent } from 'ngx/go-modules/src/components/sidepanels/suggestion-panel/suggestion-panel.component';
import { GoSidepanelService } from 'ngx/go-modules/src/services/go-sidepanel/go-sidepanel.service';
import { environment } from '@video-sharing/environments/environment';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { LinkResult } from 'ngx/go-modules/src/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-header-toolbar',
	styleUrls: ['./header-toolbar.style.scss'],
	templateUrl: './header-toolbar.component.html'
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {

	public unsubscribe;
	public user;
	public baseUrl: string;
	public userLoadedFromUserService = false;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService,
		private modal: GoModalService,
		public cd: ChangeDetectorRef,
		public ngxGoSidepanelService: GoSidepanelService,
		private ngxGoToastService: NgxGoToastService,
		private translateService: TranslateService,
		@Inject(responsiveViewToken) private responsiveView: ResponsiveViewService,
		@Inject(zendeskToken) private zendeskService: ZendeskService,
		@Inject('Window') private window: Window,
	) {}

	public ngOnInit () {
		this.user = this.authService.getUser();
		this.baseUrl = environment.goreactUrl;
		this.userService.getSelf()
			.subscribe((user) => {
				this.user = user;
				this.userLoadedFromUserService = true;
				const userDetailsParam = this.route.snapshot.queryParamMap.get('showUserDetails');
				const provider = this.route.snapshot.queryParamMap.get('provider');
				if (userDetailsParam) {
					switch (userDetailsParam) {
						case LinkResult.SUCCESS:
							this.translateService.get('common_sso-account-successfully-linked', { provider }).subscribe((message) => {
								this.ngxGoToastService.createToast({
									type: GoToastStatusType.SUCCESS,
									message
								});
							});
							break;
						case LinkResult.ACCOUNT_TAKEN:
							this.translateService.get('common_sso-account-linked-account-taken', { provider }).subscribe((message) => {
								this.ngxGoToastService.createToast({
									type: GoToastStatusType.ERROR,
									message
								});
							});
							break;
						// No default needed since param supplied with no state value
					}

					this.launchProfile();
					this.router.navigate([], {
						queryParams: { showUserDetails: null, provider: null },
						queryParamsHandling: 'merge'
					});
				}
				this.cd.detectChanges();
			});

		this.zendeskService.updateZendeskWidget();
		this.unsubscribe = this.responsiveView.subscribe([Sizes.SMALL], () => {
			this.zendeskService.updateZendeskWidget();
			this.cd.detectChanges();
		});
	}

	public logout () {
		this.authService.logout().subscribe(() => {
			this.router.navigate(['/login'], { queryParams: { session: this.route.firstChild.snapshot.paramMap.get('uuid') }});
		});
	}

	public navigate (url: string): void {
		this.window.open(url, '_blank');
	}

	public openSuggestionPanel () {
		this.ngxGoSidepanelService.open(NgxSuggestionPanelComponent, {
			panelClass: ['suggestion-panel']
		});
	}

	public launchProfile () {
		// In the edge case of a user not being fully loaded yet, don't open this modal
		if (!this.userLoadedFromUserService) {
			return;
		}

		this.modal.open(EditUserDetailsDialogComponent, false, {
			data: {
				sessionId: this.route.firstChild.snapshot.paramMap.get('uuid')
			}
		}).afterClosed().subscribe(() => {
			this.userService.getSelf()
				.subscribe((user) => {
					this.user = user;
					this.cd.detectChanges();
				});
		});
	}

	public ngOnDestroy() {
		this.unsubscribe();
	}

	public activateZendeskWidget () {
		this.window.zE.activate();
	}

	public shouldShowDemoButtons () :boolean {
		return this.authService.getCookie('is_self_demo_experiment') !== null;
	}

	public shouldShowDropdown () {
		return this.user !== null && this.authService.getCookie('is_self_demo_experiment') === null;
	}
}
