import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import { of, Subject } from 'rxjs';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'email-verification-dialog',
	templateUrl: './email-verification-dialog.component.html',
	styleUrls: ['./email-verification-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationDialogComponent implements OnInit, OnDestroy {
	public componentDestroyed = new Subject();
	public loading = true;
	public error;

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: { email: string },
		private cd: ChangeDetectorRef,
		private signupService: SignUpService
	) {
	}

	public ngOnInit (): void {
		this.sendVerification();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed.next(true);
		this.componentDestroyed.complete();
	}

	public sendVerification () {
		this.error = null;
		this.updateLoadingState(true);

		this.signupService
			.sendVerificationEmail(this.data.email)
			.pipe(take(1))
			.pipe(takeUntil(this.componentDestroyed))
			.pipe(catchError(() => {
				this.error = true;
				return of(null);
			}))
			.pipe(finalize(() => this.updateLoadingState(false)))
			.subscribe();
	}

	/**
	 * Close the dialog
	 */
	public close (value = null) {
		this.dialogRef.close(value);
	}


	private updateLoadingState (value) {
		this.loading = value;
		this.cd.detectChanges();
	}
}
