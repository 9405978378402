<div class="header">
	<h3 translate="license-renewal-snackbar_title-and-button-text"></h3>
	<button
		class="close-btn"
		type="button"
		(click)="close()"
		[attr.aria-label]="'common_close' | translate">
		<i class="ficon-app-x"></i>
	</button>
</div>
<div class="image">
	<img src="https://staticassets.goreact.com/astronaut-moon.svg" alt="astronaut-astroid">
</div>
<p class="message">{{ getMessageTranslation() | translate: {days: numDays, date: data.license.ends_at | date: 'mediumDate'} }}</p>
<div class="action-wrapper">
	<button class="tertiary-btn" (click)="close()" translate="license-renewal-snackbar_remind-later"></button>
	<button class="primary-btn" (click)="openPaymentPanel()" translate="license-renewal-snackbar_title-and-button-text"></button>
</div>
