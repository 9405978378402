<div
	class="library-item-row"
	[class.active]="currentlySelected && currentlySelected.hash === item.hash"
	data-cy="library-item-row"
>
	<span
		[attr.aria-label]="getAriaLabel(item)"
		(click)="expandItem($event, item)"
		tabindex="0"
		(keyup.enter)="expandItem($event, item)"
		[attr.aria-expanded]="expanded.includes(item.hash)"
		class="library-item-info"
		[class.withMargin]="multiSelect"
		data-cy="library-item-details"
	>
		<i
			*ngIf="!multiSelect"
			class="library-item-icon"
			[ngClass]="getIcon(item)"
		></i>
		<mat-checkbox
			*ngIf="multiSelect"
			color="primary"
			(click)="$event.stopPropagation()"
			[checked]="item.checked"
			[(ngModel)]="item.checked"
			(change)="checkSelection()"
			[attr.aria-checked]="item.checked"
			[attr.aria-label]="
				(item.checked
					? 'library-collections_multi-deselect-one'
					: 'library-collections_multi-select-one'
				) | translate : { item: item.name }
			"
		>
		</mat-checkbox>
		<span class="collection-item-name" data-cy="collection-item-name">
			{{ item.name || "common_untitled" | translate }}
		</span>
		<span class="collection-item-modified-date">
			{{ formatDate(item.modified_at) }}
		</span>
	</span>
	<button
		*ngIf="!multiSelect"
		[matMenuTriggerFor]="menu"
		class="menu-btn collection-item-menu item-options-dropdown-menu btn-none"
		data-cy="library-item-dropdown-menu"
		[attr.aria-label]="'collection-item-menu' | translate"
		(click)="setActiveToggle($event)"
	>
		<i class="ficon-app-dots-vertical"></i>
	</button>
	<mat-menu #menu="matMenu">
		<ng-container *ngIf="displayMenuOptions">
			<button
				mat-menu-item
				*ngIf="item.collection_item_type !== 'activity'"
				data-cy="common-preview"
				class="preview-option"
				(click)="previewItem(item)"
			>
				<span>{{ "common_preview" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="shouldShowEdit()"
				data-cy="edit-library-item-button"
				class="edit-option"
				(click)="edit(item)"
			>
				<span>{{ "common_edit" | translate }}</span>
			</button>
			<button
				mat-menu-item
				data-cy="common_copy"
				class="copy-option"
				(click)="copyItem(item)"
			>
				<span>{{ "common_copy" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="canMove(item)"
				data-cy="common-move"
				class="move-option"
				(click)="moveItem(item)"
			>
				<span>{{ "common_move" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="canShare()"
				class="share-option"
				(click)="shareItem(item)"
			>
				<span>{{ "common_share" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="canRemove(item)"
				data-cy="common-remove"
				class="remove-option"
				(click)="removeItem(item)"
			>
				<span>{{ "common_remove" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="item.item.filename"
				data-cy="download-library-item"
				class="download-option"
				(click)="downloadItem(item)"
			>
				<span>{{ "common_download" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="canRestore(item)"
				class="restore-option"
				(click)="moveItem(item)"
			>
				<span>{{ "common_restore" | translate }}</span>
			</button>
			<button
				mat-menu-item
				*ngIf="!UADetect.isMobile() && itemInUse"
				class="download-report-option"
				(click)="downloadReport(item)"
			>
				<span>{{ "library-collections_download-report" | translate }}</span>
			</button>
		</ng-container>
		<div class="loading-items" *ngIf="!displayMenuOptions">
			<i class="ficon-spinner ficon-spin"></i>
		</div>
	</mat-menu>
</div>
<div class="library-item-expanded" *ngIf="expanded.includes(item.hash)">
	<div class="library-item-details" tabindex="0">
		<div class="library-item-details-row title-row">
			<span
				class="library-item-details-title"
				translate="library-collections_details-name"
			></span>
			<span>{{ item.name || "common_untitled" | translate }}</span>
		</div>
		<div class="library-item-details-row mobile-only">
			<span
				class="library-item-details-title"
				translate="library-collections_details-last-modified"
			></span>
			<span>{{ formatDate(item.modified_at) }}</span>
		</div>
		<div class="library-item-details-row location-row" *ngIf="item.location">
			<span
				class="library-item-details-title"
				translate="library-collections_details-location"
			></span>
			<span>{{ item.location }}</span>
		</div>
		<div class="library-item-details-row">
			<span
				class="library-item-details-title"
				translate="library-collections_details-creator"
			></span>
			<span>{{ item.creator_name }}</span>
		</div>
		<div class="library-item-details-row">
			<span
				class="library-item-details-title"
				translate="library-collections_details-creation-date"
			></span>
			<span>{{ formatDate(item.item.created_at) }}</span>
		</div>
		<div class="library-item-details-row description-row">
			<span
				class="library-item-details-title"
				translate="library-collections_details-description"
			></span>
			<span class="library-item-description">{{ item.description }}</span>
		</div>
	</div>
	<ngx-media-thumbnail
		*ngIf="item.collection_item_type === 'media' && media"
		class="media-thumbnail"
		[media]="media"
	></ngx-media-thumbnail>
	<div class="library-item-buttons">
		<button
			type="button"
			class="tertiary-btn edit-btn"
			*ngIf="shouldShowEdit()"
			(click)="edit(item)"
			wcag-ignore
		>
			<span translate="common_edit"></span>
		</button>
		<button
			*ngIf="showPreviewButton(item)"
			type="button"
			class="secondary-btn preview-btn"
			data-cy="library-item-preview-button"
			wcag-ignore
			(click)="previewItem(item)"
		>
			<span translate="library-collections_details-preview"></span>
		</button>
		<button
			*ngIf="mode === modes.SELECT"
			data-cy="library-item-select"
			type="submit"
			class="primary-btn submit-btn"
			wcag-ignore
			(click)="selectItem.emit({item: item})"
		>
			<span translate="library-collections_details-select"></span>
		</button>
	</div>
</div>
