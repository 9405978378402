<div class="filter-container comment-filter-user" [formGroup]="form">
	<mat-form-field goSelect>
		<mat-label>{{ 'common_filter-by' | translate }}</mat-label>
		<mat-select
			multiple
			formControlName="selectedUsers"
			disableOptionCentering
			panelClass="offsetPanel commentFilterPanel">
			<mat-select-trigger>
				<!-- need this class for goSelect prepend label -->
				<span class="mat-select-min-line">
					<span *ngIf="allSelected.selected; else numSelected">{{ 'common_all' | translate }}</span>
					<ng-template #numSelected>
						<span class="num-selected-badge">{{ form.value.selectedUsers.length }}</span>
					</ng-template>
				</span>
			</mat-select-trigger>
			<mat-option
				#allSelected
				(click)="toggleAll()"
				[value]="0"
				[disabled]="uniqueUsers.length === 0 || uniqueUsers.length === 1">
				{{ 'common_all' | translate}}
			</mat-option>
			<mat-option
				*ngFor="let user of uniqueUsers"
				(click)="toggleOne()"
				[disabled]="uniqueUsers.length === 1"
				[value]="user.id">
				{{ user.fullname }}
				<small>({{user.count}})</small>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
