<div class="fd-transcription-information">
	<div class="left-container">
		<div class="transcript-select-container" *ngIf="hasAnalytics$ | async">
			<h5 class="transcription-label sub-header-title" translate="feedback-session-feedback-display_transcript-header"></h5>
			<mat-form-field goSelect>
				<mat-select
					[disabled]="shouldTease"
					[(ngModel)]="selectedTranscription"
					(selectionChange)="selectOption($event)"
					disableOptionCentering
					panelClass="offsetPanel">
					<mat-select-trigger>
						{{ getSelectedTranscription() }}
					</mat-select-trigger>
					<mat-option *ngFor="let option of transcriptSelectOptions" [value]="option">
						{{ transcriptSelectTranslations[option] | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<span *ngIf="showBetaLabel()" class="beta-label" translate="common_beta"></span>
		</div>
	</div>

	<div class="right-container">
		<ng-content select="[close-panel]"></ng-content>
	</div>
</div>

<!-- Fetching Transcript -->
<div class="loading-view" *ngIf="isLoading$ | async; else notLoaded">
	<i class="ficon-spinner ficon-spin"></i>
	<span translate="common_loading"></span>
</div>

<!-- Error Fetching Transcript -->
<ng-template #notLoaded>
	<ng-container *ngIf="(requestErrorOccurred$ | async) && !shouldTease">
		<ng-container *ngIf="!media.isYoutube()">
			<ngx-zero-state class="launch-ai-zero-state" *ngIf="shouldShowLaunchAIZeroState()">
				<div zero-state-title translate="transcript-viewer-zero-state_launch-ai"></div>
				<div zero-state-subtext>
					<div translate="transcript-viewer-zero-state_launch-ai-subtext"></div>
					<div>
						<button (click)="runAI()" class="run-ai-button primary-btn" [disabled]="isRunningAI$ | async">
							{{'transcript-viewer-zero-state_launch-ai-run-ai' | translate}}
						</button>
					</div>
				</div>
				<div zero-state-graphic-bottom>
					<img class="zero-state-image" src="https://staticassets.goreact.com/Rocket.svg" alt="{{'transcript-viewer-zero-state_launch-ai' | translate}}">
				</div>
			</ngx-zero-state>

			<ngx-zero-state class="loading-failed-zero-state" *ngIf="!shouldShowLaunchAIZeroState()">
				<div zero-state-title translate="transcript-viewer-zero-state_loading-failed"></div>
				<div zero-state-subtext translate="transcript-viewer-zero-state_loading-failed-subtext"></div>
				<div zero-state-graphic-bottom>
					<img class="loading-failed-zero-state-image zero-state-image" src="https://staticassets.goreact.com/floating-astronaut.svg" alt="{{'transcript-viewer-zero-state_loading-failed-image-alt' | translate}}">
				</div>
			</ngx-zero-state>
		</ng-container>

		<ngx-zero-state class="loading-failed-zero-state" *ngIf="media.isYoutube()">
			<div zero-state-title translate="transcript-viewer-zero-state_youtube"></div>
			<div zero-state-subtext translate="transcript-viewer-zero-state_youtube-subtext"></div>
			<div zero-state-graphic-bottom>
				<img class="loading-failed-zero-state-image zero-state-image" src="https://staticassets.goreact.com/floating-astronaut.svg" alt="{{'transcript-viewer-zero-state_loading-failed-image-alt' | translate}}">
			</div>
		</ngx-zero-state>
	</ng-container>
</ng-template>

<!-- Transcript Successfully Fetched -->
<section class="transcript-container" *ngIf="parsedMediaTranscript$ | async as transcript">

	<!-- Transcript Processing -->
	<div class="loading-view"
		*ngIf="transcript.status === TranscriptStatus.PROCESSING || transcript.status === TranscriptStatus.QUEUED">
		<i class="ficon-spinner ficon-spin"></i>
		<span translate="transcript-viewer-zero-state_transcript-processing-subtext"></span>
	</div>

	<!-- Transcript Failed -->
	<ngx-zero-state *ngIf="transcript.status === TranscriptStatus.ERROR">
		<div zero-state-title translate="transcript-viewer-zero-state_loading-failed"></div>
		<div zero-state-subtext translate="transcript-viewer-zero-state_transcript-failed-subtext"></div>
		<div zero-state-graphic-bottom>
			<img class="zero-state-image" src="https://staticassets.goreact.com/floating-astronaut.svg" alt="{{'transcript-viewer-zero-state_loading-failed-image-alt' | translate}}">
		</div>
	</ngx-zero-state>

	<!-- Transcript Complete -->
	<ng-container *ngIf="transcript.status === TranscriptStatus.COMPLETED">
		<section class="tease-container" *teaseWall="teaseWallConfig;">
			<transcript-search
				[searchResults]="searchResults$ | async"
				[currentSearchIndex]="currentSearchIndex"
				(currentSearchIndexChange)="onSearchIndexChange($event)"
				(searchTextChange)="onSearchTextChange($event)"
			>
			</transcript-search>
			<cdk-virtual-scroll-viewport class="virtual-viewport" [style.overflow-y]="shouldTease ? 'hidden':'auto'">
				<ul role="list" class="sentence-list">
					<li
						role="listitem"
						*cdkVirtualFor="let sentence of transcript.sentences; templateCacheSize: 25;"
						[attr.data-sentence-id]="sentence.id"
						[ngClass]="sentenceStyles.get(sentence.id)">
						<button [tabindex]="shouldTease ? -1: 0" class="sentence-button" (click)="onSentenceClick(sentence)"
								[attr.aria-label]="('transcript-viewer_jump-to' | translate) + (sentence.start | formatTime)
							+ ', ' + ('transcript-viewer_speaker' | translate : { speaker: sentence.speaker})
							+ ', ' + sentence.parsedText">
						<span class="time-container">
							<span class="time-text">
								{{ sentence.start | formatTime }}
							</span>
						</span>
						<span class="sentence-details">
							<span class="sentence-speaker" [hidden]="!(isMultiSpeaker$ | async)">
								<ng-container *ngIf="sentence.speaker.length === 1">
									{{ 'transcript-viewer_speaker' | translate: { speaker: sentence.speaker } }}
								</ng-container>
								<ng-container *ngIf="sentence.speaker.length > 1">
									{{ sentence.speaker }}
								</ng-container>
								<button
									*ngIf="selectedService.getGroup().hasInstructorRole(true) && (isMultiSpeaker$ | async)"
									mat-icon-button
									class="edit-speakers-button"
									(click)="editSpeakers($event)"
									[attr.aria-label]="'edit-transcription-speakers_title' | translate">
									<i class="ficon-app-edit"></i>
								</button>
							</span>
							<span class="sentence-text">
								<ng-container *ngFor="let word of sentence.separateWords; let i = index; let last = last">
									<span [ngClass]="getWordStyle(sentence.id, i)">
										{{ word }}
										<ng-container *ngIf="!last">&#160;</ng-container>
									</span>
								</ng-container>
							</span>
						</span>
						</button>
					</li>
				</ul>
			</cdk-virtual-scroll-viewport>
		</section>
	</ng-container>
</section>


