<div class="graphic">
	<ng-content select="[zero-state-graphic]"></ng-content>
</div>

<div class="heading">
	<ng-content select="[zero-state-title]"></ng-content>
</div>

<div class="subtext">
	<ng-content select="[zero-state-subtext]"></ng-content>
</div>

<div class="body">
	<ng-content select="[zero-state-body]"></ng-content>
</div>

<div class="graphic-bottom">
	<ng-content select="[zero-state-graphic-bottom]"></ng-content>
</div>

