<div class="badge-container" *ngIf="currentBadgeType != null" [ngClass]="currentBadgeType">
	<span class="badge-msg">
		{{ getMessageTranslationKey() | translate }}
	</span>

	<div
		class="action-container"
		*ngIf="shouldShowAction()">
		<span class="dot">&#183;</span>
		<button
			(click)="actionClick.emit()"
			[disabled]="loading || disabled"
			class="btn-none badge-btn"
			translate="{{getActionTranslationKey()}}"
			[attr.aria-label]="getAriaLabelTranslationKey() | translate">
		</button>
		<i *ngIf="loading" class="ficon-spinner ficon-spin" aria-live="polite" [attr.aria-label]="'common_loading'| translate"></i>
	</div>
</div>
