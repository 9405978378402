<div id="edit-user-details-dialog-container" class="modal-dialog modal-xlg">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<h4 class="modal-title">
				<span translate="header_account-settings"></span>
			</h4>
			<button id="edit-user-details-dialog-close" type="button" class="close-btn" (click)="close()" [attr.aria-label]="'common_close' | translate">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body">
			<user-details [hideLanguage]="true" [sessionId]="data.sessionId"></user-details>
		</div>
	</div>
</div>
