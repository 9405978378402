import {
	Component, Input
} from '@angular/core';

@Component({
	selector: 'thumbnail',
	templateUrl: './thumbnail.component.html',
	styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent {
	@Input() public src: string;
	@Input() public duration: number;
	@Input() public fallbackIcon: string;
	@Input() public isPractice: boolean;

	public isLoaded = false;
	public crossorigin: string = 'anonymous';


	public shouldShowFallbackIcon (): boolean
	{
		return !this.src && !!this.fallbackIcon;
	}

	public determineCrossOriginValue (): string|null {
		return this.src?.startsWith('https://img.youtube.com') ? null : 'anonymous';
	}
}
