import { DatePipe } from '@angular/common';
import {
	Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { MenuComponent } from 'ngx/go-modules/src/components/menus/menu/menu.component';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';

@Component({
	selector: 'ngx-session-card',
	templateUrl: './session-card.component.html',
	styleUrls: ['./session-card.component.scss'],
	providers: [DatePipe]
})
export class SessionCardComponent implements OnChanges {
	@Output() public goToSession = new EventEmitter();
	@ViewChild(MenuComponent)
	public menuComponent: MenuComponent;
	@ViewChild('cardContainer', {read: ElementRef})
	public cardContainer: ElementRef;

	@Input() public session: any;

	public nameList: string[] = [];

	constructor (
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(uaDetectToken) private UADetect: UADetectClass,
		private translateService: TranslateService,
		private datePipe: DatePipe,
		public ngxSessionService: NgxSessionService
	) {}

	public ngOnChanges (changes: SimpleChanges): void {
		if (changes.session && this.session) {
			this.nameList = this.session.presenters.map((item) => item.fullname);
		}
	}

	public openMenu (event: MouseEvent) {
		event.stopPropagation();
		// open browser menu on shift right click
		if (event.button === 2 && event.shiftKey) {
			return;
		}
		if (!this.ngxSessionService.shouldDisplayOptionsMenu(this.session)) {
			return;
		}

		event.preventDefault();
		this.menuComponent.openMenu(event, this.cardContainer.nativeElement);
 	}

	public getTooltip (session): string {
		// This has a newline in it, combined with a css
		// rule to preserve the new line in the tooltip.
		return `${session.description}

		${session.getFormattedPresenters()}`;
	}

	public cardAriaLabel () {
		const activity = this.selectedService.getActivity();
		const group = this.selectedService.getGroup();
		const currentUser = this.userService.currentUser;
		const hasPermission = this.session.mayReview(
			currentUser,
			group,
			activity
		);

		let message = this.ngxSessionService.shouldDisplayJoinButton(this.session) ?
			this.translateService.instant('session-list-item_controller-join-live-session') :
			this.translateService.instant('session-list-item_controller-critique-video');

		if (!this.session.isRecorded() &&
			activity.is_conversation &&
			this.UADetect.browserDetector.isOldEdge()
		) {
			message = this.translateService.instant('common_unsupported-feature-try-latest-browser-version');
			// Currently only applicable to Multiple Camera activities
		} else if (!this.session.isRecorded() &&
			this.session.hasMaxPresenterCount(activity) &&
			!this.session.isPresenter(currentUser)) {
			message = this.translateService.instant('session-list-item_controller-max-presenter-count');
		} else if (hasPermission) {
			// check to see if media is ready
			if (this.session.source_media && this.session.source_media.isPending()) {
				message = this.translateService.instant('session-list-item_controller-source-media-not-finished-processing');
			} else if (this.session.media && this.session.media.isPending() && this.session.isRecorded()) {
				message = this.translateService.instant('session-list-item_controller-media-not-finished-processing');
			} else if (this.session.media && this.session.media.isFailed()) {
				message = this.translateService.instant('session-list-item_controller-media-processing-failed');
			}
		} else if (this.session.isOwner(currentUser)) {
			message = this.translateService.instant('session-list-item_controller-not-permission-review-own-video');
		} else {
			message = this.translateService.instant('session-list-item_controller-not-permission-review-this-video');
		}

		return message+ ',' + [this.getVideoThumbnailTitle(), this.getSessionViewedStateText(), this.getCommentAriaLabel()].join(',');
	}

	/**
	 * Get video thumbnail title for screenreader
	 *
	 * @returns {string}
	 */
	public getVideoThumbnailTitle () {
		const startDate = this.datePipe.transform(this.session.started_at ?? '', 'fullDate');

		const presentersText = this.session.presenters
			.map((presenter) => presenter.fullname)
			.join(', ');

		let translation = this.translateService.instant('session-list-item_controller-video-thumbnail-title', {
			presenters: presentersText,
			videoDescription: this.session.description,
			startDate
		});

		// Add info about slides if present
		if (this.hasSlidesEnabled() && this.session.source_media_id) {
			translation += `, ${this.translateService.instant('session-list-item_aria-with-slides')}`;
		}

		// Add info about attachments if present
		if (this.session.num_attachments > 0) {
			translation += `, ${this.translateService.instant('session-list-item_aria-with-attachments', { count: this.session.num_attachments })}`;
		}

		return translation;
	}

	/**
	 * Get the session's state's text whether it's viewed or unviewed.
	 */
	public getSessionViewedStateText () {
		if (this.session.viewed) {
			return this.translateService.instant('session-list-item_controller-viewed_state');
		}

		return this.translateService.instant('session-list-item_controller-unviewed_state');
	}

	public getCommentAriaLabel () {
		if (!this.session.num_comments) {
			return this.translateService.instant('comment-notifications-no-comments');
		} else if (!this.session.num_unviewed_comments) {
			return this.translateService.instant('comment-notifications-viewed');
		} else if (this.session.num_direct_unviewed_comments) {
			const key = this.session.num_direct_unviewed_comments === 1 ?
				this.translateService.instant('comment-notifications-unviewed-single') :
				this.translateService.instant('comment-notifications-unviewed-plural', {number: this.session.num_direct_unviewed_comments});
			return key;
		} else if (this.session.num_unviewed_comments) {
			const key = this.session.num_unviewed_comments === 1 ?
				this.translateService.instant('comment-notifications-unviewed-new-single'):
				this.translateService.instant('comment-notifications-unviewed-new-plural');
			return key;
		}
	}

	/**
	 * Whether sessions activity has slides enabled
	 *
	 * @returns {boolean}
	 */
	public hasSlidesEnabled () {
		return this.selectedService.getActivity().isSlidesEnabled();
	}

	public unviewedState (session): boolean {
		return !session.viewed;
	}
}
