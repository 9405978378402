<div id="goTooltipId"
	class="wrapper"
	goMonitorTabEvent
	(onTabbedOut)="onTabbedOut($event)"
	(onShiftTabbedOut)="onTabbedOut($event)"
	(mouseleave)="onHoverEvent($event)"
	(mouseenter)="onHoverEvent($event)"
	role="tooltip">
    <div class="content">
        <ng-template goAnchor></ng-template>
        <div *ngIf="isAString" [innerHTML]="content"></div>
    </div>
    <div class="go-tooltip-arrow" [ngStyle]="dimension" [ngClass]="[arrowAngleClass]"></div>
</div>
