<div
	class="sidepanel"
	role="navigation"
	[hidden]="!sidePanelVisible"
	[cdkTrapFocus]="shouldTrapFocusToSidepanel">
	<div
		class="side-panel-content"
		*ngIf="!sidePanelLoading">
		<div class="side-panel-header">
			<h2
				translate="library-collections"
				class="side-panel-title">
			</h2>
			<button
				type="button"
				*ngIf="collectionManagement"
				(click)="openManageCollectionsPanel()"
				class="btn btn-none manage-collections-btn"
				data-cy="manage-collections-btn"
				[goTooltip]="'library-collections_manage-collections' | translate"
				goTooltipPlacement="bottom"
				wcag-ignore>
				<i class="ficon-gear"></i>
			</button>
		</div>
		<ul class="side-panel-list">
			<li
				class="collection-list-item side-panel-list-item"
				data-cy="collection-list-item"
				*ngFor="let collection of visibleCollections"
				[ngClass]="{
					'active': collection === selectedCollection ,
					'mineCollection': collection.type === libraryTypes.MINE,
					'orgCollection': collection.type === libraryTypes.ORG,
					'historyCollection': collection.type === libraryTypes.ARCHIVE,
					'legacyCollection': collection.type === libraryTypes.LEGACY,
					'managedCollection': collection.type === libraryTypes.MANAGED,
					'directShareCollection': collection.type === libraryTypes.DIRECT_SHARE
				}"
				tabindex="0"
				(click)="selectCollection(collection)"
				(keyup.enter)="selectCollection(collection)"
				[hidden]="!isCollectionVisible(collection)"
			>
				{{collection.name}}
			</li>
			<span
				*ngIf="visibleCollections.length > 5"
				class="toggle-more-collections"
				tabindex="0"
				(keyup.enter)="toggleCollectionsExpanded()"
				(click)="toggleCollectionsExpanded()">
					<span translate="{{collectionsExpanded ? 'library-collections-show-less' : 'library-collections-show-more'}}"></span>
					<i class="ficon-app-chevron-down" [ngClass]="{'flipped': collectionsExpanded}"></i>
			</span>
		</ul>

		<div class="side-panel-header content-header">
			<h2
				translate="library-collections_content-type"
				class="side-panel-title">
			</h2>
		</div>
		<ul class="side-panel-list">
			<li
				[attr.data-cy]="'type-' + type.slug"
				class="content-type-list-item side-panel-list-item {{type.slug}} type-{{type.slug}} {{isContentTypeSelected(type) ? 'active' : ''}}"
				*ngFor="let type of contentTypes | sort:'sort_order'"
				tabindex="0"
				(keyup.enter)="selectContentType(type)"
				(click)="selectContentType(type)"
			>
				{{type.name}}
			</li>
		</ul>

		<div class="side-panel-header">
			<h2
				translate="library-collections-sort_by"
				class="side-panel-title">
			</h2>
		</div>
		<div
			class="sort-options-container"
			role="radiogroup">
			<div
				class="sort-option-radio-btn radio"
				*ngFor="let option of sortOptions"
				role="radio"
				[attr.aria-checked]="sortBy === option">
				<input
					[attr.tabindex]="sortBy === option ? '0' : '-1'"
					type="radio"
					[class]="['form-control', option].join(' ')"
					[(ngModel)]="sortBy"
					[attr.aria-labelledby]="'optionLabel' + option"
					name="sortByOption"
					[value]="option">
				<label
					id="optionLabel{{option}}"
					class="option-details">
					<span *ngIf="option === 'alphanumeric'" translate="library-collections-sort_alphanumeric"></span>
					<span *ngIf="option === 'alphanumericreverse'" translate="library-collections-sort_alphanumeric-reverse"></span>
					<span *ngIf="option === 'lastmodified'" translate="library-collections-sort_lastmodified"></span>
				</label>
			</div>
		</div>
	</div>
	<div
		class="panel-loading"
		*ngIf="sidePanelLoading">
			<span class="ficon-spinner ficon-spin"></span>
			<span translate="library-viewer_loading"></span>
	</div>
</div>
<main class="main-panel" role="main">
	<div
		class="panel-overlay"
		*ngIf="showOverlay()"
		(click)="toggleSidePanel()">
	</div>
	<div
		class="main-panel-content"
		*ngIf="!mainPanelLoading">
		<div class="main-panel-header">
			<span class="left-header">
				<button
					type="button"
					class="btn btn-none toggle-side-panel-btn"
					(click)="toggleSidePanel()"
					[goTooltip]="'library-collections_show-navigation' | translate"
					goTooltipPlacement="bottom"
					wcag-ignore>
					<i class="ficon-menu"></i>
				</button>
				<h2 class="collection-title">{{selectedCollection.name + ': ' + selectedContentType.name}}</h2>
			</span>
			<mat-slide-toggle
				*ngIf="mode !== modes.SELECT"
				[(ngModel)]="multiSelect"
				(change)="toggleMultiSelect()"
				color="primary"
				class="multi-select-slide-toggle">
				{{ 'library-collections_multi-select' | translate }}
			</mat-slide-toggle>
		</div>
		<div class="library-search-container search-bar">
			<div class="input-group" role="search">
				<span class="search-txt" translate="common_search"></span>
				<input type="text"
					class="form-control search-input"
					data-cy="collection-items-search"
					[formControl]="searchStrForm"
					[attr.aria-label]="('library-viewer-item_search-label' | translate)">
				<span *ngIf="!searchStr" class="input-group-addon"><i class="ficon-search"></i></span>
				<span *ngIf="searchStr" class="input-group-addon search-clear">
					<button (click)="clearSearch()" [attr.aria-label]="('common_cancel-search' | translate)" class="ficon-app-x btn-none"></button>
				</span>
			</div>

			<div aria-live="polite" class="search-result-info" *ngIf="searchStr">
				<span
					*ngIf="collectionItems.items.length > 0"
					data-cy="library-viewer-item_search-result-info"
					[innerHtml]="'library-viewer-item_search-result-info' | translate: {
						text: searchStr,
						count: getResultsCount(),
						total: collectionItems.items.length}
					">
				</span>
			</div>
		</div>
		<div class="collection-items-wrapper" data-cy="collection-items-wrapper" *ngIf="collectionItems.items.length > 0 || collectionItems.folders.length > 0">
			<div class="collection-item-header">
				<div class="leftside-header">
					<div
						class="checkbox"
						*ngIf="multiSelect && !searchStr">
						<mat-checkbox
							class="select-all"
							color="primary"
							[checked]="selectAllChecked"
							[(ngModel)]="selectAllChecked"
							(change)="selectAll()"
							[attr.aria-label]="'library-collections_multi-select-all' | translate"
						>
						</mat-checkbox>
					</div>
					<div
						class="collection-item-header-name"
						[ngClass]="{'withMargin': multiSelect}"
						translate="library-collections_header-name">
					</div>
				</div>
				<div class="rightside-header">
					<div
						class="collection-item-header-modified"
						[ngClass]="{'withMargin': !multiSelect}"
						translate="library-collections_header-modified">
					</div>

					<button
						*ngIf="multiSelect"
						type="button"
						[matMenuTriggerFor]="menu"
						class="menu-btn collection-item-menu item-options-dropdown-menu btn-none"
						data-cy="library-item-dropdown-menu"
						[attr.aria-label]="('collection-item-menu' | translate)"
						(click)="setActiveToggle($event)"
					>
						<i class="ficon-app-dots-vertical"></i>
					</button>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							type="button"
							class="copy-option"
							data-cy="common-copy"
							(click)="copyItems()"
							wcag-ignore>
							<span translate="common_copy"></span>
						</button>
						<button
							mat-menu-item
							type="button"
							class="move-option"
							data-cy="common-move"
							*ngIf="!hideOnArchiveAndManaged()"
							(click)="moveItems()"
							wcag-ignore>
							<span translate="common_move"></span>
						</button>
						<button
							mat-menu-item
							type="button"
							class="remove-option"
							data-cy="common-remove"
							*ngIf="!hideOnArchiveAndManaged()"
							(click)="removeItems()"
							wcag-ignore>
							<span translate="common_remove"></span>
						</button>
					</mat-menu>
				</div>
			</div>
			<div class="collection-items-list">
				<ngx-collection-folder-viewer
					*ngFor="let folder of collectionItems.folders | sort:getSortKey(true)"
					[folder]="folder"
					[expanded]="expanded"
					[expandedFolders]="expandedFolders"
					[mode]="mode"
					[orgSettings]="orgSettings"
					(selectItem)="select($event)"
					[collections]="collections"
					[archiveCollection]="archiveCollection"
					[selectedCollection]="selectedCollection"
					[selectedContentType]="selectedContentType"
					(loadNewItems)="loadNewItems($event)"
					[collectionItems]="collectionItems"
					[sortBy]="sortBy"
					[searchStr]="searchStr"
					[multiSelect]="multiSelect"
					(setSelectAllChecked)="setSelectAllChecked($event)"
					[expandedHeight]="expandedHeight"
					[currentlySelected]="currentlySelected"
					(setActivityPanelIsOpen)="setActivityPanelIsOpen($event)"
					(setCurrentlySelected)="setCurrentlySelected($event)"
				></ngx-collection-folder-viewer>
				<ngx-collection-item-viewer
					class="collection-item"
					[ngClass]="{'active': expanded.includes(item.hash)}"
					*ngFor="let item of itemsWithoutFolder | search:searchStr:['name', 'description', 'creator_name'] | sort:getSortKey()"
					[item]="item"
					[expanded]="expanded"
					[mode]="mode"
					(selectItem)="select($event)"
					[collections]="collections"
					[orgSettings]="orgSettings"
					[archiveCollection]="archiveCollection"
					[selectedCollection]="selectedCollection"
					[selectedContentType]="selectedContentType"
					(loadNewItems)="loadNewItems($event)"
					[collectionItems]="collectionItems"
					[items]="itemsWithoutFolder"
					(updateItems)="updateItems()"
					[multiSelect]="multiSelect"
					(setSelectAllChecked)="setSelectAllChecked($event)"
					[expandedHeight]="expandedHeight"
					[currentlySelected]="currentlySelected"
					(setActivityPanelIsOpen)="setActivityPanelIsOpen($event)"
				></ngx-collection-item-viewer>
			</div>
		</div>
		<div class="zero-state-wrapper" aria-live="assertive" aria-hidden="false">
			<ngx-zero-state
				*ngIf="collectionItems.items.length === 0 && collectionItems.folders.length === 0"
				class="zero-state-items"
				[ngSwitch]="selectedContentType.slug">
				<span zero-state-subtext *ngSwitchCase="'media'" translate="library-viewer-item_zero-state-media"></span>
				<span zero-state-subtext *ngSwitchCase="'rubric'" translate="library-viewer-item_zero-state-rubrics"></span>
				<span zero-state-subtext *ngSwitchCase="'markers'" translate="library-viewer-item_zero-state-markers"></span>
				<span zero-state-subtext *ngSwitchCase="'document'" translate="library-viewer-item_zero-state-documents"></span>
				<span zero-state-subtext *ngSwitchCase="'activity'" translate="library-viewer-item_zero-state-activities"></span>
			</ngx-zero-state>
		</div>
		<div *ngIf="collectionManagement" class="main-panel-footer">
			<button
				type="button"
				[ngClass]="{
					'tertiary-btn': mode === 'select',
					'primary-btn': mode !== 'select'
				}"
				class="close-btn goreact-btn"
				wcag-ignore
				data-cy="lib-close-btn"
				(click)="cancel.emit()"
				translate="{{ mode === 'select' ? 'common_cancel'  : 'common_close' }}">
			</button>
			<span
				class="action-container"
				*ngIf="!hideOnArchiveLegacyAndManaged() || hideOnDirectShare()">
				<button
					type="button"
					id="add-library-item-btn"
					[attr.data-cy]="'add-' + selectedContentType.slug"
					class="goreact-btn link-btn add-btn {{selectedContentType.slug}} add-{{selectedContentType.slug}}"
					wcag-ignore
					(click)="addLibraryItem()"
					[attr.aria-label]="('Add ' + selectedContentType.slug)">
						<span [ngSwitch]="selectedContentType.slug">
							<span *ngSwitchCase="'media'" translate="library-collections_add-media"></span>
							<span *ngSwitchCase="'rubric'" translate="library-collections_add-rubric"></span>
							<span *ngSwitchCase="'markers'" translate="library-collections_add-markers"></span>
							<span *ngSwitchCase="'document'" translate="library-collections_add-document"></span>
							<span *ngSwitchCase="'activity'" translate="library-collections_add-activity"></span>
						</span>
				</button>
				<button
					type="button"
					data-cy="add-folder"
					class="link-btn add-btn add-folder-btn goreact-btn"
					wcag-ignore
					[attr.aria-label]="('library-collections_add-folder-aria' | translate)"
					(click)="openAddFolderModal()">
					<span translate="library-collections_add-folder"></span>
				</button>
			</span>
		</div>
	</div>
	<!-- Only show one loading indicator at a time, so wait until side panel loads to show this (or if side panel isn't visible) -->
	<div
		class="panel-loading"
		*ngIf="(!sidePanelLoading && mainPanelLoading) || (!sidePanelVisible && mainPanelLoading)">
			<span class="ficon-spinner ficon-spin"></span>
			<span translate="library-viewer_loading"></span>
	</div>
</main>
<div
	class="library-overlay"
	*ngIf="activityPanelIsOpen">
</div>
