import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { LicenseSeatsdataSourceElement, LicenseSeatsRoleDataSourceElement } from '../license-seats.datasource';


export enum RemoveUserAction {
	REMOVE_FROM_COURSE,
	REMOVE_FROM_ALL_COURSES
}

export interface RemoveUserConfirmDialogData {
	user: LicenseSeatsdataSourceElement;
	role?: LicenseSeatsRoleDataSourceElement;
	action: RemoveUserAction;
}

@Component({
	selector: 'app-remove-user-confirm-dialog',
	templateUrl: './remove-user-confirm-dialog.component.html'
})
export class RemoveUserConfirmDialogComponent {
	public constructor (
		public dialogRef: MatDialogRef<RemoveUserConfirmDialogComponent, boolean>,
		@Inject(MAT_DIALOG_DATA) public dialogData: RemoveUserConfirmDialogData
	) {
	}

	public get isSingleCourseRemoval () {
		return this.dialogData.action === RemoveUserAction.REMOVE_FROM_COURSE;
	}

	public cancel () {
		this.dialogRef.close(false);
	}

	public confirm () {
		this.dialogRef.close(true);
	}
}
