import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OpenIdConfiguration } from 'ngx/go-modules/src/interfaces/auth-providers';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';

export interface LoginEvent {
	username: string;
	password: string;
}

@Component({
	selector: 'ngx-login',
	templateUrl: './login.component.html',
	styleUrls: [
		'./login.component.scss',
		'./login-sm.component.scss'
	]
})
export class LoginComponent implements OnChanges {
	@Output() public onSignUp: EventEmitter<void> = new EventEmitter();
	@Output() public onForgotPassword: EventEmitter<void> = new EventEmitter();
	@Output() public onLogin: EventEmitter<LoginEvent> = new EventEmitter();
	@Output() public onLoginWithSso: EventEmitter<string> = new EventEmitter();

	@Input() public errorMessage: string;
	@Input() public disabledButton: boolean = false;
	@Input() public email: string|null;

	public form: FormGroup;
	public username = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]);
	public password = new FormControl('', Validators.required);

	public openIdProviders: OpenIdConfiguration[] = [];
	public preferredSsoProvider?: OpenIdConfiguration;

	constructor (
		fb: FormBuilder,
		public authService: NgxAuthService
	) {
		this.form = fb.group({
			username: this.username,
			password: this.password
		});
	}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.email) {
			this.form.controls.username.setValue(this.email);
		}
	}

	public login () {
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			return;
		}

		this.onLogin.emit(this.form.value);
	}

	public loginWithProvider (providerName: string) {
		this.onLoginWithSso.emit(providerName);
	}
}
