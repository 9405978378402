<form
	[formGroup]="modifyCollectionForm"
	#content
	class="modify-collections-panel-form"
	(ngSubmit)="onFormSubmit(modifyCollectionForm)">
	<div class="modify-collections-panel-header">
		<h1
			translate="{{collection ? 'library-collections_modify-collections-edit' : 'library-collections_modify-collections-add'}}"
			class="modify-collections-panel-title">
		</h1>
	</div>
	<div class="modify-collections-panel-content">
		<section class="form-group collection-name-form-group">
			<mat-form-field appearance="outline">
				<mat-label translate="{{collection ? 'library-collections_modify-collections-name-label' : 'library-collections_modify-collections-name-label-new'}}"></mat-label>
				<input
					matInput
					id="collection-name-input"
					data-cy="collection-name-input"
					formControlName="newCollectionName"
					type="text"
					required/>
				<mat-error *ngIf="modifyCollectionForm.controls.newCollectionName.hasError('required')" translate="library-collections_modify-collections-name-error"></mat-error>
			</mat-form-field>
		</section>
		<form [formGroup]="inviteForm" #inviteContent>
			<section class="form-group collection-collaborators-form-group">
				<mat-form-field appearance="outline">
					<mat-label translate="library-collections_modify-collections-collaborators-label"></mat-label>
					<input
						matInput
						id="library-collaborator-email"
						data-cy="library-collaborator-email"
						formControlName="newCollaboratorEmail"
						type="email"
						class="fullstory-ignore"
						(blur)="checkValidity()"
						required/>
					<mat-icon
						matSuffix
						class="ficon-app-help-circle-outline"
						[goTooltip]="'library-collections_modify-collections-collaborators-label_invite-email-help-popover' | translate"
						goTooltipPlacement="top|right"
					></mat-icon>
					<mat-error *ngIf="inviteForm.controls.newCollaboratorEmail.hasError('required')" translate="library-collections_modify-collections-email-blank"></mat-error>
					<mat-error *ngIf="inviteForm.controls.newCollaboratorEmail.hasError('email')" translate="library-collections_modify-collections-email-invalid"></mat-error>
				</mat-form-field>
				<button
					type="button"
					(click)="invite()"
					[focusOnFirstInvalidField]="{formGroup: inviteForm, formWrapper: inviteContent}"
					class="secondary-btn invite-btn"
					wcag-ignore
					translate="common_share">
				</button>
			</section>
		</form>
		<section
			*ngIf="collaboratorEmails.length"
			class="collection-collaborators">
			<h2
				class="collection-collaborators-title"
				translate="library-collections_modify-collections-collaborators">
			</h2>
			<div
				class="collaborator-email"
				*ngFor="let collaborator of uniqueEmails">
				<span tabindex="0" class="fullstory-ignore">{{collaborator}}</span>
				<button
					type="button"
					class="btn-none remove-collaborator"
					(click)="removeCollaborator(collaborator)"
					[attr.aria-label]="'library-collections_modify-collections-remove' | translate: {email: collaborator}"
					wcag-ignore>
						<i class="ficon-app-x"></i>
				</button>
			</div>
		</section>
	</div>
	<footer class="modify-collections-panel-footer">
		<button
			type="button"
			class="tertiary-btn cancel-btn"
			wcag-ignore
			(click)="cancel()"
			translate="common_cancel">
		</button>
		<button
			type="submit"
			[disabled]="saving$ | async"
			[focusOnFirstInvalidField]="{formGroup: modifyCollectionForm, formWrapper: content}"
			class="primary-btn submit-btn"
			wcag-ignore
			translate>
			common_save-and-close
			<i *ngIf="saving$ | async" class="ficon-spinner ficon-spin" aria-live="polite" attr.aria-label="{{'feedback-session_controller-saving'| translate}}"></i>
		</button>
	</footer>
</form>
