<div class="content">
	<span class="content-header" translate="header_billing-history"></span>
	<p *ngIf="isLoading$ | async" class="text-muted text-center" translate="modal-billing-history_loading-transactions"></p>

	<ng-container *ngIf="{transactions: transactions$ | async, plan: plan$ | async, credits: credits$ | async} as asyncData">
		<div class="clearfix test-html">
			<ng-container *ngIf="!asyncData.plan?.has_plan">
				<h4 class="pull-right" *ngFor="let credit of asyncData.credits">
					<span>
						{{ 'modal-billing-history_course-credits' | translate: {available: credit.units_available, max: credit.max_video_mins} }}
					</span>
				</h4>
			</ng-container>
			<h4 class="pull-right plan" *ngIf="asyncData.plan?.has_plan">
				<span translate="modal-billing-history_unlimited"></span>
				<small
					[ngClass]="{'red': asyncData.plan.has_ended}"
					class="exp">
					{{ 'modal-billing-history_expires' | translate: { date: asyncData.plan.ends_at | date: 'd MMM yyyy'} }}
				</small>
			</h4>
		</div>

		<div class="clearfix row-fluid">
			<div class="span12">
				<table class="table" *ngIf="asyncData.transactions?.length">
					<thead>
						<tr class="active">
							<th width="140" translate="modal-billing-history_order"></th>
							<th translate="modal-billing-history_date"></th>
							<th translate="modal-billing-history_for"></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr class="invoice-top-row" *ngFor="let transaction of asyncData.transactions">
							<td>
								<button
									type="button"
									class="tertiary-btn receipt-btn"
									wcag-ignore
									[goTooltip]="'modal-billing-history_send-receipt' | translate"
									(click)="sendEmail(transaction)">
									<i *ngIf="!transaction.emailSending" class="ficon-envelope-o"></i>
									<i *ngIf="transaction.emailSending" class="ficon-spinner ficon-spin"></i>
								</button>&nbsp;
								{{transaction.trans_id}}
							</td>
							<td>
								{{transaction.created_at | date: 'mediumDate'}}
							</td>
							<td>
								<span *ngIf="transaction.payment_type !== 'refund'">{{transaction.group.name}}</span>
								<span *ngIf="transaction.payment_type === 'refund'">{{ 'tmodal-billing-history_refund-for-group' | translate : {group: transaction.group.name} }}</span>
							</td>
							<td class="text-right">
								<span *ngIf="transaction.payment_type === 'refund'">
									<div><span translate="modal-billing-history_subtotal"></span>&nbsp;<span>-{{ transaction.subtotal | currency }}</span></div>
									<div><span translate="modal-billing-history_tax"></span>&nbsp;<span>-{{ transaction.tax_amount | currency }}</span></div>
									<div><strong translate="modal-billing-history_order-total"></strong><strong class="total">-{{ transaction.total | currency }}</strong></div>
								</span>
								<span *ngIf="transaction.payment_type !== 'refund'">
									<div><span translate="modal-billing-history_subtotal"></span>&nbsp;<span>{{ transaction.subtotal | currency }}</span></div>
									<div><span translate="modal-billing-history_tax"></span>&nbsp;<span>{{ transaction.tax_amount | currency }}</span></div>
									<div><strong translate="modal-billing-history_order-total"></strong>&nbsp;<strong class="total">{{ transaction.total | currency }}</strong></div>
								</span>
							</td>
						</tr>
					</tbody>
				</table>

				<div *ngIf="asyncData.transactions?.length === 0" translate="modal-billing-history_no-transaction"></div>
			</div>
		</div>
	</ng-container>
</div>
