<form (ngSubmit)="submit(form)" [formGroup]="form">
	<div class="ngx-dialog goreact-modal confirm-password-dialog-container">
		<div class="goreact-modal-header">
			<h4 class="modal-title" translate="modal-password-confirm_enter-password"></h4>
			<button type="button" class="close-btn" (click)="close()" [attr.aria-label]="'common_close'| translate">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body" #content>
				<mat-form-field appearance="outline" class="current-password">
					<mat-label translate="modal-change-email_current-password"></mat-label>
					<input type="password" matInput formControlName="currentPassword" class="fullstory-ignore" />
					<mat-error *ngIf="form.controls.currentPassword.hasError('required')" translate="modal-change-email_error-password"></mat-error>
				</mat-form-field>
		</div>
		<div class="goreact-modal-footer">
			<span class="error-text" *ngIf="error">{{ error }}</span>
			<button
				type="button"
				class="cancel-btn tertiary-btn"
				(click)="close()"
				[attr.aria-label]="'common_cancel'| translate">
				{{'common_cancel' | translate}}
			</button>
			<button
				type="submit"
				class="primary-btn submit-btn"
				[attr.aria-label]="'common_continue' | translate"
				[disabled]="(loading$ | async)"
				[focusOnFirstInvalidField]="{ formGroup: form, formWrapper: content }">
				<i class="ficon-spinner ficon-spin" *ngIf="loading$ | async"></i>
				<span translate="common_continue"></span>
			</button>
		</div>
	</div>
</form>
