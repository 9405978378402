<ng-container *ngIf="license$ | async as license">
	<ng-container  *ngIf="courseEndsAfterLicense(license) && licenseExpirationHandler.isExpiringLicenseRequiringWarning(license) && !isWhiteLabel">
		<div
			*ngIf="!actionNeeded(license) && !(hasOtherAvailableLicense$ | async)"
			class="renewal-messaging no-action">
			<span
				class="message-with-tooltip"
				[goTooltip]="getMessageTranslation(license).tooltip | translate"
				goTooltipPlacement="bottom">
				{{ getMessageTranslation(license).message | translate : {days: getMessageTranslation(license).numDays} }}</span>
		</div>
		<div
			[ngClass]="license.salesforce_license.is_free_trial ? 'free-trial-red' : 'default-grey'"
			*ngIf="actionNeeded(license) || (hasOtherAvailableLicense$ | async)"
			class="renewal-messaging with-action">
			<span class="message">
				{{ getMessageTranslation(license).message | translate : {days: getMessageTranslation(license).numDays} }}</span>
			<span class="dot">&#183;</span>
			<button
				*ngIf="licenseExpirationHandler.isExpiringLicenseRequiringRenewal(license)"
				class="btn-none renew-btn"
				(click)="renewLicense(license)"
				[translate]="license.salesforce_license.is_free_trial ? 'license-plan-purchase-license':'expiring-license_renew'">
			</button>
			<button
				*ngIf="license.salesforce_license.has_renewal"
				class="btn-none move-btn"
				[disabled]="loading$ | async"
				(click)="moveFolder()"
				translate>
				expiring-license_move-course
				<i *ngIf="loading$ | async" class="ficon-spinner ficon-spin" aria-live="polite" attr.aria-label="{{'common_loading'| translate}}"></i>
			</button>
			<button
				*ngIf="hasOtherAvailableLicense$ | async"
				class="btn-none edit-btn"
				[disabled]="loading$ | async"
				(click)="editFolder()"
				translate>
				course-menu_edit-course
				<i *ngIf="loading$ | async" class="ficon-spinner ficon-spin" aria-live="polite" attr.aria-label="{{'common_loading'| translate}}"></i>
			</button>
		</div>
	</ng-container>
</ng-container>
