import {
	ChangeDetectionStrategy,
	Component,
	OnInit
} from '@angular/core';

@Component({
	selector: 'auth-template',
	templateUrl: './auth-template.component.html',
	styleUrls: ['./auth-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthTemplateComponent implements OnInit {

	constructor () {}

	public ngOnInit (): void {

	}

}
