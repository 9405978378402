import {
	ChangeDetectorRef,
	Component, ElementRef, Inject, OnDestroy
} from '@angular/core';
import {
	TEASE_DATA_LOADING,
	TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
	TEASE_WALL_CONFIG,
	TEASE_WALL_HIDDEN, TEASE_WALL_UPGRADE_REMEMBER_KEY
} from '../constants';
import type { TeaseWallConfig } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.config';
import { Observable, Subject, finalize, take, takeUntil } from 'rxjs';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';

@Component({
	selector: 'tease-wall',
	templateUrl: './tease-wall.component.html',
	styleUrls: ['./tease-wall.component.scss']
})
export class TeaseWallComponent implements OnDestroy {
	public translationKey: string;
	public loading: boolean;
	public hasStoredKey = false;
	public componentDestroyed$$ = new Subject();

	constructor (
		public elementRef: ElementRef,
		@Inject(TEASE_WALL_CONFIG) public config: TeaseWallConfig,
		@Inject(TEASE_DATA_LOADING) public isLoading$: Observable<boolean>,
		@Inject(TEASE_WALL_HIDDEN) public hidden$: Observable<boolean>,
		@Inject('Window') private window: Window,
		@Inject(userServiceToken) private userService: UserService,
		private cdr: ChangeDetectorRef
	) {
		this.initCurrentConfig();
	}

	public initCurrentConfig () {
		let storedKey = null;
		if (this.config.rememberKey === TEASE_WALL_BETA_REQUEST_REMEMBER_KEY) {
			storedKey = this.window.localStorage.getItem(
				`${this.userService.currentUser.user_id}-` + this.config.rememberKey
			);
		} else if (
			this.config.rememberKey === TEASE_WALL_UPGRADE_REMEMBER_KEY &&
			this.config.licenseId != null
		) {
			storedKey = this.window.localStorage.getItem(
				NgxLicenseUpgradeService.createTeaseWallUpgradeRememberKey(
					this.userService.currentUser.user_id, this.config.licenseId
				)
			);
		}

		this.hasStoredKey = !!storedKey;
		this.translationKey = storedKey ?? this.config.translationKey;
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public buttonClicked () {
		this.loading = true;
		this.config.promptAction
			.pipe(
				takeUntil(this.componentDestroyed$$),
				take(1),
				finalize(() => {
					this.loading = false;
					// Ensure the loading spinner state is updated
					this.cdr.markForCheck();
				})
			)
			.subscribe((translationKey) =>  {
				const requestKey = this.getRequestKey();
				if (requestKey) {
					this.hasStoredKey = true;
					this.translationKey = translationKey;
					this.window.localStorage.setItem(requestKey, translationKey);
				}
			});
	}

	private getRequestKey () {
		if (this.config.rememberKey === TEASE_WALL_UPGRADE_REMEMBER_KEY) {
			return NgxLicenseUpgradeService.createTeaseWallUpgradeRememberKey(
				this.userService.currentUser.user_id, this.config.licenseId
			);
		} else if (this.config.rememberKey === TEASE_WALL_BETA_REQUEST_REMEMBER_KEY) {
			// Beta Request
			return `${this.userService.currentUser.user_id}-${this.config.rememberKey}`;
		} else {
			return null;
		}
	}
}
