<div class="sso-wrapper goreact-btn secondary-btn" [ngClass]="providersForDropdown.length === 0 ? 'solo-btn' : null" [attr.disabled]="!preferredSsoProvider">
	<button
		type="button"
		class="login-with-sso-main-btn {{preferredSsoProvider?.auth_provider.provider_name}}"
		[disabled]="!preferredSsoProvider"
		[attr.aria-label]="'common_login-with-sso' | translate:{'provider': preferredSsoProvider?.display_name}"
		(click)="loginWithProvider(preferredSsoProvider)">
		<ng-container *ngIf="loading">
			<loading [width]="'20px'" [height]="'20px'"></loading>
		</ng-container>
		<ng-container *ngIf="!loading && preferredSsoProvider">
			<span class="sso-logo"><img [src]="preferredSsoProvider.logo_url" [alt]="preferredSsoProvider.display_name + 'logo'"></span>
			<span>{{preferredSsoProvider.display_name}}</span>
		</ng-container>
		<ng-container *ngIf="!loading && !preferredSsoProvider">
			<span class="no-providers" translate="sso_no-available-providers"></span>
		</ng-container>
	</button>
	<span class="other-providers-menu" *ngIf="providersForDropdown.length > 0">
		<button type="button" class="other-provider-btn" [matMenuTriggerFor]="menu" aria-label="Additional SSO Providers">
			<i class="ficon-search-chevron-down"></i>
		</button>
		<mat-menu #menu="matMenu" xPosition="before" overlayPanelClass="ssoProviderMenu">
			<button class="additional-provider-btn" mat-menu-item *ngFor="let config of providersForDropdown" (click)="loginWithProvider(config)">
				<span class="sso-logo"><img [src]="config.logo_url" [alt]="config.display_name + 'logo'"></span>
				<span>{{config.display_name}}</span>
			</button>
		</mat-menu>
	</span>
</div>
