<div
	class="library-folder-row"
	[class.active]="currentlySelected?.id === folder.id">
	<span
		[attr.aria-label]="'collection-folder-aria-label' | translate : {name: folder.name, date: formatDate(folder.updated_at)}"
		tabindex="0"
		(keyup.enter)="expandFolder(folder)"
		data-cy="collection-folder"
		[attr.aria-expanded]="expandedFolders.includes(folder.id)"
		(click)="expandFolder(folder)"
		[class.withMargin]="multiSelect || !managedCollectionAccess()"
		class="library-folder-info">
		<i
			class="folder-icon"
			[class.ficon-folder-open]="expandedFolders.includes(folder.id)"
			[class.ficon-folder-filled]="!expandedFolders.includes(folder.id)">
		</i>
		<span class="collection-folder-name">
			{{folder.name}}
		</span>
		<span class="collection-folder-modified-date">
			{{ formatDate(folder.updated_at) }}
		</span>
	</span>
	<button
		*ngIf="!multiSelect && managedCollectionAccess()"
		type="button"
		[matMenuTriggerFor]="menu"
		data-cy="collection-folder-menu"
		class="btn-none collection-folder-menu folder-options-dropdown-menu"
		[attr.aria-label]="'collection-folder-menu' | translate">
		<i class="ficon-app-dots-vertical"></i>
	</button>
	<mat-menu #menu="matMenu">
		<button
			mat-menu-item
			class="edit-folder-button"
			type="button"
			data-cy="common-rename"
			wcag-ignore
			(click)="openEditFolderModal()">
			<span translate="common_rename"></span>
		</button>
		<button
			mat-menu-item
			class="remove-option"
			type="button"
			data-cy="common-remove"
			(click)="removeFolder(folder)"
			wcag-ignore>
			<span translate="common_remove"></span>
		</button>
	</mat-menu>
</div>
<div
	class="library-folder-expanded"
	*ngIf="expandedFolders.includes(folder.id)">
	<ngx-collection-item-viewer
		class="collection-item"
		*ngFor="let item of filteredItems | sort:getSortKey()"
		[class.active]="expanded.includes(item.hash)"
		[item]="item"
		[expanded]="expanded"
		[mode]="mode"
		(selectItem)="selectItem.emit($event)"
		[collections]="collections"
		[orgSettings]="orgSettings"
		[archiveCollection]="archiveCollection"
		[selectedCollection]="selectedCollection"
		[selectedContentType]="selectedContentType"
		(loadNewItems)="loadNewItems.emit($event)"
		[collectionItems]="collectionItems"
		[items]="filteredItems"
		(updateItems)="updateItems()"
		[multiSelect]="multiSelect"
		(setSelectAllChecked)="setSelectAllChecked.emit($event)"
		[expandedHeight]="expandedHeight"
		[currentlySelected]="currentlySelected"
		(setActivityPanelIsOpen)="setActivityPanelIsOpen.emit($event)"
	></ngx-collection-item-viewer>
	<div
		*ngIf="!filteredItems.length"
		class="collection-folder-zero-state"
		translate="collection-folder-no-items">
	</div>
</div>
