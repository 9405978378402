<ng-container *ngIf="(hidden$ | async) === false">
	<i class="ficon-spinner ficon-spin ficon-2x loading-spinner" *ngIf="isLoading$ | async"></i>

	<ng-container *ngIf="!(isLoading$ | async)">
		<div *ngIf="((config.promptAction || config.secondaryLink) && !hasStoredKey); else textView">
			<button
				*ngIf="config.promptAction"
				(click)="buttonClicked()"
				type="submit"
				class="primary-btn"
				[attr.aria-label]="('tease-wall_request-button-aria-label' | translate )+ ', ' +  (translationKey | translate)"
				[disabled]="loading">
				<span [translate]="translationKey"></span>
				<i class="ficon-spinner ficon-spin" *ngIf="loading"></i>
			</button>

			<a *ngIf="config.secondaryLink"
				[attr.aria-label]="'access_denied-learn-more' | translate"
				[attr.href]="config.secondaryLink"
				target="_blank"
				rel="noopener noreferrer"
				class="secondary-link tertiary-btn">
				{{'access_denied-learn-more' | translate}}
			</a>
		</div>

		<ng-template #textView>
			<div class="text-view">
				<i *ngIf="config.promptAction && hasStoredKey" class="ficon-app-checkmark-circle-outline"></i>
				<p tabindex="0"
					aria-live="polite"
					[attr.aria-label]="('tease-wall_request-button-aria-label' | translate) + ', ' +  (translationKey | translate)"
					[translate]="translationKey">
				</p>
			</div>
		</ng-template>
	</ng-container>

</ng-container>
