<form [formGroup]="form" (ngSubmit)="accept()" #content>
	<div class="goreact-modal terms-of-use-dialog">
		<div class="goreact-modal-header">
			<h4 class="modal-title" translate="modal-terms_header"></h4>
		</div>
		<div class="goreact-modal-body">
			<p translate="modal-terms_details"></p>
			<p>
				<a
					[attr.aria-label]="'modal-terms_user-terms' | translate"
					translate="modal-terms_user-terms"
					href="https://get.goreact.com/terms"
					target="_blank"
					rel="noopener noreferrer"></a>
			</p>
			<p>
				<a
					[attr.aria-label]="'modal-terms_privacy' | translate"
					translate="modal-terms_privacy"
					href="https://get.goreact.com/privacy"
					target="_blank"
					rel="noopener noreferrer"></a>
			</p>
	
			<div class="accept-terms-group">
				<mat-checkbox
					data-cy="updated-terms-agree"
					class="accept-terms-checkbox"
					color="primary"
					formControlName="acceptTerms">
					<span translate="modal-terms_accept"></span>
				</mat-checkbox>
				<mat-error
					*ngIf="!form.pristine && form.controls.acceptTerms.hasError('required')"
					translate="modal-terms_must-accept-terms">
				</mat-error>
			</div>
		</div>
		<div class="goreact-modal-footer">
			<button
				data-cy="updated-terms-continue"
				class="primary-btn accept-button"
				type="submit"
				translate="common_continue"
				[focusOnFirstInvalidField]="{formGroup: form, formWrapper: content}">
			</button>
		</div>
	</div>
</form>
