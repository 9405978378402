<div class="search-bar" role="search">
	<div class="search-txt" translate="common_search"></div>
	<input #textInput
		type="text"
		class="form-control search-input"
		[attr.aria-label]="'common_search'| translate"
		[(ngModel)]="searchText"
		(ngModelChange)="onSearchQueryUpdate($event)"/>
	<div class="action-wrapper">
		<ng-container *ngIf="showActionButtons$ | async">
			<span class="search-result-index" *ngIf="searchResults.length > 0">
				{{currentSearchIndex + 1}} {{'transcript-search_of' | translate}} {{searchResults.length}}
			</span>
			<span class="search-result-index" *ngIf="searchResults.length === 0" [attr.aria-label]="'transcript-search_no-results' | translate">
				{{'transcript-search_no-results' | translate}}
			</span>
			<div class="arrow-buttons">
				<button (click)="moveSearchResultSelection(-1)" [attr.aria-label]="'transcript-search_previous-selection' | translate">
					<i class="ficon-search-chevron-up"></i>
				</button>
				<button (click)="moveSearchResultSelection(1)" [attr.aria-label]="'transcript-search_next-selection' | translate">
					<i class="ficon-search-chevron-down"></i>
				</button>
			</div>
		</ng-container>
		<span *ngIf="!searchText"><i class="ficon-search search-btn"></i></span>
		<button *ngIf="searchText" class="ficon-app-x clear-btn" mat-button (click)="clearText()" [attr.aria-label]="'transcript-search_clear-search' | translate"></button>
	</div>
</div>
