<session-analytic-item-view
	(onExpanded)="onExpanded.emit($event)"
	[isExpanded]="isExpanded"
	[dataSummary]="summary"
	[title]="titleTranslation | translate"
	[shouldTease]="shouldTease">
	<div graph>
		<ng-container *ngIf="!noWords; else zeroState">
			<div class="word-normal" translate="{{normalTranslation}}"></div>
			<highcharts-chart
				#chart
				[Highcharts]="Highcharts"
				[options]="chartOptions"
			></highcharts-chart>
			<div class="word-legend">
				<div class="word-legend-item" *ngFor="let wordCount of wordTypeWords">
					<span
						class="word-count"
						[ngStyle]="{
							'color': bestContrast(wordCount.color),
							'background-color': wordCount.color
						}"
					>{{wordCount.count}}</span>
					<span class="word">{{wordCount.word}}</span>
				</div>
			</div>
		</ng-container>
		<ng-template #zeroState>
			<analytic-graph-zero-state>
				{{zeroStateTranslation | translate}}
			</analytic-graph-zero-state>
        </ng-template>
	</div>
</session-analytic-item-view>
