<div class="user-settings">
	<div class="settings-nav">
		<ng-container *ngFor="let option of settingsOptions">
			<a
				*ngIf="showLink(option)"
				[ngClass]="{'selected': selectedOption === option}"
				(keyup.enter)="selectedOption = option"
				(click)="selectedOption = option"
				tabindex="0"
				class="menu-option"
				>
				{{ settingsOptionsTranslations[option] | translate }}
			</a>
		</ng-container>

	</div>
	<div class="settings-menu">
		<mat-form-field class="go-select">
			<mat-select
				[(ngModel)]="selectedOption"
				disableOptionCentering
				panelClass="offsetPanel go-select-panel">
				<ng-container *ngFor="let option of settingsOptions">
					<mat-option
					*ngIf="showLink(option)"
					[value]="option">
					{{ settingsOptionsTranslations[option] | translate }}
				</mat-option>
				</ng-container>

			</mat-select>
		</mat-form-field>
	</div>
	<div class="settings-content" data-cy="settings-content">
		<ng-container [ngSwitch]="selectedOption">
			<ng-container *ngSwitchCase="settingsOptions[0]">
				<user-details></user-details>
			</ng-container>
			<ng-container *ngSwitchCase="settingsOptions[1]">
				<email-notifications></email-notifications>
			</ng-container>
			<ng-container *ngSwitchCase="settingsOptions[2]">
				<default-activity-settings [account]="account"></default-activity-settings>
			</ng-container>
			<ng-container *ngSwitchCase="settingsOptions[3]">
				<billing-history></billing-history>
			</ng-container>
		</ng-container>
	</div>
</div>
