import * as dayjs from 'dayjs';
import { noop } from 'angular';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ActivityEditorPanel, activityEditorPanelToken } from 'go-modules/activity-editor-panel/activity-editor-panel.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CONTENT_TYPES, LIBRARY_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { mediaToken } from 'go-modules/models/media/media.factory';
import { rubricEditorModalToken } from 'go-modules/modals/rubric-editor/modal.factory';
import { mediaPreviewToken } from 'go-modules/modals/media-preview/modal.factory';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { goModalToken } from 'go-modules/modals/go-modal.factory';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { LibraryService, ShareLibraryItemResponse } from 'ngx/go-modules/src/services/library/library.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { orgWideRubricReportToken } from 'go-modules/csv-report/org-wide-rubric-report/org-wide-rubric-report.service';
import { ShareLibraryItemDialogComponent } from '../../dialogs/share-library-item-dialog/share-library-item-dialog.component';
import { InvalidEmailsErrorDialogComponent } from '../../dialogs/invalid-emails-error-dialog/invalid-emails-error-dialog.component';
import { Types } from 'ngx/go-modules/src/services/media/media-types';
import { NgxMediaService } from 'ngx/go-modules/src/services/media/media.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { CollectionDestinationComponent, CollectionDestinationData } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.component';
import { DESTINATION_MODES } from 'ngx/go-modules/src/components/library/collection-destination/collection-destination.constants';

@Component({
	selector: 'ngx-collection-item-viewer',
	templateUrl: './collection-item-viewer.component.html',
	styleUrls: ['./collection-item-viewer.component.scss']
})
export class CollectionItemViewerComponent implements OnInit, OnDestroy {
	@Input() public item: any;
	@Input() public expanded: any;
	public media: any;
	@Input() public mode: string;
	public modes = MODES;
	@Output() public selectItem: EventEmitter<any> = new EventEmitter();
	public toggledItemEl: HTMLButtonElement;
	@Input() public collections: any;
	@Input() public archiveCollection: any;
	@Input() public selectedCollection: any;
	@Input() public selectedContentType: any;
	@Output() public loadNewItems: EventEmitter<any> = new EventEmitter();
	@Input() public collectionItems: any;
	@Input() public items: any;
	@Input() public multiSelect: boolean;
	@Output() public setSelectAllChecked: EventEmitter<boolean> = new EventEmitter();
	@Input() public expandedHeight: number;
	@Input() public currentlySelected: any;
	public itemInUse: boolean;
	@Input() public orgSettings: any;
	public displayMenuOptions: boolean = true;
	public selectedGroup;
	private eventSubscription: any;
	@Output() public setActivityPanelIsOpen: EventEmitter<boolean> = new EventEmitter();
	@Output() public updateItems: EventEmitter<any> = new EventEmitter();

	constructor (
		@Inject(mediaToken) private MediaModel,
		@Inject(rubricEditorModalToken) private rubricEditorModal,
		@Inject(mediaPreviewToken) private mediaPreviewModal,
		private translate: TranslateService,
		private dialog: MatDialog,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		private modal: GoModalService,
		@Inject(goModalToken) private goModal,
		private libraryService: LibraryService,
		private activityService: NgxActivityService,
		@Inject(orgWideRubricReportToken) private orgWideRubricReportService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(activityEditorPanelToken) private activityEditorPanel: ActivityEditorPanel,
		private eventService: EventService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(masqueradeToken) private masquerade: Masquerade,
		@Inject(activityToken) private activityModel,
		private mediaService: NgxMediaService
	) {}

	public ngOnInit () {
		this.selectedGroup = this.selectedService.getGroup() || this.selectedService.getAccount();
		// Watch for pubnub changes on library media
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				const originalItem = this.item;
				if (originalItem && parseInt(originalItem.item.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					Object.assign(originalItem.item, ev.data);

					// If we are expanded already, handle the media we made for that
					if (this.media) {
						Object.assign(this.media, ev.data);
					}
				}
			});

		this.itemInUse = false;
		if (this.item.collection_item_type === CONTENT_TYPES.RUBRICS) {
			this.displayMenuOptions = false;
		}
		if (this.expanded.includes(this.item.hash)) {
			this.media = this.MediaModel.model(this.item.item);
		}
		if (!this.userService.currentUser.is_root_owner || this.masquerade.isMasked()) {
			this.itemInUse = this.item.item.in_use;
		}
	}

	public ngOnDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public formatDate (date) {
		return dayjs(date).format('MMM D, YYYY');
	}

	public getAriaLabel (item) {
		let translationKey;
		//if no filename, assume youtube
		const mediaType = item.item.filename ?
			this.mediaService.determineMediaType(item.item.filename) :
			Types.VIDEO;
		switch(item.collection_item_type)
		{
			case CONTENT_TYPES.RUBRICS:
				translationKey = 'library-collections_content-label-rubric';
				break;
			case CONTENT_TYPES.MARKERS:
				translationKey = 'library-collections_content-label-markers';
				break;
			case CONTENT_TYPES.MEDIA:
				translationKey = mediaType === Types.VIDEO ? 'library-collections_content-label-video' :
					'library-collections_content-label-audio';
				break;
			case CONTENT_TYPES.DOCUMENTS:
				translationKey = mediaType === Types.DOCUMENT ? 'library-collections_content-label-document' :
					'library-collections_content-label-image';
				break;
			case CONTENT_TYPES.ACTIVITIES:
				translationKey = 'library-collections_content-label-activities';
				break;
		}
		return this.translate.instant(translationKey, {name: item.name, date: this.formatDate(item.modified_at)});
	}

	public getIcon (item) {
		let icon;
		//if no filename, assume youtube
		const mediaType = item.item.filename ?
			this.mediaService.determineMediaType(item.item.filename) :
			Types.VIDEO;
		switch(item.collection_item_type)
		{
			case CONTENT_TYPES.RUBRICS:
				icon = 'ficon-rubric-filled';
				break;
			case CONTENT_TYPES.MARKERS:
				icon = 'ficon-app-tag-multiple';
				break;
			case CONTENT_TYPES.MEDIA:
				// Trust mediaType over filename (as thats more accurate until encoding is done)
				if (item.item.media_type === Types.AUDIO) {
					icon = 'ficon-app-volume-medium';
				} else {
					icon = mediaType === Types.VIDEO ? 'ficon-app-camcorder' : 'ficon-app-volume-medium';
				}
				break;
			case CONTENT_TYPES.DOCUMENTS:
				icon = mediaType === Types.DOCUMENT ? 'ficon-document-filled' : 'ficon-image-filled';
				break;
			case CONTENT_TYPES.ACTIVITIES:
				icon = 'ficon-assignment';
				break;
		}
		return icon;
	}

	public loadRubricUsage (rubricItem) {
		this.libraryService.getRubricItemUsage(
			rubricItem.item.id, this.selectedService.getOrg().group_id
		).subscribe((result: any) => {
			this.displayMenuOptions = true;
			this.itemInUse = result.in_use;
		});
	}

	public expandItem ($event, item) {
		const expanded = this.expanded;
		const hash = item.hash;
		if (expanded.includes(hash)) {
			expanded.splice(expanded.indexOf(hash), 1);
		} else {
			expanded.push(hash);
			// scroll down if expanded view won't be visible
			const container = document.querySelector('.collection-items-wrapper') as HTMLElement;
			const containerHeight = container.offsetHeight;
			const containerTotal = containerHeight + container.getBoundingClientRect().top;
			const newRowHeight = $event.target.getBoundingClientRect().bottom + (this.expandedHeight / 6);
			//if the new row height would be outside the container & container is large enough
			if (newRowHeight > containerTotal){
				setTimeout(() => {
					container.scrollTo({
						top: container.scrollTop + newRowHeight - containerTotal,
						behavior: 'smooth'
					});
				});
			}
		}
		this.media = this.MediaModel.model(item.item);
		const folderId = item.library_collection_folder_id;
		this.currentlySelected = {hash, folderId};
	}

	public showPreviewButton (item) {
		return item.collection_item_type === CONTENT_TYPES.RUBRICS ||
			item.collection_item_type === CONTENT_TYPES.MARKERS ||
			(item.collection_item_type === CONTENT_TYPES.DOCUMENTS && this.mediaService.isPreviewable(item.item));
	}

	public previewItem (item) {
		if(item.collection_item_type === CONTENT_TYPES.RUBRICS){
			this.openRubric(item.item_id, 'live');
		} else if (item.collection_item_type === CONTENT_TYPES.DOCUMENTS ||
			item.collection_item_type === CONTENT_TYPES.MEDIA){
			this.openMediaPreviewModal(item, false);
		} else if (item.collection_item_type === CONTENT_TYPES.MARKERS) {
			this.openMarkerPreview(item, false);
		}
	};

	public openRubric (schemaId, mode) {
		return this.rubricEditorModal.open({
			modalData: {
				options: {
					schemaId,
					mode,
					postData: {
						//if we end up copying the rubric then save to same collection
						add_to_collection: this.selectedCollection.id
					}
				}
			}
		}).result;
	};

	public openMarkerPreview (item, edit) {
		return this.goModal.open({
			modal: 'markerSetEditor',
			modalData: {
				markerSet: item.item,
				options: {
					editing: edit
				}
			}
		}).result;
	}

	public openMediaPreviewModal (item, edit) {
		const media = this.MediaModel.model(item.item);
		return this.mediaPreviewModal.open({
			modalData: {
				media,
				edit,
				allowDownload: true
			}
		});
	}

	public edit (libraryItem) {
		if (!this.canEdit(libraryItem)) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_item_edit_error-title'),
					message: this.translate.instant('library-collections_item_edit_error-message')
				}
			}).afterClosed().subscribe(() => {
				this.toggledItemEl.focus();
			});
		}

		if (libraryItem.collection_item_type === CONTENT_TYPES.MEDIA ||
			libraryItem.collection_item_type === CONTENT_TYPES.DOCUMENTS) {
			const media = this.MediaModel.model(libraryItem.item);
			media.description = libraryItem.description;
			return this.mediaPreviewModal.open({
				modalData: {
					media,
					edit: true,
					allowDownload: true,
					groupId: this.selectedGroup.group_id
				}
			}).result
				.then((data) => {
					Object.assign(libraryItem.item, data);
					libraryItem.description = data.description;
					libraryItem.name = data.title;
					libraryItem.modified_at = data.modified_at;
				})
				.catch(noop)
				.finally(() => {
					this.toggledItemEl?.focus();
				});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.MARKERS) {
			return this.openMarkerPreview(libraryItem, true)
				.then((data) => {
					Object.assign(libraryItem.item, data);
					libraryItem.description = data.description;
					libraryItem.name = data.title;
					libraryItem.modified_at = data.modified_at;
				})
				.catch(noop)
				.finally(() => {
					this.toggledItemEl?.focus();
				});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.RUBRICS) {
			this.libraryService.checkRubricUsage(libraryItem.item.id)
				.subscribe((response) => {
					if (response.in_use) {
						const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
							ConfirmDialogComponent, {
								data: {
									title: this.translate.instant('modal-rubric-edit-copy_title'),
									message: this.translate.instant('modal-rubric-edit-copy_message'),
									confirmText: this.translate.instant('common_create-copy'),
									cancelText: this.translate.instant('common_cancel')
								} as ConfirmDialogData
							});

						return dialogRef.afterClosed().pipe(
							filter((confirm: boolean) => confirm)
						).subscribe(() => {
							//copy item and open rubric editor, allow skip if in select mode
							this.copyItem(libraryItem, true, this.mode === MODES.SELECT);
						});
					} else {
						return this.openRubric(libraryItem.item_id, 'edit')
							.then((data) => {
								Object.assign(libraryItem.item, data);
								libraryItem.name = data.title;
								libraryItem.description = data.desc;
								libraryItem.modified_at = data.modified_at;
							})
							.catch(noop)
							.finally(() => {
								this.toggledItemEl?.focus();
							});
					}
				});
		} else if (libraryItem.collection_item_type === CONTENT_TYPES.ACTIVITIES) {
			const headerOptions: any = {
				editMode: true,
				hideName: false
			};

			this.activityService.get(libraryItem.item.id).subscribe((activity) => {
				activity = this.activityModel.model(activity);
				this.setActivityPanelIsOpen.emit(true);
				activity.group_id = this.selectedGroup.group_id;

				return this.activityEditorPanel.open({
					user: this.userService.currentUser,
					group: this.selectedGroup,
					activity,
					headerOptions,
					libraryMode: true,
					orgSettings: this.orgSettings,
					firstFocusSelector: '#activity-editor-name-input'
				}).result.then((newActivity) => {
					libraryItem.name = newActivity.name;
					libraryItem.modified_at = newActivity.modified_at;
				}).catch(noop)
					.finally(() => {
						this.setActivityPanelIsOpen.emit(false);
						this.toggledItemEl?.focus();
					});
			});
		}
	}

	public canEdit (item) {
		return this.isOrgCollectionAndCanModifyItems() ||
			(this.collectionItems.user_ids.includes(item.created_by) &&
				this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public setActiveToggle ($event) {
		this.toggledItemEl = $event.currentTarget;
		if (!this.displayMenuOptions) {
			this.loadRubricUsage(this.item);
		}
	}

	public copyItem (item, launchRubricEditor: boolean = false, allowSkip: boolean = false) {
		const destinationData: CollectionDestinationData = {
			collections: this.collections,
			selectedCollection: this.selectedCollection,
			contentType: this.selectedContentType.slug,
			mode: DESTINATION_MODES.COPY,
			allowSkip
		};
		this.modal.open(CollectionDestinationComponent, false, {
			data: destinationData
		}).afterClosed().subscribe((res) => {
			if (res.dismissed) {
				if (res.hasFolderAdded) {
					if (res.collection.id === this.selectedCollection.id) {
						this.loadNewItems.emit({});
					}
				}
				return;
			}
			let {collection} = res;
			const {folder, autoSelect} = res;
			if (autoSelect) {
				collection = this.archiveCollection;
			}
			const items = [{
				collections: [{collection_id: collection.id} as any]
			}] as any[];

			if (folder?.id > 0) {
				items[0].collections[0].folder_id = folder.id;
			}

			// Handle items from archive that may not
			// have an actual libraryItem
			if (item.id) {
				items[0].itemId = item.id;
			} else {
				items[0].item = {
					item_id: item.item_id,
					item_type: item.collection_item_type
				};
			}

			this.libraryService.copyCollectionItem({
				items
			}).subscribe((copiedItems) => {
				if (launchRubricEditor) {
					this.openRubric(copiedItems.data[0].item_id, 'edit')
						.then((res) => {
							if (autoSelect) {
								this.selectItem.emit({item: {item: res}});
							} else {
								this.loadNewItems.emit({expandItem: {collection, folder, item: res}});
							}
						})
						.catch(noop);
				} else if (this.selectedCollection.id === collection.id){
					this.loadNewItems.emit({});
				}
			});
		});
	}

	public moveItem (item) {
		const destinationData: CollectionDestinationData = {
			collections: this.collections,
			selectedCollection: this.selectedCollection,
			contentType: this.selectedContentType.slug,
			mode: this.canRestore(item) ? DESTINATION_MODES.RESTORE : DESTINATION_MODES.MOVE
		};
		this.modal.open(CollectionDestinationComponent, false, {
			data: destinationData
		}).afterClosed().subscribe((res) => {
			if (res.dismissed) {
				if (res.hasFolderAdded) {
					if (res.collection.id === this.selectedCollection.id) {
						this.loadNewItems.emit({});
					}
				}
				return;
			}
			const {collection, folder} = res;
			const data = {
				new_library_collection_id: collection.id,
				new_library_collection_folder_id: folder?.id ?? null,
				items: [
					{
						library_collection_id: this.selectedCollection.id,
						library_collection_folder_id: item.library_collection_folder_id,
						library_collection_item_id: item.id
					}
				]
			};
			this.libraryService.moveCollectionItem(data)
				.subscribe({
					next: () => {
						this.loadNewItems.emit({});
					},
					error: () => {
						this.dialog.open(MessageDialogComponent, {
							data: {
								title: this.translate.instant('library-collections_item_move_error-title'),
								message: this.translate.instant('library-collections_item_move_error-message')
							}
						}).afterClosed().subscribe(() => {
							this.toggledItemEl.focus();
						});
					}
				});
		});
	}

	public shareItem (item) {
		const dialog$ = ShareLibraryItemDialogComponent.open(this.dialog, {data: {
			collection_id: item.library_collection_ids[0],
			item_id: item.id
		}}).afterClosed();

		dialog$.subscribe((res: ShareLibraryItemResponse) => {
			if (!res) return;
			if (res.invalidUsers.length > 0) {
				InvalidEmailsErrorDialogComponent.open(this.dialog, {data: {
					title: this.translate.instant('modal-share-library-item_share-fail_title'),
					message: this.translate.instant('modal-share-library-item_share-fail_message'),
					emails: res.invalidUsers
				}});
			} else {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'modal-share-library-item_share-success'
				});
			}
		});
	}

	// Either they created the shared collection
	// or they created the item in the collection (with the exception of archive)
	// or any legacy items not in my history
	public canRemove (item) {
		if (this.isOrgCollectionAndCanModifyItems()) {
			return true;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE) {
			return false;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.DIRECT_SHARE ||
			item.shared_to === this.userService.currentUser.user_id) {
			return true;
		}
		if (item.legacy_id !== null) {
			return this.collectionItems.user_ids.includes(this.selectedCollection.created_by);
		}
		return this.collectionItems.user_ids.includes(item.created_by) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public canRestore (item) {
		return !item.location && item.id !== null && this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE;
	}

	public canMove (item) {
		if (this.isOrgCollectionAndCanModifyItems()) {
			return true;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.ARCHIVE) {
			return false;
		}
		if (this.selectedCollection.type === LIBRARY_TYPES.DIRECT_SHARE ||
			item.shared_to === this.userService.currentUser.user_id) {
			return true;
		}
		if (item.legacy_id !== null) {
			return this.collectionItems.user_ids.includes(this.selectedCollection.created_by);
		}
		return this.collectionItems.user_ids.includes(item.created_by) ||
			(this.collectionItems.user_ids.includes(this.selectedCollection.created_by) &&
				this.selectedCollection.type === LIBRARY_TYPES.SHARED);
	}

	public canShare () {
		return !this.orgSettings.block_copied_assignment_editing &&
			this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE;
	}

	public removeItem (item) {
		const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
			ConfirmDialogComponent, {
				data: {
					title: this.translate.instant('library-collections_remove-item'),
					message: this.translate.instant('library-collections_remove-message'),
					confirmText: this.translate.instant('common_remove'),
					cancelText: this.translate.instant('common_cancel')
				} as ConfirmDialogData
			});

		dialogRef.afterClosed().pipe(
			filter((confirm: boolean) => confirm)
		).subscribe(() => {
			this.libraryService.removeCollectionItem(this.selectedCollection.id, [item])
				.subscribe(() => {
					const items = this.items
						.filter((index) => (index.id !== item.id));
					const collectionItems = this.collectionItems.items
						.filter((index) => (index.id !== item.id));
					this.items = items;
					this.collectionItems.items = collectionItems;
					this.updateItems.emit();
				});
		});
	}

	public checkSelection () {
		this.setSelectAllChecked.emit(this.collectionItems.items.every((item) => item.checked));
	}

	public isOrgCollectionAndCanModifyItems () {
		// only care about org collections here
		if (this.selectedCollection.type !== LIBRARY_TYPES.ORG ) {
			return false;
		}
		// root user can always modify
		if (this.userService.currentUser.is_root_user) {
			return true;
		}
		// try to find the org based on the name of the org collection
		let orgToManage = this.selectedService.getMyOrgs().find((org) => org.name === this.selectedCollection.name);
		// in the case the org name was changed, or otherwise not found then just use selected org
		// which may not be correct, but the backend will still block it if unauthorized
		if (!orgToManage) {
			orgToManage = this.selectedService.getOrg();
		}
		return orgToManage.is_admin;
	}

	public shouldShowEdit () {
		if (this.selectedCollection.type === LIBRARY_TYPES.MANAGED) {
			return this.userService.currentUser.is_root_content;
		}
		return this.selectedCollection.type !== LIBRARY_TYPES.ARCHIVE;
	}

	public downloadItem (item) {
		this.MediaModel.download(item.item_id);
	}

	public async downloadReport (item) {
		try {
			await this.orgWideRubricReportService.execute(item.item_id, this.selectedService.getOrg().group_id);
		} catch (error) {
			return this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('rubric-report_download-failed-title'),
					message: this.translate.instant('rubric-report_download-failed-message')
				}
			}).afterClosed().subscribe(() => {
				this.toggledItemEl.focus();
			});
		}
	}
}
