<mat-menu #contextMenu="matMenu">
	<div (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()">
		<div (outsideClick)="closeMenu()" outsideClick cdkTrapFocus>
			<ng-content></ng-content>
		</div>
	</div>
</mat-menu>

<div #menuTrigger class="menu-trigger"
	[style.left.px]="contextMenuPosition.x"
	[style.top.px]="contextMenuPosition.y"
	[matMenuTriggerFor]="contextMenu">
</div>
