import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { goPasswordValidator } from 'ngx/go-modules/src/form-validator/go-password/go-password.validator';
import type { ResetPassword } from 'ngx/go-modules/src/services/auth/interfaces/auth-reset-password.interface';
import { VerificationModes } from 'ngx/go-modules/src/components/email-verification/email-verification.component';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';

@Component({
	selector: 'reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {
	@Input() public email: string;
	@Input() public expiry: number;
	@Input() public hash: string;
	@Output() public onSuccess: EventEmitter<void> = new EventEmitter();
	@Output() public onForgot: EventEmitter<void> = new EventEmitter();

	public form: FormGroup;
	public loading = false;
	public resetLinkExpired = false;
	public resetSuccess = false;
	public resetFailed = false;
	public emailSignature;
	public verificationModes = VerificationModes;

	constructor (
		private authService: NgxAuthService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder
	) {
		this.form = this.fb.group({
			password: ['', [Validators.required, goPasswordValidator()]],
			confirmPassword: ['', [Validators.required]]
		});
	}

	public ngOnInit (): void {
		const expiryDate = new Date(this.expiry * 1000);
		if (new Date() >= expiryDate) {
			this.resetLinkExpired = true;
		}
	}

	public verifyEmail (res) {
		this.emailSignature = res.email_signature;
	}

	public submit (): void {
		if (this.form.valid) {
			this.loading = true;

			const requestData: ResetPassword = {
				password: this.form.controls.password.value,
				confirm_password: this.form.controls.confirmPassword.value,
				email: this.email,
				expiration: this.expiry,
				hash: this.hash,
				email_signature: this.emailSignature
			};

			this.authService.resetPassword(requestData).subscribe({
				next: () => {
					this.loading = false;
					this.resetSuccess = true;
					this.cdr.detectChanges();
				}, error: (_error) => {
					this.loading = false;
					this.resetFailed = true;
					this.cdr.detectChanges();
				}
			});
		}
	}
}
