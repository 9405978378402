<auth-template>
	<email-verification
		*ngIf="!emailSignature && !resetLinkExpired"
		[mode]="verificationModes.PASSWORD_RESET"
		[email]="email"
		(onResend)="onForgot.emit()"
		(onComplete)="verifyEmail($event)">
	</email-verification>
	<div *ngIf="emailSignature || resetLinkExpired" class="reset-wrap">
		<div class="reset-content">
			<section>
				<form [formGroup]="form" (ngSubmit)="submit()"
					class="reset-form"
					*ngIf="!resetSuccess && !resetFailed && !resetLinkExpired">
					<div class="header" translate="reset-password_title"></div>

					<mat-form-field appearance="outline">
						<mat-label translate="reset-password_new_password_label"></mat-label>
						<input type="password" matInput formControlName="password" class="fullstory-ignore" />
						<mat-icon
								matSuffix
								class="ficon-app-help-circle-outline"
								[goTooltip]="'sign-up-password-rules' | translate"
								goTooltipPlacement="top|right"
						></mat-icon>
						<mat-error *ngIf="form.controls.password.invalid" translate="common_password_error-password-requirements"></mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label translate="reset-password_confirm_new_password_label"></mat-label>
						<input type="password" matInput formControlName="confirmPassword" class="fullstory-ignore" pattern="{{ form.controls.password.value }}" />
						<mat-error *ngIf="form.controls.confirmPassword.errors?.pattern" translate="common_password_error-match"></mat-error>
					</mat-form-field>

					<button type="submit" [disabled]="loading" class="submit-btn primary-btn" translate="reset-password_title"></button>
				</form>

				<div class="reset-success" *ngIf="resetSuccess">
					<div class="header" translate="reset-password_title"></div>
					<p class="message" translate="reset-password_success-message"></p>
					<button (click)="onSuccess.emit()" queryParamsHandling="preserve" class="primary-btn" translate="reset-password_success-continue_btn_text"></button>
				</div>

				<div class="reset-failed" *ngIf="resetFailed">
					<div class="header" translate="reset-password_title"></div>
					<p class="message" translate="reset-password_failed-message"></p>
					<button (click)="onForgot.emit()" queryParamsHandling="preserve" class="primary-btn" translate="reset-password_success-continue_btn_text"></button>
				</div>

				<div class="reset-link-expired" *ngIf="resetLinkExpired">
					<div class="header" translate="reset_password-link-expired_title"></div>
					<p class="message" translate="reset-password-link-expired_message"></p>
					<button (click)="onForgot.emit()" queryParamsHandling="preserve" class="primary-btn" translate="reset-password_success-continue_btn_text"></button>
				</div>
			</section>
		</div>
	</div>
</auth-template>
