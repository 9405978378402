<session-analytic-item-view
	(onExpanded)="onExpanded.emit($event)"
	[isExpanded]="isExpanded"
    [title]="'feedback-session-feedback-display_analytics_pauses-header' | translate"
    [dataSummary]="'feedback-session-feedback-display_analytics_pauses_data-summary' | translate : {
        totalPauses,
        totalLengthyPauses
    }"
    [shouldTease]="shouldTease">
    <div graph
        class="pause-graph-container"
        role="application">
        <ng-container *ngIf="pauseInstances.length > 0; else zeroState">
            <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="chartOptions">
            </highcharts-chart>
        </ng-container>

		<ng-template #zeroState>
			<analytic-graph-zero-state>
				{{(media.isYoutube() ? 'transcript-viewer-zero-state_youtube-subtext' : 'feedback-session-feedback-display_analytics_pauses_zero-state-label') | translate}}
			</analytic-graph-zero-state>
        </ng-template>
    </div>
</session-analytic-item-view>
