<div class="modal-dialog">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<h4 class="modal-title">
				<span [ngSwitch]="data.mode">
					<span *ngSwitchCase="'add'" [ngSwitch]="data.contentType">
						<span *ngSwitchCase="'media'" translate="collection-destination_title-media-add"></span>
						<span *ngSwitchCase="'rubric'" translate="collection-destination_title-rubric-add"></span>
						<span *ngSwitchCase="'markers'" translate="collection-destination_title-markers-add"></span>
						<span *ngSwitchCase="'document'" translate="collection-destination_title-document-add"></span>
						<span *ngSwitchCase="'activity'" translate="collection-destination_title-activity-add"></span>
					</span>
					<span *ngSwitchCase="'copy'" [ngSwitch]="data.contentType">
						<span *ngSwitchCase="'media'" translate="collection-destination_title-media-copy"></span>
						<span *ngSwitchCase="'rubric'" translate="collection-destination_title-rubric-copy"></span>
						<span *ngSwitchCase="'markers'" translate="collection-destination_title-markers-copy"></span>
						<span *ngSwitchCase="'document'" translate="collection-destination_title-document-copy"></span>
						<span *ngSwitchCase="'activity'" translate="collection-destination_title-activity-copy"></span>
					</span>
					<span *ngSwitchCase="'move'" [ngSwitch]="data.contentType">
						<span *ngSwitchCase="'media'" translate="collection-destination_title-media-move"></span>
						<span *ngSwitchCase="'rubric'" translate="collection-destination_title-rubric-move"></span>
						<span *ngSwitchCase="'markers'" translate="collection-destination_title-markers-move"></span>
						<span *ngSwitchCase="'document'" translate="collection-destination_title-document-move"></span>
						<span *ngSwitchCase="'activity'" translate="collection-destination_title-activity-move"></span>
					</span>
					<span *ngSwitchCase="'restore'" [ngSwitch]="data.contentType">
						<span *ngSwitchCase="'media'" translate="collection-destination_title-media-restore"></span>
						<span *ngSwitchCase="'rubric'" translate="collection-destination_title-rubric-restore"></span>
						<span *ngSwitchCase="'markers'" translate="collection-destination_title-markers-restore"></span>
						<span *ngSwitchCase="'document'" translate="collection-destination_title-document-restore"></span>
						<span *ngSwitchCase="'activity'" translate="collection-destination_title-activity-restore"></span>
					</span>
					<span *ngSwitchCase="'addToLibrary'" [ngSwitch]="data.contentType">
						<span *ngSwitchCase="'activity'" translate="collection-destination_title-activity-add-to-library"></span>
					</span>
				</span>
			</h4>
			<button
				class="close-btn"
				type="button"
				[attr.aria-label]="('common_close' | translate)"
				(click)="dismiss()">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body" tabindex="0">
			<div class="form-div" [formGroup]="form" #content>
				<mat-form-field appearance="outline" *ngIf="data.showNameField" class="activity-form">
					<mat-label translate="collection-destination_assignment_name-label"></mat-label>
					<input matInput formControlName="name" />
					<mat-error
						*ngIf="form.controls.name.hasError('required')"
						translate="collection-destination_assignment_name-error-required">
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label translate="collection-destination_collection-label"></mat-label>
					<mat-select
						formControlName="selectedCollection"
						(selectionChange)="loadFolders()"
						disableOptionCentering
						panelClass="offsetPanel"
						required>
						<mat-option
							*ngFor="let collection of collections"
							[value]="collection">
							{{ collection.name }}
						</mat-option>
					</mat-select>
					<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
					<mat-error
						*ngIf="form.controls.selectedCollection.hasError('required')"
						translate="collection-destination_assignment_name-error-required">
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" *ngIf="folders.length > 1 && !(foldersLoading$ | async)">
					<mat-label translate="collection-destination_folder-label"></mat-label>
					<mat-select
						formControlName="selectedFolder"
						data-cy="folder-selection"
						[placeholder]="'common_none' | translate"
						disableOptionCentering
						panelClass="offsetPanel data-cy-folder-panel">
						<mat-option
							*ngFor="let folder of folders"
							[value]="folder">
							{{ folder.name }}
						</mat-option>
					</mat-select>
					<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
				</mat-form-field>
			</div>
		</div>
		<div class="goreact-modal-footer">
			<span class="left">
				<button
					*ngIf="data.allowSkip"
					type="button"
					data-cy="skip-collection-btn"
					class="goreact-btn link-btn skip-btn"
					(click)="autoSelect()"
					translate="collection-destination_dont-save"
					wcag-ignore>
				</button>
				<button
					type="button"
					data-cy="add-folder-on-destination"
					class="link-btn add-btn goreact-btn"
					wcag-ignore
					[attr.aria-label]="('library-collections_add-folder-aria' | translate)"
					(click)="openAddFolderModal()">
					<span translate="library-collections_add-folder"></span>
				</button>
			</span>
			<span class="right">
				<button
					id="cancel-collection-btn"
					type="button"
					class="goreact-btn tertiary-btn"
					(click)="dismiss()"
					translate="common_cancel"
					wcag-ignore>
				</button>
				<button
					type="button"
					data-cy="choose-collection-next"
					class="goreact-btn primary-btn primary-btn-action"
					[focusOnFirstInvalidField]="{formGroup: form, formWrapper: content}"
					(click)="close()"
					wcag-ignore>
					<span [ngSwitch]="data.mode">
						<span *ngSwitchCase="'add'" translate="common_next"></span>
						<span *ngSwitchCase="'copy'" translate="common_save"></span>
						<span *ngSwitchCase="'move'" translate="common_save"></span>
						<span *ngSwitchCase="'restore'" translate="common_restore"></span>
						<span *ngSwitchCase="'addToLibrary'" translate="common_copy"></span>
					</span>
				</button>
			</span>
		</div>
	</div>
</div>
