import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ngx-zero-state',
	templateUrl: './zero-state.component.html',
	styleUrls: ['./zero-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxZeroStateComponent {
	constructor () {}
}
