import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { LibraryCollectionFolder, LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { InvalidValueValidator } from 'ngx/go-modules/src/validators/invalid-value';

@Component({
	selector: 'ngx-manage-library-collection-folder',
	templateUrl: './manage-library-collection-folder.component.html',
	styleUrls: ['./manage-library-collection-folder.component.scss']
})
export class ManageLibraryCollectionFolderComponent implements OnInit {
	public form = new FormGroup({
		name: new FormControl('', [Validators.required]),
		selectedCollection: new FormControl(null, [])
	});

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			collections: any[];
			existingFolders: LibraryCollectionFolder[];
			createMode: boolean;
			selectedCollection: any;
			folder: any;
		},
		private libraryService: LibraryService
	) {}

	public ngOnInit () {
		if (this.data.createMode) {
			if (!this.data.selectedCollection) {
				return this.dialogRef.close({ request_failed: true });
			}
			this.form.controls.selectedCollection.setValue(this.data.selectedCollection);
		} else {
			if (!this.data.folder || !this.data.folder.id) {
				return this.dialogRef.close({ request_failed: true });
			}
			this.form.controls.name.setValue(this.data.folder.name);
		}

		let existingNames = this.data.existingFolders.map((folder) => folder.name.toLowerCase().trim());
		// if renaming folder then can still 'rename' it to existing name
		if (!this.data.createMode) {
			existingNames = existingNames.filter((name) => name !== this.data.folder.name.toLowerCase().trim());
		}
		this.form.controls.name.addValidators(InvalidValueValidator(existingNames));
	}

	public loadFolders () {
		this.libraryService.getCollectionFolders(this.form.value.selectedCollection.id)
			.subscribe((folders: LibraryCollectionFolder[]) => {
				this.data.existingFolders = folders;

				// re-validate folderName to check unique name
				this.form.controls.name.clearValidators();
				let existingNames = this.data.existingFolders.map((folder) => folder.name.toLowerCase().trim());
				if (!this.data.createMode) {
					existingNames = existingNames.filter((name) => name !== this.data.folder.name.toLowerCase().trim());
				}
				this.form.controls.name.addValidators([Validators.required, InvalidValueValidator(existingNames)]);
				this.form.controls.name.updateValueAndValidity();
			});
	}

	public dismiss () {
		this.dialogRef.close({ dismissed: true });
	}

	public submit (form) {
		if (form.invalid) {
			return;
		}

		if (form.pristine) {
			return this.dismiss();
		}

		if (this.data.createMode) {
			this.libraryService.createFolder(this.form.value.selectedCollection.id, this.form.value.name)
				.subscribe({
					next: (newFolder) => {
						this.dialogRef.close(newFolder);
					},
					error: (error) => {
						this.dialogRef.close({ request_failed: true, error });
					}
				});
		} else {
			this.libraryService.editFolder(this.data.folder.id, this.form.value.name)
				.subscribe({
					next: (updatedFolder) => {
						this.dialogRef.close(updatedFolder);
					},
					error: (error) => {
						this.dialogRef.close({ request_failed: true, error });
					}
				});
		}
	}

	public getTitleTranslationKey () {
		return this.data.createMode ? 'add-collection-folder_title' : 'edit-collection-folder_label';
	}

	public getPlaceholderKey () {
		return this.data.createMode ? 'add-collection-folder_placeholder' : 'edit-collection-folder_placeholder';
	}

	public getButtonName () {
		return this.data.createMode ? 'common_add' : 'common_rename';
	}
}
