import { Injectable } from '@angular/core';
import {
	CanActivate,
	UrlTree,
	Router,
	ActivatedRouteSnapshot
} from '@angular/router';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AppService } from '@video-sharing/app/common/services/app/app.service';
import { AuthService } from '@video-sharing/app/common/services/auth/auth.service';
import { ExternalSessionPublicDetails } from '../interfaces/app/external-session-public-data';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor (
		public auth: AuthService,
		private router: Router,
		private appService: AppService
	) {}

	public canActivate (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.auth.isAuthenticated()) {

			// Based on the route param, determine where we should send them
			return this.appService.getPublicSessionDetails(route.params.uuid)
				.pipe(

					switchMap((details: ExternalSessionPublicDetails) => {
						let path = '/login';

						if (details.email !== null && !details.has_account) {
							path = '/sign-up';
						}

						this.goToRoute(path, route);
						return of(true);
					}),

					catchError(() => {
						this.goToRoute('/login', route);
						return of(true);
					})
				);
		}

		return true;
	}

	public goToRoute (path: string, route: ActivatedRouteSnapshot): void {
		this.router.navigate(
			[path],
			{ queryParams: { session: route.params.uuid } }
		);
	}
}
