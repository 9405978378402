import * as angular from 'angular';
import { feedbackSessionModule } from 'go-modules/feedback-session';
import { headerViewModule } from 'go-modules/header';
import { downgradeComponent } from '@angular/upgrade/static';
import { appRunModule } from 'go-modules/app-run';
import 'angular-translate-loader-static-files';
import { zendeskModule } from 'go-modules/services/zendesk';
import 'go-modules/zendesk-settings';
import { responsiveViewModule } from 'go-modules/responsive-view';
import { HeaderToolbarComponent } from '../common/components/header-toolbar/header-toolbar.component';
import { accountLockoutModalModule } from 'go-modules/modals/account-lockout';
import { changeEmailModalModule } from 'go-modules/modals/change-email';
import { userModel } from 'go-modules/models/user';
import { eventServiceModule } from 'ngx/go-modules/src/services/event';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { GoLocalizeHelperService, Language } from 'go-modules/translation-helper/go-localize-helper.service';
import { goTourModule } from 'go-modules/go-tour';
import { environment } from '@video-sharing/environments/environment';

export const feedbackWrapperModule = angular.module('FeedbackWrapperModule', [
	appRunModule,
	feedbackSessionModule,
	headerViewModule,
	responsiveViewModule,
	zendeskModule,
	accountLockoutModalModule,
	changeEmailModalModule,
	userModel,
	eventServiceModule,
	goLocalizeHelperModule,
	goTourModule
])
	// Adding config for $mdGestureProvider will fix <mat-checkbox> not triggering its change event
	// The issue only occurs in Chrome-family browsers in mobile, issue does not occur in Safari
	// Used this ticket with similar issue as reference: https://github.com/angular/components/issues/20725
	.config([
		'$mdGestureProvider',
		function ($mdGestureProvider) {
			$mdGestureProvider.disableAll();
		}
	])
	.directive('headerToolbar', downgradeComponent({ component: HeaderToolbarComponent }))
	.component('videoShareHeader', { template: '<header-view><header-toolbar></header-toolbar></header-view>' })
	// Angular.js routing is EVIL
	.config(['$provide', ($provide) => {
		$provide.decorator('$browser', ['$delegate', ($delegate) => {
			$delegate.onUrlChange = () => {};
			$delegate.url = () => {
				return '';
			};
			return $delegate;
		}]);
	}])
	// NOTE:
	// This was copied from `translate.config.ts` in go-modules. Any chagnes there should be synced here,
	// with the exception of the prefix for where to get the translations
	.config(['$translateProvider', '$translatePartialLoaderProvider', 'tmhDynamicLocaleProvider', (
		$translateProvider: ng.translate.ITranslateProvider,
		$translatePartialLoaderProvider: ng.translate.ITranslatePartialLoaderProvider,
		tmhDynamicLocaleProvider: tmh.tmh.IDynamicLocaleProvider
	) => {

		$translateProvider.useLoader('$translatePartialLoader', {
			urlTemplate: `${environment.translationsPrefix}translations/translation/{lang}{part}?v=@@CACHEBUST@@`
		});

		tmhDynamicLocaleProvider.localeLocationPattern(`${environment.translationsPrefix}translations/locales/locale-{{locale}}.js`);
		tmhDynamicLocaleProvider.defaultLocale(Language.EN);

		// This ensures the "main" json file is loaded immediately
		$translatePartialLoaderProvider.addPart('.json');

		$translateProvider.uniformLanguageTag('bcp47');
		$translateProvider.useSanitizeValueStrategy('escapeParameters');
		$translateProvider.preferredLanguage('en');
		$translateProvider.fallbackLanguage('en');
		$translateProvider.useMissingTranslationHandler('goreactMissingTranslationHandler');
	}])
	.run(['goLocalizeHelper', (goLocalizeHelper: GoLocalizeHelperService) => {
		goLocalizeHelper.configure(Language.EN);
	}])
	.name;
