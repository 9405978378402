import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input
} from '@angular/core';
import { StateService } from '@uirouter/angularjs';
import { $stateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state.upgrade';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';

@Component({
	selector: 'license-management',
	templateUrl: './license-management.component.html',
	styleUrls: ['./license-management.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseManagementComponent {
	@Input() public orgId;
	@Input() public myAccount;
	public showMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor (
		@Inject($stateToken) public $state: StateService,
		private groupService: NgxGroupService,
		private ngxUserService: NgxUserService
	) {}

	public ngOnInit () {
		let expiredLicenses;
		if (this.orgId == null) {
			expiredLicenses = this.ngxUserService.getExpiredLicenses();
		} else {
			expiredLicenses = this.groupService.getExpiredLicenses(this.orgId);
		}
		expiredLicenses.pipe().subscribe((value) => {
			this.showMenu.next(value.licenses.length > 0);
		});
	}
}
