import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import type { ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import type { LicenseHolderOrInvite, SalesforceLicense } from 'ngx/go-modules/src/interfaces/licenses';
import { isInvite } from 'ngx/go-modules/src/interfaces/licenses/license-holder-or-invite';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';
import { Observable, Subject, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';
import type { LicenseHoldersAndInvitesResponse } from 'ngx/go-modules/src/services/license/license.service';
import { userServiceToken } from 'go-modules/models/user/user.service';
import type { UserService } from 'go-modules/models/user/user.service';

const REMOVE_HOLDER_TITLE = 'license-management_confirmation-remove-holder-title';
const REMOVE_HOLDER_MESSAGE = 'license-management_confirmation-remove-holder-message';
const REMOVE_HOLDER_BUTTON = 'license-management_confirmation-remove-holder-button';

export const REMOVE_HOLDER_SUCCESS = 'license-management_confirmation-remove-success';
export const NETWORK_LOST_TRANSLATION_KEY = 'license-management_error-network-lost';
export const UNKNOWN_ERROR_TRANSLATION_KEY = 'license-management_error-unknown-error';

@Component({
	selector: 'license-holder',
	templateUrl: './license-holder.component.html',
	styleUrls: ['./license-holder.component.scss'],
	providers: [TranslatePipe]
})
export class LicenseHolderComponent implements OnDestroy {
	@Input() public salesforceLicense: SalesforceLicense;
	@Output() public viewSeatsEvent = new EventEmitter<string>();

	private componentDestroyed$$ = new Subject();

	public constructor (
		@Inject(userServiceToken) private userService: UserService,
		public auth: NgxAuthService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private translationPipe: TranslatePipe,
		private licenseService: LicenseService,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef) {
	}

	private static handleRemoveUserError (err: HttpErrorResponse): Observable<string> {
		if (err.status === 0) {
			return of(NETWORK_LOST_TRANSLATION_KEY);
		}

		return of(UNKNOWN_ERROR_TRANSLATION_KEY);
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(void(0));
		this.componentDestroyed$$.complete();
	}

	public canUpdateHolder (holder): boolean {
		return this.userService.currentUser.is_root_user ||
			(!holder.account_owner && holder.user?.user_id !== this.userService.currentUser.user_id);
	}

	public canRemoveHolder (holder): boolean {
		return this.userService.currentUser.is_root_user ||
			(!holder.account_owner && holder.user?.user_id !== this.userService.currentUser.user_id);
	}

	public canAdministerLicense (): boolean {
		// Root user or Org Admin can always make changes
		if (this.userService.currentUser.is_root_user
			|| this.salesforceLicense.is_org_admin
			|| this.salesforceLicense.is_license_admin
		) {
			return true;
		}

		const myHolder = this.salesforceLicense.license.license_holders
			.find((holder) => holder.user.user_id === this.userService.currentUser.user_id);

		// This shouldn't normally be possible, but if a root deleted the license owner, then
		// I could have access to the license, but not be in the list...
		if (myHolder == null) {
			return false;
		}

		return myHolder.is_admin;
	}

	public permissionChange (event: MatSelectChange, holder: LicenseHolderOrInvite) {
		if (event.value !== holder.is_admin) {
			let confirmData: ConfirmDialogData;
			if(holder.is_admin) {
				confirmData = {
					title: this.translationPipe.transform('license-management_confirmation-permision-to-instructor-title'),
					message: this.translationPipe.transform('license-management_confirmation-permision-to-instructor-message'),
					confirmText: this.translationPipe.transform('license-management_confirmation-permision-to-instructor-confirm-button')
				};
			} else {
				confirmData = {
					title: this.translationPipe.transform('license-management_confirmation-permision-to-admin-title'),
					message: this.translationPipe.transform('license-management_confirmation-permision-to-admin-message'),
					confirmText: this.translationPipe.transform('license-management_confirmation-permision-to-admin-confirm-button')
				};
			}

			const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: confirmData });
			const isAdminRollback = holder.is_admin;

			dialogRef.afterClosed()
				.pipe(
					take(1),
					takeUntil(this.componentDestroyed$$),
					filter((value) => {
						if (value) {
							event.source.disabled = true;
							holder.is_admin = event.value;
							return true;
						}

						event.source.writeValue(holder.is_admin);
						return false;
					}),
					switchMap(() => {
						return isInvite(holder) ?
							this.licenseService.updateLicenseHolderInvite(holder.license_id, holder) :
							this.licenseService.updateLicenseHolder(holder.license_id, holder);
					}),
					finalize(() => event.source.disabled = false)
				)
				.subscribe({
					next: () => {
						this.snackBar.open(this.translationPipe.transform('license-management_permission-update-success-message'), null, { duration: 1000 });
					},
					error: (httpError: HttpErrorResponse) => {
						holder.is_admin = isAdminRollback;
						event.source.writeValue(isAdminRollback);
						this.snackBar.open(httpError.error.message, null, { duration: 1000 });
					}
				});
		}
	}

	public addUsers () {
		const dialog$ = InviteUserDialogComponent.open(this.dialog, {data: {
			salesforceLicense: this.salesforceLicense
		}}).afterClosed();

		dialog$.pipe(
			filter((holdersAndInvites: LicenseHoldersAndInvitesResponse | undefined) => holdersAndInvites != null)
		).subscribe((holdersAndInvites: LicenseHoldersAndInvitesResponse) => {
			this.salesforceLicense.license.license_holders.push(...holdersAndInvites.license_holders);
			this.salesforceLicense.license.license_holder_invites.push(...holdersAndInvites.license_holder_invites);
			// While I generally dislike calling detectChanges and prefer pipes, modifying getHoldersAndInvites
			// into an observable pipe is a lot more complex.
			this.cdr.detectChanges();
		});
	}

	public removeUser (holder: LicenseHolderOrInvite & {removing: boolean}) {
		const removeHolderTranslationKeys = [
			REMOVE_HOLDER_TITLE,
			REMOVE_HOLDER_MESSAGE,
			REMOVE_HOLDER_BUTTON
		];
		this.translate.get(removeHolderTranslationKeys).pipe(
			map((translations) => ({
				title: translations[REMOVE_HOLDER_TITLE],
				message: translations[REMOVE_HOLDER_MESSAGE],
				confirmText: translations[REMOVE_HOLDER_BUTTON]
			})),
			switchMap((confirmData) => ConfirmDialogComponent.open(this.dialog, { data: confirmData }).afterClosed()),
			filter((confirm: boolean) => confirm),
			tap(() => {
				holder.removing = true;
				this.cdr.detectChanges();
			}),
			switchMap(() =>
				isInvite(holder) ?
					this.licenseService.removeLicenseHolderInvite(this.salesforceLicense.license_id, holder.id) :
					this.licenseService.removeLicenseHolder(this.salesforceLicense.license_id, holder.id)
			),
			tap(() => {
				const list: LicenseHolderOrInvite[] = isInvite(holder) ?
					this.salesforceLicense.license.license_holder_invites :
					this.salesforceLicense.license.license_holders;

				const index = list.indexOf(holder);
				list.splice(index, 1);

				if (!isInvite(holder) && holder.seats_consumed != null) {
					this.salesforceLicense.total_seats_consumed -= holder.seats_consumed;
				}
			}),
			map(() => REMOVE_HOLDER_SUCCESS),
			catchError(LicenseHolderComponent.handleRemoveUserError),
			switchMap((translationKey: string) => this.translate.get(translationKey)),
			finalize(() => {
				holder.removing = false;
				this.cdr.detectChanges();
			})
		).subscribe((translationString: string) => this.snackBar.open(translationString, null, { duration: 3000 }));
	}

	public getHoldersAndInvites (): LicenseHolderOrInvite[] {
		return [
			...this.salesforceLicense.license.license_holders,
			...this.salesforceLicense.license.license_holder_invites
		];
	}

	public viewSeats (holder) {
		this.viewSeatsEvent.emit(holder.email ?? holder.user.email);
	}
}
