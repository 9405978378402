import {
	Component, ElementRef, OnDestroy, OnInit
} from '@angular/core';

@Component({
	selector: 'card-container',
	templateUrl: './card-container.component.html',
	styleUrls: ['./card-container.component.scss']
})
export class CardContainerComponent implements OnInit, OnDestroy {
	public resizeObserver: ResizeObserver;

	constructor (
		private elementRef: ElementRef
	) {}

	public ngOnInit () {
		this.observeSize();
	}

	public ngOnDestroy (): void {
		this.resizeObserver.disconnect();
	}

	private observeSize () {
		const setIconSize = () => {
			const cardMain: HTMLElement = this.elementRef.nativeElement.querySelector('.card-main');
			const icon: HTMLElement = this.elementRef.nativeElement.querySelector('.card-main i');
			if (icon) {
				icon.style.fontSize = `${cardMain.offsetHeight * 0.5}px`;
			}
		};

		this.resizeObserver = new ResizeObserver(setIconSize);
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}
}
