
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { SettingsOptions, SettingsOptionsTranslations } from 'ngx/go-modules/src/enums/settings-options';
import { $stateParamsToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/state-params.upgrade';
import { StateParams } from '@uirouter/angularjs';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { filter } from 'rxjs';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { LinkResult } from 'ngx/go-modules/src/enums';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent implements OnInit {
	public account;
	public settingsOptions: SettingsOptions[] = Object.values(SettingsOptions);
	public settingsOptionsTranslations = SettingsOptionsTranslations;
	public selectedOption = this.settingsOptions[0];
	public environmentVarsService: EnvironmentVarsService;
	private readonly PARTNER_GOREACT = 'GO_REACT';

	constructor (
		private ngxGoToastService: NgxGoToastService,
		@Inject($stateParamsToken) private $stateParams: StateParams,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		private translateService: TranslateService) {}

	public showLink (option) {
		if (option === SettingsOptions.BILLING_HISTORY) {
			if (this.isLTI()) {
				const partners = clientSettings.partners;
				const isGoReactPartner = partners.length ?
					partners.every((value) => value.guid === this.PARTNER_GOREACT) : true;
				return isGoReactPartner;
			}
		}

		if(option !== SettingsOptions.ACTIVITY_DEFAULTS) {
			return true;
		}

		return !!this.account && !this.isLTI();
	}

	public ngOnInit (): void {
		this.environmentVarsService = EnvironmentVarsService.getInstance();

		if (this.$stateParams.tab && this.settingsOptions.includes(this.$stateParams.tab)) {
			this.selectedOption = this.$stateParams.tab;
		}

		const linkSuccessParam = this.$stateParams['link-result'];
		const linkProvider = this.$stateParams.provider;
		if (linkSuccessParam) {
			switch(linkSuccessParam) {
				case LinkResult.SUCCESS:
					this.translateService.get('common_sso-account-successfully-linked', { provider: linkProvider }).subscribe((message) => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.SUCCESS,
							message
						});
					});
					break;
				case LinkResult.ACCOUNT_TAKEN:
					this.translateService.get('common_sso-account-linked-account-taken', { provider: linkProvider }).subscribe((message) => {
						this.ngxGoToastService.createToast({
							type: GoToastStatusType.ERROR,
							message
						});
					});
					break;
				// No default needed since param supplied with no state value
			}
		}

		const accountGroup = this.selectedService.getAccount();
		this.account = accountGroup?.isAccount() ? accountGroup: null;

		this.selectedService.selectedSubject.asObservable()
			.pipe(filter(() => this.selectedService.getAccount()?.isAccount()))
			.subscribe(() => {
				this.account = this.selectedService.getAccount();
			});
	}

	public isLTI () {
		return this.environmentVarsService.environmentIs(ENVIRONMENTS.LTI);
	}
}
