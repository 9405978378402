<div class="modal-dialog compare-products-dialog">
	<div>
		<span class="modal-squiggle"></span>
		<div class="goreact-modal-header">
			<span></span>
			<button type="button" class="tertiary-btn close-icon" (click)="close()" attr.aria-label="{{ 'common_close' | translate }}">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body">
			<product-card *ngFor="let product of products; let last = last"
				[product]="product"
				[actionTranslation]="product.actionTranslation"
				[isDisabled]="!product.hasAction"
				[isLast]="last"
				(action)="selectPackage(product)"></product-card>
		</div>
		<div class="goreact-modal-footer">
			<button class="tertiary-btn close-btn" (click)="close()">{{ 'common_close' | translate }}</button>
		</div>
	</div>
</div>
