<div class="modal-dialog video-details-dialog-container">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<h4 class="modal-title">{{ 'feedback-session-header_video-details' | translate }}
				<span *ngIf="!(isLoading$ | async)">
					{{ 'feedback-session-header_video-details-modal-owner' | translate: {
							'name': video.owner.first_name + ' ' + video.owner.last_name
						}
					}}
				</span>
			</h4>
			<button
				class="close-btn"
				type="button"
				[attr.aria-label]="'common_close' | translate"
				(click)="close()">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body">
			<div class="row text-center spinner loading-spinner" *ngIf="isLoading$ | async">
				<i class="ficon-spinner ficon-4x ficon-spin"></i>
			</div>
			<div class="row" *ngIf="!(isLoading$ | async)">
				<div class="col-md-4">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4>{{ 'feedback-session-header_video-details-modal-media-info' | translate }}</h4>
						</div>
						<div class="panel-body has-media-panel" *ngIf="video.media">
							<div class="row">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-id' | translate }}</div>
								<div class="col-md-9">{{ video.media.media_id }}</div>
							</div>
							<div class="row" *ngIf="video.media.filename">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-filename' | translate }}</div>
								<div class="col-md-9">{{ video.media.filename }}</div>
							</div>
							<div class="row">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-filesize' | translate }}</div>
								<div class="col-md-9">{{ video.media.filesize }} MB</div>
							</div>
							<div class="row">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-status' | translate }}</div>
								<div class="col-md-9">{{ video.media.media_status }}</div>
							</div>
							<div class="row media-download-row" *ngIf="!isYoutube(video.media.media_url)">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-download' | translate }}</div>
								<div class="col-md-9">
									<button (click)="download(video.media.media_id)"
										type="button"
										aria-label="download media"
										class="tertiary-btn download">
										{{ 'feedback-session-header_video-details-modal-media-download' | translate }}
									</button>
								</div>
							</div>
							<div class="row youtube-session" *ngIf="isYoutube(video.media.media_url)">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-youtube-url' | translate }}</div>
								<div class="col-md-9">
									<a type="button"
										aria-label="youtube URL"
										class="tertiary-btn"
										[attr.href]="video.media.media_url"
										target="_blank">
										{{ 'feedback-session-header_video-details-modal-media-open-new-tab' | translate }}
									</a>
								</div>
							</div>
							<div class="row" *ngIf="video.media.encoding">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-encoded-by' | translate }}</div>
								<div class="col-md-9">
									{{video.media.encoding.encoder}}
								</div>
							</div>
							<div class="row" *ngIf="video.media.data">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-misc-data' | translate }}</div>
								<div class="col-md-9">
									<pre>{{ video.media.data }}</pre>
								</div>
							</div>
							<div class="row sync-events-panel" *ngIf="video.media.session.sync_events.length > 0">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-markers' | translate }}</div>
								<div class="col-md-9">
									<pre>{{ video.media.session.sync_events | json }}</pre>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">{{ 'feedback-session-header_video-details-modal-media-actions' | translate }}</div>
								<div class="col-md-9">
									<button type="button"
										aria-label="restore media"
										class="secondary-btn swap-media-btn"
										[disabled]="hasUpload()"
										(click)="restoreMedia(video.media.media_id)">
											<span *ngIf="hasUpload()">{{ 'feedback-session-header_video-details-cant-swap-media' | translate }}</span>
											<span *ngIf="!hasUpload()">{{ 'feedback-session-header_video-details-modal-recover-media' | translate }}</span>
									</button>
								</div>
							</div>
						</div>

						<div class="panel-body no-media-panel" *ngIf="video.media === null">
							<div class="row">
								<div class="col-md-12">
									{{ 'feedback-session-header_video-details-modal-media-no-media-found' | translate }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4>{{ 'feedback-session-header_video-details-modal-media-transcode-results' | translate }}</h4>
						</div>
						<div class="panel-body encoding-panel" *ngIf="video.encoding">
							<div class="row encode-print">
								{{ video.encoding | json }}
							</div>
						</div>
						<div class="panel-body no-encoding-panel" *ngIf="video.encoding === null">
							<div class="row">
								<div class="col-md-12">{{ 'feedback-session-header_video-details-modal-media-no-encode-results' | translate }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="panel panel-default">
						<div class="panel-heading"><h4>
							{{ 'feedback-session-header_video-details-modal-upload-results' | translate }}
						</h4></div>
						<div class="panel-body upload-panel" *ngIf="hasUpload()">
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-id' | translate }}</div>
								<div class="col-md-10">{{ video.upload.media_upload_id }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-original-filename' | translate }}</div>
								<div class="col-md-10">{{ video.upload.filename_original }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-media-filesize' | translate }}</div>
								<div class="col-md-10">{{ 'feedback-session-header_video-details-modal-upload-size' | translate: {'size' : video.upload.filesize} }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-started' | translate }}</div>
								<div class="col-md-10">{{ video.upload.started_at }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-finished' | translate }}</div>
								<div class="col-md-10">{{ video.upload.finished_at }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-duration' | translate }}</div>
								<div class="col-md-10 upload-duration">{{ video.upload.duration }}</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-av-upload-speed' | translate }}</div>
								<div class="col-md-10" *ngIf="video.upload.speed">
									{{
										'feedback-session-header_video-details-modal-upload-av-upload-speed-mb' | translate: {
										'speed': video.upload.speed
										}
									}}
								</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-env-user' | translate }}</div>
								<div class="col-md-10">
									<pre>{{ video.upload.data | json }}</pre>
								</div>
							</div>
							<div class="row">
								<div class="col-md-2">{{ 'feedback-session-header_video-details-modal-upload-parts' | translate }}</div>
								<div class="col-md-10">
									<pre>{{ video.upload.parts | json }}</pre>
								</div>
							</div>
						</div>
						<div class="panel-body no-upload-panel" *ngIf="!hasUpload()">
							<div class="row">
								<div class="col-md-12">{{ 'feedback-session-header_video-details-modal-upload-no-upload-found' | translate }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="goreact-modal-footer">
			<button type="button" class="secondary-btn close-btn" (click)="close()">{{ 'common_close' | translate }}</button>
		</div>
	</div>
</div>
