import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { Subject, takeUntil } from 'rxjs';

export const FORWARD = 1;
export const BACKWARD = -1;

@Component({
	selector: 'ngx-speed-grader',
	templateUrl: './speed-grader.component.html',
	styleUrls: ['./speed-grader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxSpeedGraderComponent implements OnInit, OnDestroy {
	@Input() public sessionList;
	@Input() public currentSessionId;
	public total = 0;
	private componentDestroyed$$ = new Subject();

	constructor (
		private eventService: EventService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>
	) {
	}

	public ngOnInit () {
		this.total = this.getTotal();
		this.eventService.listen(EVENT_NAMES.SESSION_ACTIVE_PAGINATION_FILTER)
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe(() => {
				this.total = this.getTotal();
			});
	}

	public ngOnDestroy () {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public next () {
		this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT, {
			exitData: null,
			callback: () => {
				this.handleChange(this.getNextSessionInDirection(FORWARD));
			}
		});
	}

	public previous () {
		this.eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_REQUEST_EXIT, {
			exitData: null,
			callback: () => {
				this.handleChange(this.getNextSessionInDirection(BACKWARD));
			}
		});
	}

	public getCurrentIndex () {
		return this.sessionList.indexOf(this.currentSessionId);
	}

	public getTotal () {
		return this.getSessionPagination()?.meta.total ?? 1;
	}

	public shouldDisableSpeedGraderButtons () {
		return this.sessionList.length < 2;
	}

	// Checks session list if has loaded paginated sessions
	// Disables prev "<" button if not all has been loaded
	public shouldDisablePreviousBtn () {
		return this.getCurrentIndex() === 0 && this.sessionList.length !== this.getTotal();
	}

	private getNextSessionInDirection (direction) {
		let index = this.getCurrentIndex() + direction;

		if (index < 0) {
			index = this.sessionList.length - 1;
		} else if (index >= this.sessionList.length) {
			index = 0;
		}

		return {
			sessionId: this.sessionList[index],
			direction
		};
	}

	private handleChange (changeData) {
		this.eventService.broadcast(EVENT_NAMES.SPEED_GRADER_CHANGE, {
			newSessionId: changeData.sessionId,
			direction: changeData.direction
		});
	}

	private getSessionPagination () {
		const filter = this.Session.getActivePaginationFilter();
		return this.Session.getPaginationByFilter(filter);
	}
}
