<div class="ngx-dialog goreact-modal">
	<div class="goreact-modal-header">
		<h4 class="modal-title">{{ 'unlink-sso-dialog_title' | translate:{provider: data.authProvider.open_id_configuration.display_name} }}</h4>
		<button type="button" class="close-btn" (click)="cancel()" [attr.aria-label]="'common_cancel'| translate">
			<i class="ficon-app-x"></i>
		</button>
	</div>
	<div class="goreact-modal-body">
		<div>
			<p>{{ 'unlink-sso-dialog_use-password' | translate:{provider: data.authProvider.open_id_configuration.display_name} }}</p>
			<p>{{ 'unlink-sso-dialog_reconnect' | translate:{provider: data.authProvider.open_id_configuration.display_name} }}</p>
			<mat-error *ngIf="error$ | async">
				{{ 'unlink-sso-dialog_error' | translate:{provider: data.authProvider.open_id_configuration.display_name} }}
			</mat-error>
		</div>
	</div>
	<div class="goreact-modal-footer">
		<button
			class="cancel-button tertiary-btn"
			(click)="cancel()"
			[attr.aria-label]="'common_cancel'| translate"
		>
			{{'common_cancel' | translate}}
		</button>

		<button
			class="primary-btn confirm-button unlink-btn "
			(click)="confirm()"
			[disabled]="loading$ | async"
			[attr.aria-label]="'unlink-sso-dialog_unlink'| translate"
		>
			<span *ngIf="loading$ | async; else notLoading" class="ficon-spinner ficon-spin"></span>
			<ng-template #notLoading>{{'unlink-sso-dialog_unlink' | translate}}</ng-template>
		</button>
	</div>
</div>
