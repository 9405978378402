<form class="user-details" [formGroup]="form" (ngSubmit)="saveUser(form)">
	<div class="content">
		<span class="content-header" translate="modal-edit-user_user-details"></span>

		<section class="content-body">
			<mat-form-field appearance="outline">
				<mat-label id="first-name-label" translate="modal-edit-user_first-name"></mat-label>
				<input
					type="text"
					matInput
					id="userFirstName"
					formControlName="userFirstName"
					data-cy="edit-user-form-first-name"
					class="fullstory-ignore form-control"
					aria-labelledby="first-name-label"
					autocomplete="given-name"
					[readonly]="isEnvironmentLTI()"/>
				<mat-error *ngIf="form.controls.userFirstName.hasError('required')" data-cy="edit-user-form-first-name-invalid">{{'common-validation_first-name-required' | translate}}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label id="last-name-label" translate="modal-edit-user_last-name"></mat-label>
				<input
					type="text"
					matInput
					id="userLastName"
					formControlName="userLastName"
					data-cy="edit-user-form-last-name"
					class="fullstory-ignore form-control"
					aria-labelledby="last-name-label"
					autocomplete="family-name"
					[readonly]="isEnvironmentLTI()"/>
				<mat-error *ngIf="form.controls.userLastName.hasError('required')" data-cy="edit-user-form-last-name-invalid">{{'common-validation_last-name-required' | translate}}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="currentLanguage">
				<mat-label translate="modal-edit-user_language"></mat-label>
				<mat-select
					formControlName="language"
					disableOptionCentering
					panelClass="offsetPanel">
					<mat-option
						*ngFor="let language of languages"
						[value]="language">
						{{ language.name }}
					</mat-option>
				</mat-select>
				<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
			</mat-form-field>

			<div class="group-wrapper email-group">
				<span>
					<mat-form-field class="no-line">
						<mat-label id="email-label" translate="common_email"></mat-label>
						<input
							type="text"
							matInput
							id="userEmail"
							formControlName="userEmail"
							data-cy="edit-user-form-email"
							class="fullstory-ignore form-control"
							aria-labelledby="email-label"
							readonly/>
					</mat-form-field>
				</span>
				<span class="change-email-btn-container" *ngIf="!isEnvironmentLTI()">
					<button
						*ngIf="hasAuthProviderGoReact() && !hasSsoProvider()"
						class="secondary-btn change-email-btn"
						type="button"
						(click)="changeEmail()"
						translate="modal-edit-user_change-email">
					</button>
				</span>
			</div>

			<ng-container *ngIf="!isEnvironmentLTI()">
				<div class="group-wrapper password-group" *ngIf="hasAuthProviderGoReact()">
					<span>
						<mat-form-field class="no-line">
							<mat-label id="password-label" translate="common_password"></mat-label>
							<input
								type="password"
								matInput
								class="fullstory-ignore form-control"
								aria-labelledby="password-label"
								value="********"
								readonly/>
						</mat-form-field>
					</span>
					<span>
						<button
							class="secondary-btn change-password-btn"
							type="button"
							(click)="changePassword()"
							translate="modal-edit-user_change-password">
						</button>
					</span>
				</div>
			</ng-container>

			<div class="sso-section" *ngIf="!isEnvironmentLTI()">
				<span class="group-wrapper unlink-group" *ngIf="hasSsoProvider()">
					<span class="content-header" translate="sso_sso-details"></span>
					<div class="linked-providers" *ngFor="let provider of ssoProviders">
						<div class="sso-account-linked" [ngClass]="provider.provider_name">
							<span>
								<img class="provider-logo" [src]="provider.open_id_configuration.logo_url" [alt]="provider.open_id_configuration.display_name + ' logo'">
							</span>
							<span translate="common_sso-account-linked" [translateParams]="{provider: provider.open_id_configuration.display_name}"></span>
							<span *ngIf="!this.masquerade.isMasked()">
								<button
										class="secondary-btn unlink-btn"
										type="button"
										(click)="unlinkSsoAccount(provider)"
										translate="unlink-sso-dialog_unlink">
								</button>
							</span>
						</div>
					</div>
				</span>
				<div class="group-wrapper link-new-providers" *ngIf="ssoProviders && !this.masquerade.isMasked()">
					<span class="content-header" translate="sso_link-providers"></span>
					<sso-provider-button
						[excludedProviders]="ssoProviders"
						(onLoginWithSso)="connectSsoAccount($event)">
					</sso-provider-button>
				</div>
			</div>
		</section>
	</div>

	<div class="content-footer" *ngIf="shouldShowSaveButton$ | async">
		<button
			[disabled]="isSaving$ | async"
			class="primary-btn submit-btn"
			type="submit"
			translate="common_save-changes">
		</button>
	</div>
</form>
