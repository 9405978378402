import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Create script tag to load settings.js in each environment
const settingsTag = document.createElement('script');
settingsTag.setAttribute('src', environment.featureFlagUrl);
settingsTag.addEventListener('load', () => {
	import(/* webpackMode: "eager" */ './app/app.module').then((file) => {

		if (environment.production) {
			enableProdMode();
		}

		platformBrowserDynamic()
			.bootstrapModule(file.AppModule)
			.catch((err) => console.error(err));
	});
});

document.head.appendChild(settingsTag);
