import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	HostListener,
	Inject,
	InjectionToken,
	OnDestroy,
	OnInit,
	SimpleChange,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { GoAnchorDirective } from 'ngx/go-modules/src/directives/go-anchor/go-anchor.directive';
import { FocusableElementsUtil } from 'ngx/go-modules/src/utilities';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import type { GoSidepanelData } from '../interfaces/go-sidepanel-data';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

export const GO_SIDEPANEL_DATA = new InjectionToken<GoSidepanelData>('GO_SIDEPANEL_DATA');

@Component({
	selector: 'go-sidepanel',
	templateUrl: './go-sidepanel.component.html',
	styleUrls: ['./go-sidepanel.component.scss']
})
export class GoSidepanelComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('main', {static: true})
	public main: ElementRef;
	@ViewChild(GoAnchorDirective, {static: true})
	public goAnchor: GoAnchorDirective;

	public content: Component;
	private componentRef: ComponentRef<any>;

	constructor (
		@Inject(GO_SIDEPANEL_DATA) public sidepanelData: GoSidepanelData,
		@Inject(GoDialogRef) public dialogRef: GoDialogRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		public cdr: ChangeDetectorRef
	) {
		this.content = sidepanelData.content;
	}

	@HostListener('keydown', ['$event'])
	public onKeyDown ($event: KeyboardEvent) {
		if($event.key === 'Escape' && this.sidepanelData.options.closeOnEscape) {
			$event.preventDefault();
			$event.stopPropagation();
			this.dialogRef.close();
		}
	}

	public ngOnInit () {
		this.loadComponent();
	}

	public ngOnDestroy () {
		this.componentRef?.destroy();
	}

	public loadComponent () {
		const component = this.content as any;
		const cFactory = this.componentFactoryResolver.resolveComponentFactory<any>(component);
		const viewRef =  this.goAnchor.viewContainerRef;
		viewRef.clear();
		this.componentRef = viewRef.createComponent<any>(cFactory);
		this.cdr.detectChanges();

		// Set component inputs value
		const inputs = this.sidepanelData.options.inputs;

		if(inputs) {
			const keys = Object.keys(inputs);
			const simpleChanges: SimpleChanges = {};

			keys.forEach((key) => {
				simpleChanges[key] = new SimpleChange(
					this.componentRef.instance[key],
					inputs[key],
					this.componentRef.instance[key]
				);
				this.componentRef.instance[key] = inputs[key];
			});

			// We need to trigger ngOnchanges manually because it only trigger when
			// component instantiated in the template
			if (this.componentRef.instance.ngOnChanges) {
				this.componentRef.instance.ngOnChanges(simpleChanges);
			}
		}
	}

	public close () {
		this.dialogRef.close();
	}

	public ngAfterViewInit (): void {
		// If no active element found focus on main element instead
		const firstFocusableElement = FocusableElementsUtil.getFirstFocusableElement(this.main.nativeElement) ??
			this.main.nativeElement;

		of(firstFocusableElement).pipe(take(1), delay(1)).subscribe((el: HTMLMapElement) => el.focus());
	}

	// When tabbing exitted last focusable element
	// We want move focus back to the main element
	// But this will focus the first focusable element inside #main element
	public onTabOut	() {
		this.main.nativeElement.focus();
	}
}
