<div class="goreact-modal rate-limit-dialog">
	<div class="goreact-modal-header">
		<h4 class="modal-title" translate="rate-limit-dialog_title"></h4>
	</div>
	<div class="goreact-modal-body" cdkTrapFocus>
		<img class="graphic" src="https://staticassets.goreact.com/upside-down-astronaut.svg" alt="Too many requests">
		<p
			class="text"
			[innerHTML]="'rate-limit-dialog_message' | translate:
				{ 'time': countdown$ | async}">
		</p>
	</div>
</div>
