import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface ConfirmDialogData {
	title: string;
	message: string;
	note?: string;
	cancelText?: string;
	confirmText?: string;
	confirmStyle?: string;
	warningNote?: boolean;
}
export interface ConfirmDialogConfig {
	disableClose?: boolean;
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
	public constructor (
		public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
	) {}

	// A small helper to open the dialog with all the correct typing
	public static open (
		dialog: MatDialog,
		config?: MatDialogConfig<ConfirmDialogData>
	): MatDialogRef<ConfirmDialogComponent, boolean> {
		return dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, config);
	}

	public close () {
		this.dialogRef.close(null);
	}

	public cancel () {
		this.dialogRef.close(false);
	}

	public confirm () {
		this.dialogRef.close(true);
	}
}
