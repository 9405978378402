<span [ngClass]="{ 'marker-pill': withName }">
	<!-- show button if can click -->
	<button
		*ngIf="showButton; else nonClick"
		class="marker-icon clickable"
		[attr.aria-label]="getLabel(marker)"
		[goTooltip]="marker.tag_name"
		goTooltipPlacement="top"
		[disabled]="disabled"
		(click)="select.emit()"
		[ngStyle]="{
			'color': bestContrast(),
			'background-color': '#' + marker.tag_color
		}">
		<span>{{marker.tag_abbreviation ? marker.tag_abbreviation : marker.tag_name | twoLetters}}</span>
	</button>
	<ng-template #nonClick>
		<span
			class="marker-icon"
			[attr.aria-label]="getLabel(marker)"
			[goTooltip]="marker.tag_name"
			goTooltipPlacement="top"
			[ngStyle]="{
				'color': aiStyle ? '#000000' : bestContrast(),
				'background-color': aiStyle ? '#ffffff' : '#' + marker.tag_color,
				'border-color': aiStyle ? '#' + marker.tag_color : 'transparent'
			}">
			<span>{{marker.tag_abbreviation ? marker.tag_abbreviation : marker.tag_name | twoLetters}}</span>
		</span>
	</ng-template>
	<span *ngIf="withName" class="marker-name">{{marker.tag_name}}</span>
	<button
		*ngIf="showRemove"
		class="remove-tag"
		(click)="remove.emit()"
		attr.aria-label="{{'feedback-creator_tag-remove' | translate}}">
		<i class="ficon-app-x"></i>
	</button>
</span>
