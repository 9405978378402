<div class="name-list">
	<div class="names-container">
		<span class="name" *ngFor="let name of nameList">
			{{name}}
		</span>
	</div>
	<div class="overflow-count" *ngIf="hasOverflown">
		<span>+ {{ overflowCount }}</span>
	</div>
</div>
