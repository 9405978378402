<div class="ngx-dialog goreact-modal">
	<div class="goreact-modal-header">
		<h4 class="modal-title">
			{{ data.title }}
		</h4>
		<button type="button" class="close-btn" (click)="close()" [attr.aria-label]="'common_close' | translate">
			<i class="ficon-app-x"></i>
		</button>
	</div>
	<div class="goreact-modal-body">
		<p [innerHTML]="data.message"></p>
		<div *ngFor="let email of data.emails">{{email}}</div>
		<p *ngIf="data.additionalMessage" class="additional-message" [innerHTML]="data.additionalMessage"></p>
	</div>
	<div class="goreact-modal-footer">
		<button
			class="confirm-button primary-btn"
			(click)="close()"
			translate="common_close">
		</button>
	</div>
</div>
