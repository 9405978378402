<session-analytic-item-view
	(onExpanded)="onExpanded.emit($event)"
	[isExpanded]="isExpanded"
	title="{{'session-analytics_feedback-graph' | translate}}"
	[dataSummary]="feedbackSummary">
	<div graph
		class="feedback-graph-container"
		role="application">
			<ng-container *ngIf="this.commentSeriesData.length || markerSeriesData.length; else zeroState">
				<highcharts-chart
					#feedbackChart
					style="height: 200px; width: 100%;"
					[Highcharts]="Highcharts"
					[options]="chartOptions"
				></highcharts-chart>
				<marker-display *ngIf="markerSeriesData.length > 0" [markers]="markersWithCount" [withCount]="true"></marker-display>
			</ng-container>

			<ng-template #zeroState>
				<analytic-graph-zero-state>
					{{'feedback-graph_zero-state' | translate}}
				</analytic-graph-zero-state>
			</ng-template>
		</div>
</session-analytic-item-view>
