<div class="go-sidepanel" cdkTrapFocus goMonitorTabEvent (onTabbedOut)="onTabOut()" tabindex="-1" #main>
	<div *ngIf="sidepanelData.options.title" class="go-sidepanel-header">
		<h1>{{sidepanelData.options.title}}</h1>
		<button type="button" class="close-btn btn-none" (click)="close()" [attr.aria-label]="'common_close' | translate">
			<i class="ficon-app-x"></i>
		</button>
	</div>

	<div class="go-sidepanel-body">
		<div class="go-sidepanel-content">
			<ng-template goAnchor></ng-template>
		</div>
	</div>

	<div *ngIf="!sidepanelData.options.noFooter" class="go-sidepanel-footer">
		<button class="primary-btn close-btn" (click)="close()" translate="common_close"></button>
	</div>
</div>
