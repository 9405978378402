<button
	type="button"
	class="btn-none open-menu-btn"
	[ngStyle]="{
		color: selectedColor.hex && bestContrast('#' + selectedColor.hex),
		'background-color': '#' + selectedColor.hex
	}"
	[ngClass]="style"
	[matMenuTriggerFor]="colorMenu"
	[attr.aria-label]="'tag-editor_change-color' | translate: {
		abbreviation: abbreviation ?? '',
		color: selectedColor.translation | translate
	}">
	{{ style === "tag-icon" && abbreviation ? abbreviation : "" }}
</button>
<mat-menu class="color-grid" #colorMenu="matMenu" yPosition="below">
	<ng-container *ngFor="let color of colors; let i = index">
		<!-- have to set background color on both so we can inherit it on hover -->
		<span class="color-wrapper" [style.background-color]="'#' + color.hex">
			<button
				mat-menu-item
				[attr.id]="'tag-color-option-' + color.hex"
				class="color-option"
				[style.background-color]="'#' + color.hex"
				(click)="select(color)"
				[attr.aria-selected]="color === selectedColor"
				[attr.aria-label]="getAriaLabel(color, i)"
				[matTooltip]="color.translation | translate"
			></button>
			<!-- TODO add [cdkFocusInitial]="color === selectedColor" when upgrading to angular material 13-->
		</span>
	</ng-container>
</mat-menu>
