<div class="thumbnail-wrapper">
	<img
		[src]="src"
		[alt]="'media-thumbnail_media-thumbnail' | translate"
		(load)="isLoaded = true"
		class="has-thumbnail"
		[attr.crossorigin]="determineCrossOriginValue()"
		*ngIf="!shouldShowFallbackIcon(); else icon"
	/>

	<ng-template #icon>
		<i class="{{fallbackIcon}} no-thumbnail"></i>
	</ng-template>

	<div *ngIf="isPractice" class="practice-badge">
		<span class="draft-label" translate="common_draft"></span>
	</div>

	<div *ngIf="duration > 0" class="duration">
		<span class="cover"></span>
		<span class="text">{{duration | formatTime}}</span>
	</div>
</div>
