import { Highlightable } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { GoDropdownComponent } from './go-dropdown.component';
import { GoDropdownService } from './go-dropdown.service';

@Component({
	selector: 'go-dropdown-option',
	templateUrl: './go-dropdown-option.component.html',
	styleUrls: ['./go-dropdown.component.scss']
})
export class GoDropdownOptionComponent implements Highlightable {
	@Input() public key: string;
	@Input() public name: string;
	@Input() public description: string | null;

	@HostBinding('class.selected')
	public get selected (): boolean {
		return this.select.selectedOption === this;
	}

	@HostBinding('class.active')
	public active = false;

	private select: GoDropdownComponent;

	constructor (
		private dropdownService: GoDropdownService
	) {
		this.select = this.dropdownService.getSelect();
	}

	@HostListener ('click', ['$event'])
	public onClick (event: PointerEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.select.selectOption(this);
	}

	// invoked by ActiveDescendantKeyManager in go-dropdown.component
	public setActiveStyles (): void {
		this.active = true;
	}

	public setInactiveStyles (): void {
		this.active = false;
	}
}
