<div class="modal-dialog" data-cy="rename-folder-modal">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<h4 class="modal-title" translate="{{getTitleTranslationKey()}}"></h4>
			<button
				class="close-btn"
				type="button"
				[attr.aria-label]="('common_close' | translate)"
				(click)="dismiss()">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<div class="goreact-modal-body">
			<div class="form-div" [formGroup]="form" #content>
				<mat-form-field *ngIf="data.createMode && data.collections?.length > 1" id="select-collection" appearance="outline">
					<mat-label translate="add-collection-folder_select_collection-label"></mat-label>
					<mat-select
						formControlName="selectedCollection"
						(selectionChange)="loadFolders()"
						disableOptionCentering
						panelClass="offsetPanel"
						required>
						<mat-option
							*ngFor="let collection of data.collections"
							[value]="collection">
							{{ collection.name }}
						</mat-option>
					</mat-select>
					<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
					<mat-error
						*ngIf="form.controls.selectedCollection.hasError('required')"
						translate="collection-destination_assignment_name-error-required">
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label translate="add-collection-folder_name-label"></mat-label>
					<input
						matInput
						formControlName="name"
						data-cy="folderName"
						[placeholder]="getPlaceholderKey() | translate"
						maxlength="191"/>
					<mat-error
						*ngIf="form.controls.name.hasError('required')"
						translate="add-collection-folder_required">
					</mat-error>
					<mat-error
						*ngIf="form.controls.name.hasError('maxlength')"
						translate="add-collection-folder_maxlength">
					</mat-error>
					<mat-error
						*ngIf="form.controls.name.hasError('duplicate')"
						translate="add-collection-folder_unique-name">
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="goreact-modal-footer">
			<button
				type="button"
				wcag-ignore
				(click)="dismiss()"
				translate="common_cancel"
				class="goreact-btn tertiary-btn cancel-btn">
			</button>
			<button
				type="submit"
				data-cy="rename-folder"
				wcag-ignore
				class="goreact-btn primary-btn"
				[focusOnFirstInvalidField]="{formGroup: form, formWrapper: content}"
				(click)="submit(form)"
				translate="{{getButtonName()}}">
			</button>
		</div>
	</div>
</div>
