import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnChanges, OnDestroy {
	@ViewChild('textInput')
	public textInput: ElementRef;

	@Input()
	public ariaLabel: string;

	@Input()
	public viewByUser: string;

	@Input()
	public ariaLabelledBy: string;

	@Output()
	public updateEvent: EventEmitter<string> = new EventEmitter();

	public searchText: string = '';
	public searchTextUpdated: Subject<string> = new Subject<string>();
	private searchTextUpdatedSubscription: Subscription;

	constructor () {
		this.searchTextUpdatedSubscription = this.searchTextUpdated.pipe(
			debounceTime(200),
			distinctUntilChanged()
		).subscribe((text) => {
			this.searchText = text;
			this.updateEvent.emit(this.searchText);
		});
	}

	public ngOnChanges (): void {
		if (this.viewByUser) {
			this.changed(this.viewByUser);
		}
	}

	public ngOnDestroy () {
		this.searchTextUpdatedSubscription.unsubscribe();
	}

	public changed (text: string) {
		this.searchTextUpdated.next(text);
	}

	public clearText () {
		this.searchTextUpdated.next('');
		this.textInput.nativeElement.focus();
	}
}
