import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { delay, interval, take } from 'rxjs';
import { NgxCommentService } from 'ngx/go-modules/src/services/comment/comment.service';
import { CommentReaction } from 'ngx/go-modules/src/interfaces/comments/comment';

export enum MODES {
	DISPLAY = 'display',
	ADD = 'add',
	FEEDBACK = 'feedback'
};

interface Reaction {
	emoji: string;
	count: number;
	reactors: string[];
	i_reacted: boolean;
};

@Component({
	selector: 'ngx-comment-reactions',
	templateUrl: './comment-reactions.component.html',
	styleUrls: ['./comment-reactions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxCommentReactionsComponent implements OnInit {
	@Input() public commentId: number;
	@Input() public reactions: CommentReaction[];
	@Output() public reactionsChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() public mode: MODES;
	@ViewChild(MatMenuTrigger) private emojiMenuTrigger: MatMenuTrigger;
	public uniqueReactions: Reaction[] = [];
	public modes = MODES;

	constructor (
		private cdr: ChangeDetectorRef,
		private commentService: NgxCommentService,
		private translate: TranslateService,
		@Inject(userServiceToken) private userService: UserService
	) {}

	public ngOnInit () {
		if (this.reactions || this.mode === MODES.FEEDBACK) {
			this.setUniqueReactions(this.reactions);
		}
	}

	public ngOnChanges (changes: SimpleChanges): void {
		if ('reactions' in changes && changes.reactions.currentValue != null) {
			this.setUniqueReactions(changes.reactions.currentValue);
		}
	}

	public focusSearchField (event) {
		event.stopPropagation();
		interval(100)
			.pipe(
				take(1),
				delay(1)
			).subscribe(() => {
				const searchField: HTMLElement = document.querySelector('div.emoji-mart-search input[type="search"]');
				searchField?.focus();
			});
	}

	// TODO simplify this with ngx-translate pluralization STAB-578
	public getTooltip (reaction) {
		if (reaction.i_reacted) {
			if (reaction.reactors.length > 1) {
				const names = [...reaction.reactors];
				const lastName = names.pop();
				return this.translate.instant('feedback-panel-you-and-many-other-reactors', {
					names: names.join(', '),
					lastName,
					emoji: reaction.emoji
				});
			} else if (reaction.reactors.length === 1) {
				return this.translate.instant('feedback-panel-you-and-one-other-reactor', {
					name: reaction.reactors[0],
					emoji: reaction.emoji
				});
			} else {
				return this.translate.instant('feedback-panel-just-you-reactor', {
					emoji: reaction.emoji
				});
			}
		} else {
			if (reaction.reactors.length > 2) {
				const names = [...reaction.reactors];
				const lastName = names.pop();
				return this.translate.instant('feedback-panel-many-other-reactors', {
					names: names.join(', '),
					lastName,
					emoji: reaction.emoji
				});
			} else if (reaction.reactors.length === 2) {
				return this.translate.instant('feedback-panel-two-other-reactors', {
					nameOne: reaction.reactors[0],
					nameTwo: reaction.reactors[1],
					emoji: reaction.emoji
				});
			} else {
				if (this.mode === MODES.FEEDBACK && reaction.reactors.length === 0) {
					if (reaction.emoji === '👍') {
						return this.translate.instant('feedback-panel-ai-feedback-good');
					}
					return this.translate.instant('feedback-panel-ai-feedback-bad');
				}
				return this.translate.instant('feedback-panel-one-other-reactor', {
					name: reaction.reactors[0],
					emoji: reaction.emoji
				});
			}
		}
	}

	public toggleReaction (event, emoji) {
		event.stopPropagation();
		const alreadyAdded = this.checkIfAlreadyAdded(emoji);
		if (alreadyAdded) {
			this.removeReaction(emoji, alreadyAdded.id);
		} else {
			this.addEmoji(emoji);
		}
	}

	public addReaction (event) {
		if (this.checkIfAlreadyAdded(event.emoji.native)) {
			return this.emojiMenuTrigger.closeMenu();
		} else {
			this.addEmoji(event.emoji.native);
			this.emojiMenuTrigger.closeMenu();
		}
	}

	private removeReaction (emoji, reactionId) {
		this.commentService.deleteReaction(this.commentId, reactionId)
			.subscribe(() => {
				const uniqueReaction = this.uniqueReactions.find(((reaction) => reaction.emoji === emoji));
				uniqueReaction.count--;
				if (uniqueReaction.count === 0 && this.mode !== MODES.FEEDBACK) {
					const uniqueIndex = this.uniqueReactions.findIndex(((reaction) => reaction.emoji === emoji));
					this.uniqueReactions.splice(uniqueIndex, 1);
				} else {
					uniqueReaction.i_reacted = false;
				}
				const reactionIndex = this.reactions.findIndex(((reaction) => {
					return reaction.emoji === emoji &&
						reaction.created_by_user_id === this.userService.currentUser.user_id;
				}));
				this.reactions.splice(reactionIndex, 1);
				if (!this.reactions.length) {
					return this.reactionsChange.emit(this.reactions);
				}
				this.cdr.detectChanges();
			});
	}

	private addEmoji (emoji) {
		this.commentService.addReaction(this.commentId, {emoji})
			.subscribe((res: CommentReaction) => {
				if (!this.reactions) {
					this.reactions = [res];
				} else {
					this.reactions.push(res);
				}
				if (this.mode === MODES.ADD) {
					this.reactionsChange.emit(this.reactions);
				} else {
					const objIndex = this.uniqueReactions.findIndex(((reaction) => reaction.emoji === res.emoji));
					if (objIndex >= 0) {
						this.uniqueReactions[objIndex].count++;
						this.uniqueReactions[objIndex].i_reacted = true;
					} else {
						this.uniqueReactions.push({
							emoji: res.emoji,
							count: 1,
							reactors: [],
							i_reacted: true
						});
					}
					this.cdr.detectChanges();
				}
			});
	}

	private checkIfAlreadyAdded (emoji) {
		if (!this.reactions) {
			return null;
		}
		return this.reactions.find((reaction) => {
			return reaction.emoji === emoji && reaction.created_by_user_id === this.userService.currentUser.user_id;
		});
	}

	private setUniqueReactions (reactions) {
		this.uniqueReactions = Object.values(reactions.reduce((obj, item) => {
			obj[item.emoji] = obj[item.emoji] || { emoji: item.emoji, count: 0, reactors: [], i_reacted: false };
			obj[item.emoji].count++;
			if (item.created_by_user_id === this.userService.currentUser.user_id) {
				obj[item.emoji].i_reacted = true;
			} else {
				obj[item.emoji].reactors.push(item.created_by_user_name);
			}
			return obj;
		}, {}));

		if (this.mode === MODES.FEEDBACK) {
			const thumbsUpExists = this.uniqueReactions.some((reaction) => reaction.emoji === '👍');
			if (!thumbsUpExists) this.uniqueReactions.push({emoji: '👍', count: 0, reactors: [], i_reacted: false});
			const thumbsDownExists = this.uniqueReactions.some((reaction) => reaction.emoji === '👎');
			if (!thumbsDownExists) this.uniqueReactions.push({emoji: '👎', count: 0, reactors: [], i_reacted: false});
		}
	}
}
