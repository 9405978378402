import {
	ChangeDetectionStrategy,
	Component,
	Input
} from '@angular/core';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';

@Component({
	selector: 'marker-display',
	templateUrl: './marker-display.component.html',
	styleUrls: ['./marker-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerDisplayComponent {
	@Input() public markers: Marker[];
	@Input() public withCount: boolean = false;
	@Input() public aiStyle: boolean = false;

	constructor () {}
}
