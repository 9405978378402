import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoStorageService } from 'ngx/go-modules/src/services/storage/storage.service';
import { tap } from 'rxjs/operators';
import { environment } from '@video-sharing/environments/environment';

export const AUTH_TOKEN_STORAGE_KEY = 'goreact-token';
export const LTI_AUTH_TOKEN_STORAGE_KEY = 'goreact-lti-app-token';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

	constructor (private storage: GoStorageService) {
	}

	public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.includes(environment.apiBaseUrl)) {
			// Only add credentials on goreact requests
			req = req.clone({
				setHeaders: {
					'Content-Type': 'application/json'
				},
				withCredentials: true
			});
		}

		return next.handle(req).pipe(tap((event) => {
			if (event instanceof HttpResponse) {
				if (event.body?.access_token) {
					this.storage.setItem(AUTH_TOKEN_STORAGE_KEY, event.body.access_token);
				}
			}
		}));
	}
}
