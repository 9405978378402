<div class="dropdown-wrapper" #dropdownComp>
	<button
		type="button"
		(click)="toggleDropdown()"
		(keydown)="onKeyDown($event)"
		class="dropdown-toggle-btn">
		{{selectedOption ? selectedOption[textKey] : placeholder}}
		<span class="dropdown-arrow"><i class="ficon-app-chevron-down"></i></span>
	</button>
	<go-dropdown-dropdown [dropdownComp]="dropdownComp">
		<div class="dropdown-content" *ngIf="!groupKey">
			<go-dropdown-option
				*ngFor="let item of items; let i = index"
				[ngClass]="{'single': !descriptionKey, 'double': descriptionKey}"
				[name]="item[textKey]"
				[key]="i.toString()"
				[description]="item[descriptionKey]">
			</go-dropdown-option>
		</div>
		<div class="dropdown-content" *ngIf="groupKey">
			<span *ngFor="let group of groups" class="grouped-items">
				<span class="group-header">{{group}}</span>
				<go-dropdown-option
					*ngFor="let item of groupedItems[group]; let i = index"
					[ngClass]="{'single': !descriptionKey, 'double': descriptionKey}"
					[name]="item[textKey]"
					[key]="group + i"
					[description]="item[descriptionKey]">
				</go-dropdown-option>
			</span>
		</div>
	</go-dropdown-dropdown>
</div>
