import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { delay, interval, take, tap } from 'rxjs';

@Component({
	selector: 'menu',
	templateUrl: 'menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
	@ViewChild('menuTrigger') public menuTrigger;
	@ViewChild(MatMenuTrigger) public contextMenu: MatMenuTrigger;

	public contextMenuPosition = {
		x: 0, y: 0
	};

	constructor (private cdr: ChangeDetectorRef) {}

	public openMenu (event: MouseEvent, parentContainer: HTMLElement) {
		event.preventDefault();
		const rect = parentContainer.getBoundingClientRect();
		const left = event.clientX - rect.left;
		const top = event.clientY - rect.top;

		if (left > 0 && top > 0) {
			this.contextMenuPosition.x = left;
			this.contextMenuPosition.y = top;
		} else {
			const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
			this.contextMenuPosition.x = buttonRect.left - rect.left;
			this.contextMenuPosition.y = buttonRect.top - rect.top;
		}

		// Refocus to event target when menu closed
		this.contextMenu.menuClosed.asObservable().pipe(take(1), delay(100)).subscribe(() => {
			(event.target as HTMLElement).focus();
		});

		// avoid glitchy menu open when right clicking again
		// alternatives tried -
		// opening menu in matMenuTrigger menuClosed observer method
		// stopping event propagation
		// changing the contextMenuPosition and just leaving it open
		interval(100)
			.pipe(
				take(1),
				tap(() => {
					// Since we hide this component
					// We need to move the trigger element into the parentContainer
					// So Mat Menu will able to locate the location of the element
					// Reason: hidden element position x/y will be 0
					parentContainer.appendChild(this.menuTrigger.nativeElement);
					this.cdr.detectChanges();
				}),
				delay(1), // open menu on next cycle to make sure menuTrigger rendered in the parentContainer
				tap(() => this.contextMenu.openMenu()),
				delay(1) // After menu opened, focus to the first element
			).subscribe(() => {
				const firstItem: HTMLElement = document.querySelector(`#${this.contextMenu.menu.panelId}`).querySelector('.mat-menu-item');
				firstItem.focus();
			});
	}

	public closeMenu () {
		this.contextMenu.closeMenu();
	}
}
