import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@video-sharing/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		private http: HttpClient
	) {}

	public getSelf (): Observable<any> {
		return this.http.get<any>(`${environment.apiBaseUrl}/users/self`);
	}
}
