import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import type { User } from '../../interfaces/user/user';
import { UserCredentials } from '../../interfaces/user/credentials';
import { environment } from '@video-sharing/environments/environment';
import { UserService } from '@video-sharing/app/common/services/user/user.service';
import { fullstoryToken, FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { zendeskToken, ZendeskService } from 'go-modules/services/zendesk/zendesk.service';
import { AnalyticsUserEvent } from 'go-modules/services/analytics/analytics.interface';
import { from, interval, Subscription, Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';

export const AUTH = {
	ROLE_COOKIE_NAME: 'gr_role',
	AUTH_USER_NAME: 'gr_user',
	EXPIRES_AT_COOKIE_NAME: 'gr_expires_at'
};

export const USER_TYPES = {
	ADMIN: 'admin',
	ADMIN_WITH_BILLING: 'admin_with_billing',
	USER: 'user'
};

interface AuthResponse {
	user: User;
}

export const EXPIRATION_INTERVAL = 60 * 1000;

@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnDestroy {
	public userType: string | null = null;
	public user: User | null = null;
	public highestUserRole: string | null = null;
	public isLti: boolean | null = null;
	public sessionExpiration: Date | null = null;
	public expirationInterval: number | undefined;
	public authenticated = false;
	public expirationTimer: Subscription;

	constructor (
		public router: Router,
		public route: ActivatedRoute,
		private http: HttpClient,
		private userService: UserService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService,
		@Inject(zendeskToken) private zendeskService: ZendeskService,
		@Inject('Window') private window: Window
	) {
		this.getSessionCookies();
		this.authenticated = !!this.userType;
		this.user = this.getUser();

		if (this.isAuthenticated()) {
			this.userService.getSelf().subscribe((user) => {
				this.setUser(user);
				this.startExpirationTimer();
				this.setupAppData();
			});
		}
	}

	public ngOnDestroy () {
		window.clearInterval(this.expirationInterval);
		this.expirationTimer?.unsubscribe();
	}

	public isAuthenticated () {
		return this.authenticated && this.sessionExpiration > new Date();
	}

	public login (credentials: UserCredentials) {
		return this.http
			.post<AuthResponse>(`${environment.apiBaseUrl}/users:login`, credentials)
			.pipe(
				tap(response => {
					this.setUser(response.user);
					this.authenticated = true;
					this.startExpirationTimer();
					this.getSessionCookies();
				})
			);
	}

	/**
	 * When creating an account on video-share, the user should already be authenticated when
	 * redirecting back from SSO
	 */
	public authenticateAndRedirectUserFromSso() {
		return this.userService.getSelf().subscribe((user) => {
			this.setUser(user);
			this.authenticated = true;
			this.startExpirationTimer();
			this.getSessionCookies();
			this.router.navigate(['/sessions', this.route.snapshot.queryParamMap.get('session')]);
		});
	}

	public logout (): Observable<void> {
		return this.http.post<void>(`${environment.apiBaseUrl}/users:logout`, null)
			.pipe(
				tap(() => {
					this.authenticated = false;
					this.userType = null;
					this.clearUser();
				}
				));
	}

	public getCookie (name: string): string | null {
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if (match) {
			return match[2];
		}
		return null;
	}

	public removeCookie (name: string): void {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	}

	public getSessionCookies () {
		this.userType = this.getCookie(AUTH.ROLE_COOKIE_NAME);
		const expiresAtCookie = this.getCookie(AUTH.EXPIRES_AT_COOKIE_NAME);
		if (expiresAtCookie) {
			const tempDateString = decodeURIComponent(expiresAtCookie);
			this.sessionExpiration = new Date(tempDateString);
		}
	}

	public setUser (user: User) {
		this.user = user;
		this.storeUser(this.user);
		this.pushGoogleAnalyticsUser(this.user);
		this.setupAppData();
	}

	public startExpirationTimer () {
		this.expirationTimer?.unsubscribe();
		this.expirationTimer = interval(EXPIRATION_INTERVAL)
			.pipe(first())
			.pipe(filter(() => this.sessionExpiration && this.sessionExpiration <= new Date()))
			.pipe(switchMap(() => from(this.logout())))
			.subscribe(() => {
				this.router.navigate(['/login'], {
					queryParams: { session: this.route.firstChild.snapshot.paramMap.get('uuid') }
				});
			});
	}

	public getUser (): User | null {
		const userStr = sessionStorage.getItem(AUTH.AUTH_USER_NAME);
		return userStr ? (JSON.parse(userStr) as User) : null;
	}

	private clearUser () {
		sessionStorage.removeItem(AUTH.AUTH_USER_NAME);
		this.clearAppData();
	}

	private storeUser (user: User) {
		sessionStorage.setItem(AUTH.AUTH_USER_NAME, JSON.stringify(user));
	}

	private pushGoogleAnalyticsUser (user: User) {
		try {
			if (this.getCookie('is_self_demo_experiment') === 'true') return;
			this.window.dataLayer.push({ user_id: String(user.user_id), event: AnalyticsUserEvent.UserAuthenticated });
		} catch (err) {
			console.error(err);
		}
	}

	private setupAppData () {
		this.fullstoryService.configure(this.user);
		this.zendeskService.configure(this.user);
	}

	private clearAppData () {
		this.fullstoryService.clearUser();
		this.zendeskService.reset();
	}
}
