<div class="markers-container">
	<div *ngFor="let marker of markers" class="marker-container">
		<marker
			[aiStyle]="aiStyle ? aiStyle : marker.is_ai_marker"
			[marker]="marker"
			[withCount]="withCount"
			[ngClass]="{'ai_marker': aiStyle || marker.is_ai_marker}">
		</marker>
		<span *ngIf="withCount">
			{{marker.count}}
		</span>
	</div>
</div>
