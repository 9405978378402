<table class="license-holder-table" *ngIf="{canEdit: canAdministerLicense()} as permission">
	<thead>
		<tr>
			<th class="cell-with-padding-left" translate="license-management_licensed-users"></th>
			<th class="license-permission-th" translate="license-management_permissions"></th>
			<th translate="license-management_date-invited"></th>
			<th translate="license-management-date-accepted"></th>
			<th>
				{{ 'license-management_seat-used' | translate}}
				<span
					[goTooltip]="'license-management_instructor-seat-used-tooltip' | translate"
					goTooltipPlacement="bottom|left"
					class="ficon-help">
				</span>
			</th>
			<th class="action-cell"></th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let holder of $any(getHoldersAndInvites())">
			<td class="cell-with-padding-left">
				<span *ngIf="holder.user">
					<span *ngIf="auth.isAdmin()">
						{{ holder.user.user_id }} &#8212;
					</span>
					{{ holder.user.first_name }} {{ holder.user.last_name }}
				</span>
				<span class="user-email text-secondary">
					(<span *ngIf="holder.user">{{ holder.user.email }}</span>
					<span *ngIf="!holder.user">{{ holder.email }}</span
					>)
				</span>
			</td>
			<td>
				<mat-form-field class="license-holder-permission">
					<mat-select [value]="holder.is_admin" (selectionChange)="permissionChange($event, holder)" [disabled]="!canUpdateHolder(holder) || !permission.canEdit">
					  <mat-option [value]="true">
						{{ 'license-management_permissions-admin' | translate}}
					  </mat-option>
					  <mat-option [value]="false">
						{{ 'license-management_permissions-instructor' | translate }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>
			</td>
			<td>
				<span *ngIf="holder.invite">
					{{ holder.invite.created_at | date: 'short':'UTC' }}
				</span>
				<span *ngIf="!holder.invite">
					{{ holder.created_at | date: 'short':'UTC' }}
				</span>
			</td>
			<td>
				<span *ngIf="holder.user">
					{{ holder.created_at | date: 'short':'UTC' }}
				</span>
			</td>
			<td>
				<a href="#"
				   *ngIf="canAdministerLicense()"
					class="view-seats-lnk"
					(click)="viewSeats(holder)">
					{{holder.seats_consumed}}
				</a>
				<div *ngIf="!canAdministerLicense()">{{holder.seats_consumed}}</div>
			</td>
			<td class="action-cell">
				<button
					mat-button
					*ngIf="canRemoveHolder(holder) && permission.canEdit"
					(click)="removeUser(holder)"
					[disabled]="$any(holder).removing">
					<i class="ficon-app-x" *ngIf="!$any(holder).removing"></i>
					<i class="ficon-spinner ficon-spin" *ngIf="$any(holder).removing"></i>
				</button>
			</td>
		</tr>
	</tbody>

	<tfoot *ngIf="permission.canEdit">
		<tr>
			<td colspan="6">
				<button mat-button class="goreact-btn link-btn add-btn add-user" (click)="addUsers()">
					<span translate="license-management_add-user"></span>
				</button>
			</td>
		</tr>
	</tfoot>
</table>
