<div class="overlay-container">
	<!-- Unsupported Browser -->
	<div class="overlay-content" *ngIf="status.reason === reasons.UNSUPPORTED_BROWSER">
		<p
			class="status-overlay-title"
			[innerHtml]="'go-recorder_unsupported-browsers-warning' | translate: {browser: status.browser}"
		></p>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'browser-troubleshooting' | translate"
			   [href]="helpUrls.supportedBrowserTroubleshoot"
			   target="_blank"
			   [innerHtml]="'go-recorder-error_supported-browsers-troubleshooting' | translate">
			</a>
		</div>
	</div>

	<!-- ChromeOS DOODOO Browser -->
	<div class="overlay-content" *ngIf="status.reason === reasons.CHROME_OS_DOO_DOO">
		<p class="status-overlay-title" [innerHtml]="'go-recorder_chrome-os-bug' | translate"></p>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'browser-troubleshooting' | translate"
			   [href]="helpUrls.chromeBugIssues"
			   target="_blank"
			   [innerHtml]="'video-scenes_chrome-error' | translate">
			</a>
		</div>
	</div>

	<!-- Unsupported iOS Browser -->
	<div class="overlay-content" *ngIf="status.reason === reasons.IOS_NON_SAFARI_BROWSER">
		<p class="status-overlay-title">
			{{'common_iOS-browser-unsupported' | translate: {browser: status.browser} }}
		</p>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'browser-troubleshooting' | translate"
			   [href]="helpUrls.supportedBrowserTroubleshoot"
			   target="_blank" [innerHtml]="'go-recorder-error_supported-browsers-troubleshooting' | translate">
			</a>
		</div>
		<div class="btn-container">
			<button
				type="button"
				class="goreact-btn secondary-btn light no-audio-continue"
				[attr.aria-label]="'go-recorder-proceed_anyway' | translate"
				(click)="status.action()"
				[innerHtml]="'go-recorder-error_continue-with-no-audio' | translate">
			</button>
		</div>
	</div>

	<!-- Microphone No Audio -->
	<div class="overlay-content" *ngIf="status.reason === reasons.MICROPHONE_NO_AUDIO">
		<p class="status-overlay-title" translate="go-recorder_equipment-error-mic"></p>
		<div class="btn-container">
			<button
				type="button"
				class="goreact-btn primary-btn try-again"
				[attr.aria-label]="'go-recorder-error_try-again' | translate"
				(click)="status.action()"
				[innerHtml]="'go-recorder-error_try-again' | translate">
			</button>
		</div>
		<div class="btn-container">
			<button
				type="button"
				class="goreact-btn secondary-btn light no-audio-continue"
				[attr.aria-label]="'go-recorder-error_continue-with-no-audio' | translate"
				(click)="this.continueWithoutAudio()"
				[innerHtml]="'go-recorder-error_continue-with-no-audio' | translate">
			</button>
		</div>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'go-recorder-error_microphone-troubleshooting' | translate"
			   [href]="helpUrls.microphoneTroubleshoot"
			   target="_blank"
			   [innerHtml]="'go-recorder-error_microphone-troubleshooting' | translate">
			</a>
		</div>
	</div>

	<!-- Camera No Motion -->
	<div class="overlay-content" *ngIf="status.reason === reasons.CAMERA_NO_MOTION">
		<p class="status-overlay-title" [innerHtml]="'go-recorder_equipment-error-camera' | translate"></p>
		<div class="btn-container">
			<button
				type="button"
				class="goreact-btn primary-btn try-again"
				[attr.aria-label]="'go-recorder-error_try-again' | translate"
				(click)="status.action()"
				[innerHtml]="'go-recorder-error_try-again' | translate">
			</button>
		</div>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'go-recorder-error_camera-troubleshooting' | translate"
			   [href]="helpUrls.cameraTroubleshoot"
			   target="_blank"
			   [innerHtml]="'go-recorder-error_camera-troubleshooting' | translate">
			</a>
		</div>
	</div>

	<!-- OpenTok Connection Failed -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_CONNECTION_FAILED">
		<p class="status-overlay-title" [innerHTML]="'go-recorder_equipment-error-server' | translate"></p>
		<div class="btn-container">
			<button
				type="button"
				class="goreact-btn primary-btn close-error"
				[attr.aria-label]="'common_close' | translate"
				(click)="status.action()"
				[innerHtml]="'go-recorder-error_close' | translate">
			</button>
		</div>
		<div class="equipment-check-links">
			<a class="goreact-btn tertiary-btn light"
			   [attr.aria-label]="'go-recorder-error_troubleshooting' | translate"
			   [href]="helpUrls.networkTroubleshoot"
			   target="_blank"
			   [innerHtml]="'go-recorder-error_troubleshooting' | translate">
			</a>
		</div>
	</div>

	<!-- OpenTok Connecting -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_CONNECTING">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_connecting' | translate"></p>
	</div>

	<!-- OpenTok Reconnecting -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_RECONNECTING">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_reconnecting' | translate"></p>
	</div>

	<!-- OpenTok Poor Connection -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_POOR_CONNECTION">
		<span class="toast-icon">
			<i class="ficon-app-warning-circle-outline"></i>
		</span>
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_poor-connection' | translate"></p>
	</div>

	<!-- OpenTok Network Disconnected -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_NETWORK_DISCONNECTED">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_network-disconnected' | translate"></p>
	</div>

	<!-- OpenTok Network Failed -->
	<div class="overlay-content" *ngIf="status.reason === reasons.OPENTOK_NETWORK_FAILED">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_network-failed' | translate"></p>
	</div>

	<div class="overlay-content" *ngIf="status.reason === reasons.SCREEN_SHARE_PREPARING">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_preparing_screen_share' | translate"></p>
	</div>

	<div class="overlay-content" *ngIf="status.reason === reasons.RESOLUTION_UPDATING">
		<i class="ficon-spinner ficon-spin"></i>
	</div>

	<div class="overlay-content" *ngIf="status.reason === reasons.RESTARTING_SESSION">
		<p class="status-overlay-title" [innerHtml]="'go-recorder-status_restarting' | translate"></p>
	</div>
</div>
