import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	Input,
	ViewChild
} from '@angular/core';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoDropdownService } from './go-dropdown.service';
import { GoDropdownOptionComponent } from './go-dropdown-option.component';
import { GoDropdownDropdownComponent } from './go-dropdown-dropdown.component';

/**
 * Examples:
 * 	- <go-dropdown placeholder="Select One" [items]="items" [textKey]="textKey"></go-dropdown>
 * 	- <go-dropdown formControlName="Items" placeholder="Select One" [items]="items" [textKey]="textKey"></go-dropdown>
 * For more examples see the test-component.html file
 */
@Component({
	selector: 'go-dropdown',
	templateUrl: './go-dropdown.component.html',
	styleUrls: ['./go-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GoDropdownComponent),
			multi: true
		},
		GoDropdownService
	]
})
export class GoDropdownComponent implements AfterViewInit, ControlValueAccessor {
	@Input() public items: any[];
	@Input() public placeholder: string;
	@Input() public textKey: string;
	@Input() public ngModel: any;
	@Input() public selected: string;
	@Input() public disabled = false;
	@Input() public descriptionKey: string | null;
	@Input() public groupKey: string | null;
	@ViewChild(GoDropdownDropdownComponent) public dropdown: GoDropdownDropdownComponent;
	public touched: boolean = false;
	public selectedOption: GoDropdownOptionComponent;
	public onChangeFn: any;
	public onTouchedFn: any;
	public groups: any[];
	public groupedItems: any[];
	private keyManager: ActiveDescendantKeyManager<GoDropdownOptionComponent>;

	constructor (
		private dropdownService: GoDropdownService,
		private element: ElementRef,
		private cdr: ChangeDetectorRef
	) {
		this.dropdownService.register(this);
	}

	public ngAfterViewInit () {
		this.keyManager = new ActiveDescendantKeyManager(this.dropdownService.options)
			.withHorizontalOrientation('ltr')
			.withVerticalOrientation()
			.withWrap(false);
		if (this.ngModel) {
			const option = this.dropdownService.options.toArray().find((opt) =>
				opt[this.textKey] === this.ngModel[this.textKey]);
			this.keyManager.setActiveItem(option);
			this.selected = option.key;
		}
		if (this.groupKey) {
			// get unique values for specified group key
			this.groups = [...new Set(this.items.map((item) => item[this.groupKey]))];
			// aggregate the items into their groups
			this.groupedItems = this.items.reduce((item, x) => {
				// if first item in group then create an array
				if (!item[x[this.groupKey]]) item[x[this.groupKey]] = [];
				// add the item to the array for that group
				item[x[this.groupKey]].push(x);
				return item;
			}, {});
			// sort the groups alphabetically
			this.groups.sort((a, b) => a.localeCompare(b));
			// sort the items within the groups alphabetically by the specified textKey
			for (const group of Object.keys(this.groupedItems)) {
				this.groupedItems[group].sort((a, b) => a[this.textKey].localeCompare(b[this.textKey]));
			}
		}
		this.selectedOption = this.dropdownService.options.toArray().find((option) => option.key === this.selected);
		this.cdr.detectChanges();
	}

	public toggleDropdown () {
		if (this.dropdown.showing) {
			this.hideDropdown();
		} else {
			this.showDropdown();
		}
	}

	public showDropdown () {
		this.dropdown.show();

		if (!this.dropdownService.options.length) {
			return;
		}

		if (this.selected) {
			this.keyManager.setActiveItem(this.selectedOption);
		} else {
			this.keyManager.setFirstItemActive();
		}
		this.cdr.detectChanges();
	}

	public hideDropdown () {
		this.dropdown.hide();
	}

	public onKeyDown (event: KeyboardEvent) {
		if (!this.dropdown.showing) {
			if ([' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
				this.showDropdown();
			}
		} else {
			if (event.key === 'Enter' || event.key === ' ' || event.key === 'Tab') {
				this.selectedOption = this.keyManager.activeItem;
				this.selected = this.selectedOption.key;
				// enter will toggle dropdown already
				if (event.key === ' ' || event.key === 'Tab') this.toggleDropdown();
				this.onChange();
				this.onTouched();
				this.cdr.detectChanges();
			} else if (event.key === 'Escape' || event.key === 'Esc') {
				this.hideDropdown();
			} else if (['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left'].indexOf(event.key) > -1) {
				this.keyManager.onKeydown(event);
				this.cdr.detectChanges();
			}
		}
	}

	public selectOption (option: GoDropdownOptionComponent) {
		this.keyManager.setActiveItem(option);
		this.selected = option.key;
		this.selectedOption = option;
		this.hideDropdown();
		this.element.nativeElement.focus();
		this.onChange();
		this.onTouched();
	}

	public onChange () {
		if (this.onChangeFn) this.onChangeFn(this.selectedOption);
	}

	public onTouched () {
		if (this.onTouchedFn) this.onTouchedFn();
	}

	// ControlValueAccessor interface methods
	public registerOnChange (onChange: any): void {
		this.onChangeFn = onChange;
	}

	public registerOnTouched (onTouched: any): void {
		this.onTouchedFn = onTouched;
	}

	public writeValue (obj: any): void {
		this.selected = obj;
	}
}
