<div class="toolbar self-demo" *ngIf="shouldShowDemoButtons()">
	<!-- Self Demo Sales Demo Request-->
	<a
		class="tertiary-btn goreact-btn"
		wcag-ignore
		target="_blank"
		href="https://get.goreact.com/demo/"
		translate="video-sharing_get-demo">
	</a>
	<!-- Self Demo Sales Demo Request-->
	<a
		class="primary-btn goreact-btn"
		wcag-ignore
		target="_blank"
		[href]="this.baseUrl + '/dashboard/auth/signup'"
		translate="video-sharing_start-trial">
	</a>
</div>
<div class="toolbar user-tools" *ngIf="shouldShowDropdown()">
	<!-- Product Feedback -->
	<button id="suggestion-panel-button"
		type="button"
		class="btn-none suggestion-panel-btn ng-scope"
		wcag-ignore
		aria-haspopup="dialog"
		(click)="openSuggestionPanel()"
		matTooltip="{{ 'header_submit-ideas' | translate }}"
		matTooltipPosition="below">
		<i class="ficon-lightbulb-o"></i>
	</button>
	<!-- Zendesk Help -->
	<button
		type="button"
		class="btn-none zendesk-btn"
		(click)="activateZendeskWidget()"
		wcag-ignore
		analytics-on="click"
		analytics-category="Zendesk"
		analytics-event="Help!"
		aria-haspopup="dialog"
		matTooltip="{{ 'zendesk_help' | translate }}"
		matTooltipPosition="below">
		<i class="ficon-app-help-circle-outline"></i>
	</button>

	<button
		class="goreact-btn tertiary-btn profile-menu-btn"
		data-cy="profile-menu-dropdown"
		[matMenuTriggerFor]="profileMenu">
		{{ this.user.full_name }}
	</button>
	<mat-menu #profileMenu class="profile-menu">
		<button
			id="profile-btn"
			mat-menu-item
			(click)="launchProfile()"
			translate="header_account-settings">
		</button>
		<mat-divider></mat-divider>
		<button
			class="terms"
			mat-menu-item
			target="_blank"
			translate="footer_terms"
			(click)="navigate('https://get.goreact.com/terms')">
		</button>
		<button
			mat-menu-item
			target="_blank"
			translate="footer_privacy"
			(click)="navigate('https://get.goreact.com/privacy')">
		</button>
		<button
			mat-menu-item
			target="_blank"
			translate="header_compliance"
			(click)="navigate('https://help.goreact.com/hc/en-us/articles/115000273226')">
		</button>
		<button
			mat-menu-item
			target="_blank"
			translate="footer_accessibility"
			(click)="navigate('https://help.goreact.com/hc/en-us/articles/360033491191-VPAT-2-3-508-Compliance')">
		</button>
		<mat-divider></mat-divider>
		<button
			mat-menu-item
			translate="header_help-desk"
			(click)="activateZendeskWidget()">
		</button>
		<button
			queryParamsHandling="preserve"
			mat-menu-item
			translate="header_logout"
			data-cy="sharing-logout"
			(click)="logout()">
		</button>
	</mat-menu>
</div>
