<div *ngIf="mode !== verificationModes.PASSWORD_RESET">
	<button class="ficon-arrow-left back-btn" role="button" aria-label="back" (click)="back()"></button>
</div>

<section class="content" aria-labelledby="email-verification-title" aria-describedby="email-verification-description">
	<h1
		id="email-verification-title"
		class="title"
		translate="{{mode === verificationModes.PASSWORD_RESET ? 'email-verification_reset_title' : 'email-verification_title'}}">
	</h1>

	<p id="email-verification-description" class="description fullstory-ignore" translate="email-verification_description" [translateParams]="{email}"></p>

	<code-input #codeInput
		[autoFocus]="true"
		[disabled]="loading || needToResendCode"
		(onChange)="codeValueChange$.next($event)"
		[class.loading]="loading || needToResendCode"
	></code-input>

	<p class="accessibility-text" aria-live="polite">{{ validatingCode ? ('email-verification_validating_code' | translate) : '' }}</p>
	<p class="verification-error" aria-live="assertive" data-cy="email-verification-code-invalid">{{error | translate}}</p>

	<p class="resend-code-wrapper">
		<span>
			<span id="email-verification-resend-code"><span translate="email-verification_did_not_email"></span> <span class="accessibility-text" translate="common_resend-code"></span></span>

			<button
				class="resend-code-btn"
				aria-labelledby="email-verification-resend-code"
				[attr.aria-pressed]="resendCodeloading"
				[attr.tabindex]="resendCodeCountDownTime > 0 ? -1 : 0"
				[class.accessibility-text]="resendCodeCountDownTime > 0"
				(click)="resendCodeloading ? null : resendCode($event, countDown)">
				{{resendCodeloading ? ('common_please-wait' | translate) + '...' : ('common_resend-code' | translate)}}
			</button>

			<span
				#countDown
				[class.accessibility-text]="resendCodeCountDownTime === 0"
				[attr.tabindex]="resendCodeCountDownTime > 0 ? 0 : -1"
				role="button"
				aria-disabled="true"
				class="count-down-timer">
				<span aria-hidden="true">{{resendCodeCountDownTime}}s</span>

				<span class="accessibility-text" translate="email-verification_did_resend_code_countdown" [translateParams]="{resendCodeCountDownTime}"></span>
			</span>
		</span>

		<span
			suffix
			class="icon ficon-app-help-circle-outline"
			[goTooltip]="'sign-up-not-received-email' | translate"
			goTooltipPlacement="top|right"
		></span>
	</p>
</section>
