<div class="search-bar" role="search">
	<div class="search-txt" translate="common_search"></div>
	<input #textInput
		type="text"
		[attr.aria-label]="ariaLabel"
		[attr.aria-labelledBy]="ariaLabelledBy"
		aria-label class="form-control search-input"
		[(ngModel)]="searchText"
		(ngModelChange)='changed($event)' />
	<div class="action-wrapper">
		<span *ngIf="!searchText" mat-icon><i class="ficon-search"></i></span>
		<button *ngIf="searchText" class="ficon-app-x clear-btn" mat-button (click)="clearText()"></button>
	</div>
</div>
