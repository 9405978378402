<div class="modal-dialog ai-markers-dialog-container">
	<div class="modal-content" cdkTrapFocus>
		<div class="goreact-modal-header">
			<span class="header-label">
				<h4 class="modal-title">{{'activity-editor-new_ai-markers-modal-title' | translate}}</h4>
			</span>
			<button type="button" class="close-btn" (click)="cancel()" attr.aria-label="{{ 'common_close' | translate }}">
				<i class="ficon-app-x"></i>
			</button>
		</div>
		<section *teaseWall="aiMarkerTeaseConfig; of: loading$">
			<div class="goreact-modal-body">
				<div>{{ 'activity-editor-new_ai-markers-modal-sub-title' | translate }}</div>
				<div class="markers-container" *ngIf="!(loading$ | async); else loading"
					 [ngClass]="{'has-error': selectedCount > AiMarkersLimit}">
					<div class="markers-container-header">
						<strong>{{ 'activity-editor-new_ai-markers-modal-ai-markers' | translate }}</strong>
						<mat-slide-toggle
							[(ngModel)]="data.activity.ai_prompts_comment_visible"
							color="primary"
							class="ai-comments-toggle">
							{{ 'activity-editor-new_ai-markers-modal-ai-comments' | translate }}
						</mat-slide-toggle>
					</div>
					<p translate="activity-editor-new_ai-markers-modal-description"
					   [translateParams]="{ limit: AiMarkersLimit }">
					</p>
					<div *ngIf="useTypes" class="use-types-container">
						<mat-form-field goSelect>
							<mat-label>{{ 'activity-editor-new_ai-markers-modal-use-type' | translate }}</mat-label>
							<mat-select
								[disabled]="shouldTease"
								disableOptionCentering
								panelClass="offsetPanel"
								(selectionChange)="filterUseType($event.value)"
								[value]="selectedUseType">
								<mat-option
									*ngFor="let type of useTypes"
									[value]="type">
									{{ type.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<span class="selected-count" *ngIf="selectedCount > 0"
							  [ngClass]="{'has-error': selectedCount> AiMarkersLimit }"
						> ({{ selectedCount }}) </span>
					</div>

					<div class="markers" [style.overflow-y]="shouldTease ? 'hidden':'auto' ">
						<div class="marker-item" *ngFor="let prompt of allPrompts$ | async">
							<mat-checkbox
								[checked]="prompt.checked"
								(change)="selectPrompt($event.checked, prompt)"
								aria-label="{{ prompt.tag.tag_name }}"
							>
								<marker [marker]="prompt.tag" [aiStyle]="true" [withName]="true"></marker>
								<div class="prompt-description">{{ prompt.description }}</div>
							</mat-checkbox>
						</div>
					</div>

					<div class="ai-marker-form-link">
						<span>
							<i class="ficon-lightbulb-o"></i>
							{{ 'activity-editor-new_ai-markers-modal-request-feedback-text' | translate }}
							<a
								href="https://forms.gle/SHcTdckaQaX2ZefE6"
								target="_blank" rel="noopener noreferrer"
								class="link-btn"
								attr.aria-label="{{'activity-editor-new_ai-markers-modal-request-feedback-link'| translate}}"
								translate="activity-editor-new_ai-markers-modal-request-feedback-link">
							</a>
						</span>
					</div>
				</div>
				<ng-template #loading>
					<span class="loading-container">
						<span class="ficon-spinner ficon-spin" aria-live="polite" attr.aria-label="{{'common_loading'| translate}}"></span>
					</span>
				</ng-template>
				<div class="validation-error" *ngIf="selectedCount > AiMarkersLimit"
					 translate="activity-editor-new_ai-markers-modal-validation-max_limit"
					 [translateParams]="{ limit: AiMarkersLimit }">
				</div>
			</div>
			<div class="goreact-modal-footer">
				<button class="tertiary-btn cancel-btn" (click)="cancel()" translate="common_cancel"></button>
				<button class="primary-btn save-btn" (click)="save()" translate="common_save-and-close"></button>
			</div>
		</section>
	</div>
</div>
