<div class="ngx-dialog goreact-modal">
	<div class="goreact-modal-header">
		<h4 class="modal-title" translate="common_remove-user"></h4>
		<button type="button" class="close-btn" (click)="cancel()" aria-label="Cancel">
			<i class="ficon-app-x"></i>
		</button>
	</div>
	<div class="goreact-modal-body">
		<p>
			{{'remove-user-cofirm-dialog_message' | translate}} <span class="bold">{{dialogData.user.full_name | titlecase}}</span>
			{{'common_from' | translate}}
			<ng-container *ngIf="isSingleCourseRemoval">
				&nbsp;<span class="bold">{{dialogData.role.raw_data.course.name}}</span>
				&nbsp;<span class="bold">({{dialogData.role.raw_data.course.created_by.full_name | titlecase}})</span>
			</ng-container>
			&nbsp;<span *ngIf="!isSingleCourseRemoval" class="bold" translate="common_all-courses"></span> ?
		</p>
		<p class="alert goreact-alert-default" [innerHtml]="'modal-remove-user_note' | translate"></p>
	</div>
	<div class="goreact-modal-footer">
		<button class="cancel-button" mat-button (click)="cancel()"> {{'common_cancel' | translate}} </button>
		<button class="confirm-button primary-btn" (click)="confirm()"> {{'common_remove' | translate}} </button>
	</div>
</div>
