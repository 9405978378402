import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import type { Session as SessionType } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { sessionToken, Session as SessionModel } from 'go-modules/models/session/session.service';
import { mediaToken } from 'go-modules/models/media/media.factory';
import dayjs from 'dayjs';
import { forOwn } from 'lodash';
import angular, { noop } from 'angular';
import { BehaviorSubject, from, map } from 'rxjs';
import { Media } from 'go-modules/services/attachment/media';
import { User } from 'ngx/go-modules/src/interfaces/user';
import { NgxYouTubeProcessorService } from 'ngx/go-modules/src/services/youtube-processor/youtube-processor.service';

export interface Video {
	session: SessionType;
	owner: User;
	media: Media;
	encoding: any;
	upload: {
		media_upload_id: number;
		filename_original: string;
		duration: number|string;
		speed: number|string;
		filesize: any;
		started_at: string;
		finished_at: string;
		data: any;
		parts: any;
	};
}

@Component({
	selector: 'ngx-video-details-dialog',
	templateUrl: './video-details-dialog.component.html',
	styleUrls: ['./video-details-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoDetailsDialogComponent implements OnInit {
	public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public video: Video;

	private ONE_MILLION = 1000000;

	constructor (
		public dialogRef: GoDialogRef,
		private youtubeProcessorService: NgxYouTubeProcessorService,
		@Inject(sessionToken) private Session: ReturnType<typeof SessionModel>,
		@Inject(mediaToken) private MediaModel,
		@Inject(GO_MODAL_DATA) private data: {
			session: SessionType;
		},
		@Inject('Window') private window: Window
	) {}

	public ngOnInit (): void {
		from(this.Session.getForensics({session_id: this.data.session.session_id}).$promise)
			.pipe(
				map((video: Video) => {
					return { ...video, media: this.MediaModel.model(video.media) };
				})
			)
			.subscribe((video: Video) => {
				this.video = video;

				if (video.hasOwnProperty('upload')) {
					this.video = this.formatUpload(video);
				}

				if (video.media) {
					this.video = this.formatMedia(this.video);
				}

				this.isLoading$.next(false);
			});
	}

	public hasUpload () {
		return this.video.upload?.media_upload_id;
	}

	public close () {
		this.dialogRef.close();
	}

	public isYoutube (url: string) {
		return this.youtubeProcessorService.isYoutubeUrl(url);
	}

	public download (mediaId: number) {
		this.MediaModel.download(mediaId);
	}

	public restoreMedia (mediaId: number) {
		this.MediaModel.swap(mediaId)
			.then(this.window.location.reload.bind(this.window.location))
			.catch(noop);
	}

	private formatUpload (video: Video): Video {
		const fileSizeMB = video.upload.filesize / this.ONE_MILLION;
		video.upload.filesize = fileSizeMB.toFixed(2);
		video.upload.duration = '--';

		const start = dayjs.utc(video.upload.started_at);
		video.upload.started_at = start.format('YYYY-MM-DD HH:mm:ss');

		if (video.upload.finished_at !== null) {
			const finish = dayjs.utc(video.upload.finished_at);
			video.upload.finished_at = finish.format('YYYY-MM-DD HH:mm:ss');

			const duration = dayjs.utc(finish.diff(start, 'ms'));
			video.upload.duration = duration.format('HH:mm:ss');
			video.upload.speed = (fileSizeMB / finish.diff(start, 's')).toFixed(2);
		}

		return video;
	}

	private formatMedia (video): Video {
		video.media.filesize = (video.media.filesize / this.ONE_MILLION).toFixed(2);

		if (video.media.encoding != null && video.media.encoding.hasOwnProperty('deleted')) {

			// filter props we don't want
			const props = [
				'bitrate',
				'duration',
				'filesize',
				'format',
				'frame_rate',
				'size',
				'video_codec'
			];

			forOwn(video.media.encoding, function (_val, key) {
				if (props.indexOf(key) === -1) {
					delete video.media.encoding[key];
				}
			});

			video.media.encoding.duration = dayjs.utc(video.media.encoding.duration * 1000).format('HH:mm:ss');
			video.media.encoding.filesize = (video.media.encoding.filesize / this.ONE_MILLION).toFixed(2);
		}

		if (video.media.data !== null) {
			video.media.data = angular.fromJson(video.media.data);
		}

		return video;
	}
}
