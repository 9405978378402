import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { EmailVerificationErrorMessages } from 'ngx/go-modules/src/enums/sign-up/email-verification-error-messages.enum';
import { EmailVerificationErrorTypes } from 'ngx/go-modules/src/enums/sign-up/email-verification-error-types.enum';
import type { EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import type { RequestValidationError } from 'ngx/go-modules/src/interfaces/request-validation-error';
import { EMPTY, interval, Observable, Subject } from 'rxjs';
import { finalize, takeUntil, takeWhile, map, switchMap, catchError, first } from 'rxjs/operators';
import { SignUpService } from 'ngx/go-modules/src/services/sign-up/sign-up.service';
import { CodeInputComponent } from './code-input/code-input.component';
import { UserService } from 'ngx/go-modules/src/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export enum VerificationModes {
	SIGN_UP = 'SIGN_UP',
	PASSWORD_RESET = 'PASSWORD_RESET',
}

@Component({
	selector: 'email-verification',
	templateUrl: './email-verification.component.html',
	styleUrls: ['./email-verification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
	@ViewChild('codeInput', {static: true})
	public codeInputComponent: CodeInputComponent;

	@Input()
	public email: string;

	@Input()
	public mode: VerificationModes = VerificationModes.SIGN_UP;

	@Output()
	public onBack = new EventEmitter<void>();

	@Output()
	public onResend = new EventEmitter<void>();

	@Output()
	public onComplete = new EventEmitter<EmailVerificationResponse>();

	public error: EmailVerificationErrorMessages;
	public notifier = new Subject();
	public loading = false;
	public validatingCode = false;
	public resendCodeloading = false;
	public needToResendCode = false;
	public verificationModes = VerificationModes;

	public resendCodeCountDownTime = 0;
	public codeValueChange$ = new Subject<string>();

	constructor (
		private signUpService: SignUpService,
		private userService: UserService,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		private translate: TranslateService
	) {}

	public ngOnInit (): void {
		this.codeValueChange$.pipe(
			switchMap((verificationCode: string) => {
				this.loading = true;
				this.validatingCode = true;
				this.error = null;
				if (this.mode === this.verificationModes.PASSWORD_RESET) {
					return this.userService.validateResetCode(this.email, verificationCode).pipe(
						catchError((err: HttpErrorResponse) => this.errorHandler(err)),
						finalize(() => {
							this.loading = false;
							this.validatingCode = false;
						})
					);
				}
				return this.signUpService.validateCode(this.email, verificationCode).pipe(
					catchError((err: HttpErrorResponse) => this.errorHandler(err)),
					finalize(() => {
						this.loading = false;
						this.validatingCode = false;
					})
				);
			}),
			first()
		).subscribe(this.onComplete);
	}

	public ngOnDestroy () {
		this.notifier.next(null);
		this.notifier.complete();
	}

	public resendCode ($event, countdownElement: HTMLElement) {
		if (this.mode === VerificationModes.PASSWORD_RESET) {
			this.onResend.emit();

			return;
		}

		this.loading = true;
		this.resendCodeloading = true;
		this.cdr.detectChanges();

		this.signUpService.sendVerificationEmail(this.email)
			.pipe(
				first(),
				takeUntil(this.notifier),
				finalize(() => {
					this.loading = false;
					this.resendCodeloading = false;
					this.cdr.detectChanges();
				}),
				catchError((response: HttpErrorResponse) => {
					if (response.status === 401 && response.error.remainingAccountLockSeconds) {
						let timeInSeconds = parseInt(response.error.remainingAccountLockSeconds, 10);
						timeInSeconds = Math.ceil(timeInSeconds / 60);

						MessageDialogComponent.open(
							this.dialog,
							{
								data: {
									title: this.translate.instant('email-verification_lock_title'),
									message: this.translate.instant(
										'email-verification_lock_message',
										{ time: timeInSeconds }
									)
								}
							});
					}

					return EMPTY;
				})
			)
			.subscribe(() => {
				this.error = null;
				this.needToResendCode = false;

				countdownElement.focus();
				this.startResendCodeCountdown().pipe(finalize(() => {
					if (document.activeElement === countdownElement) {
						$event.target.focus();
					}
				})).subscribe();
			});
	}

	public startResendCodeCountdown (): Observable<void> {
		this.resendCodeCountDownTime = 30;
		this.cdr.detectChanges();

		return interval(1000)
			.pipe(
				takeUntil(this.notifier),
				takeWhile(() => this.resendCodeCountDownTime > 0),
				map(() => {
					this.resendCodeCountDownTime--;
					this.cdr.detectChanges();
				})
			);
	}

	public back () {
		this.onBack.emit();
	}

	private errorHandler (err: HttpErrorResponse): Observable<never> {
		switch (err.status) {
			case 0:
				this.error = EmailVerificationErrorMessages.NETWORK_LOST;
				break;
			case 422:
				const errors: RequestValidationError = err.error;
				switch (errors.message) {
					case EmailVerificationErrorTypes.EMAIL_USED:
						this.error = EmailVerificationErrorMessages.EMAIL_USED;
						break;
					case EmailVerificationErrorTypes.INCORRECT_CODE:
						this.error = EmailVerificationErrorMessages.INCORRECT_CODE;
						break;
					case EmailVerificationErrorTypes.MAX_ATTEMPT:
						this.error = EmailVerificationErrorMessages.MAX_ATTEMPT;
						this.needToResendCode = true;
						break;
					case EmailVerificationErrorTypes.MISSING_VERIFICATION:
						this.error = EmailVerificationErrorMessages.MISSING_VERIFICATION;
						this.needToResendCode = true;
						break;
					default:
						this.error = err.error.message;
				}
				break;
			default:
				this.error = EmailVerificationErrorMessages.UKNOWN_ERROR;
		}

		this.codeInputComponent.clear();
		this.cdr.detectChanges();
		return EMPTY;
	}
}
