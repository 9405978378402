import { ApplicationRef, APP_INITIALIZER, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import { GoModulesModule } from 'ngx/go-modules/src/go-modules.module';
import { GoToastModule } from 'ngx/go-modules/src/components/go-toast/go-toast.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { retry } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';

import { AppComponent } from '@video-sharing/app/app.component';
import { HeaderToolbarComponent } from '@video-sharing/app/common/components/header-toolbar/header-toolbar.component';
import { AppRoutingModule } from '@video-sharing/app/app-routing.module';
import { feedbackWrapperModule } from '@video-sharing/app/wrappers/wrapper.module';
import { VideoSharingTranslateLoader } from './translate-loader';
import * as angular from 'angular';

// Pull in all shared styles
import 'bootstrap-css-only';
import 'go-styles/src/goreact-style.less';
import 'go-ui/material-theme.scss';
import 'go-ui/go-ui.scss';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { FeatureFlagSyncInterceptorModule } from 'ngx/go-modules/src/services/interceptors';
import { AuthInterceptor } from './common/auth/auth-interceptor';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';

function initializeApp (translate: TranslateService) {
	// Loads translations prior to app initializing instead of lazy loading
	return () => translate.use('en')
		.pipe(retry(2))
		.toPromise()
		.catch(console.error);
}

@NgModule({
	declarations: [
		AppComponent,
		HeaderToolbarComponent
	],
	imports: [
		GoModulesModule,
		GoMaterialModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		MatTooltipModule,
		MatDividerModule,
		UpgradeModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: VideoSharingTranslateLoader.factory,
				deps: [HttpClient]
			}
		}),
		GoToastModule,
		FeatureFlagSyncInterceptorModule
	],
	providers: [
		{ provide: '$scope', useExisting: '$rootScope' },
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [TranslateService],
			multi: true
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
	],
	entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {
	constructor(private upgrade: UpgradeModule) {}

	public ngDoBootstrap(app: ApplicationRef): void {
		setAngularJSGlobal(angular);
		this.upgrade.bootstrap(document.body, [
			feedbackWrapperModule
		]);
		app.bootstrap(AppComponent);
	}
}
