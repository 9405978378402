import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { User } from 'ngx/go-modules/src/interfaces/user';
import { AuthProvider } from 'ngx/go-modules/src/interfaces/auth-providers';

export interface UnlinkSsoDialogData {
	requiresPassword: boolean;
	authProvider: AuthProvider;
}
@Component({
	selector: 'unlink-sso-dialog',
	templateUrl: './unlink-sso-dialog.component.html',
	styleUrls: ['./unlink-sso-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnlinkSsoDialogComponent implements OnDestroy {
	public loading$ = new BehaviorSubject(false);
	public error$ = new BehaviorSubject(false);

	public constructor (
		public dialogRef: MatDialogRef<UnlinkSsoDialogComponent, User | undefined>,
		private ngxAuthService: NgxAuthService,
		@Inject(MAT_DIALOG_DATA) public data: UnlinkSsoDialogData
	) {}

	public ngOnDestroy () {
		this.loading$.complete();
		this.error$.complete();
	}

	public confirm () {

		if (this.data.requiresPassword) {
			return this.dialogRef.close(null);
		}

		this.loading$.next(true);
		this.error$.next(false);

		this.ngxAuthService.unlinkSsoAccount(this.data.authProvider.provider_name).subscribe({
			next: (userResponse) => {
				this.dialogRef.close(userResponse);
			},
			error: () => {
				this.loading$.next(false);
				this.error$.next(true);
			}
		});
	}

	public cancel () {
		this.dialogRef.close(null);
	}
}


