import * as angular from 'angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { userServiceToken } from 'go-modules/models/user/user.service';
import type { UserService } from 'go-modules/models/user/user.service';
import { changeEmailModalToken } from 'go-modules/modals/change-email/modal.service';
import type { ChangeEmailModal } from 'go-modules/modals/change-email/modal.service';
import { userToken } from 'go-modules/models/user/user.factory';
import type { User as UserFactory } from 'go-modules/models/user/user.factory';
import { GoLanguage, clientSettings } from 'go-modules/models/common/client.settings';
import { GoLocalizeHelperService, goLocalizeHelperToken } from 'go-modules/translation-helper/go-localize-helper.service';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { UserService as NgxUserService } from 'ngx/go-modules/src/services/user/user.service';
import { $translateToken } from 'ngx/go-modules/src/upgraded-3rd-party-deps/translate.upgrade';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { Masquerade, masqueradeToken } from 'go-modules/masquerade/masquerade.service';
import { AuthProvider, AuthProviders } from 'ngx/go-modules/src/interfaces/auth-providers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	UnlinkSsoDialogComponent
} from 'ngx/go-modules/src/components/dialogs/unlink-sso-dialog/unlink-sso-dialog.component';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import {
	ChangePasswordDialogComponent
} from 'ngx/go-modules/src/components/dialogs/change-password-dialog/change-password-dialog.component';
import {
	ConfirmPasswordDialogComponent,
	ConfirmPasswordDialogData
} from 'ngx/go-modules/src/components/dialogs/confirm-password-dialog/confirm-password-dialog.component';

export const DEBOUNCE_TIME = 500;

@Component({
	selector: 'user-details',
	templateUrl: './user-details.component.html',
	styleUrls: ['./user-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsComponent {
	// Hide language field when in video-share
	@Input() public hideLanguage? : boolean;
	@Input() public sessionId?: string;

	public form: FormGroup;
	public currentLanguage: GoLanguage;
	public languages: GoLanguage[];
	public user;
	public shouldShowSaveButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isSaving$ = new BehaviorSubject(false);
	public environmentVarsService: EnvironmentVarsService;
	public ssoProviders: AuthProvider[];

	constructor (
		public ngxFeatureFlag: NgxFeatureFlagService,
		private fb: FormBuilder,
		private ngxUserService: NgxUserService,
		private authService: NgxAuthService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(userToken) private User: ReturnType<typeof UserFactory>,
		@Inject(changeEmailModalToken) private changeEmailModal: ChangeEmailModal,
		@Inject($translateToken) private $translate: angular.translate.ITranslateService,
		@Inject(goLocalizeHelperToken) private goLocalizeHelper: GoLocalizeHelperService,
		@Inject(masqueradeToken) public masquerade: Masquerade,
		@Inject('Window') private window: Window,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.user = this.userService.currentUser;
		this.initializeForm();
		this.setSsoProviders();

		if (this.hideLanguage) {
			this.form.removeControl('language');
		} else {
			this.fetchLanguage();
		}
	}

	public changeEmail () {
		const user = this.User.model(angular.copy(this.user));
		this.changeEmailModal.open({
			modalData: {
				user
			}
		}).result.then((result) => {
			this.user.email = result.email;
			this.form.get('userEmail').setValue(result.email);
			this.ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'common-save-success'
			});
		}).catch(angular.noop);
	}

	public changePassword () {
		this.dialog.open(ChangePasswordDialogComponent, {
			data: {
				user: this.user,
				currentUser: this.user,
				sessionId: this.sessionId
			}
		});
	}

	public saveUser (form) {
		if (!form.valid) {
			return;
		}

		const firstName = form.get('userFirstName').value;
		const lastName = form.get('userLastName').value;
		if (firstName !== this.user.first_name ||
			lastName !== this.user.last_name) {
			this.user.first_name = firstName;
			this.user.last_name = lastName;
		}

		if (!this.hideLanguage) {
			const language = form.get('language').value;
			if (language.code !== this.user.language) {
				this.goLocalizeHelper.configure(language.code);
				this.currentLanguage = language;
				this.user.language = language.code;
			}
		}

		this.isSaving$.next(true);

		return this.User.save(this.user).$promise
			.then((user) => {
				Object.assign(this.user, user);
				this.userService.setCurrentUser(this.user);
				this.authService.refresh();
				this.initializeForm();
				this.shouldShowSaveButton$.next(false);
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'common-save-success'
				});
			}).finally(() => this.isSaving$.next(false));
	}

	public connectSsoAccount (provider: string) {
		// TODO: If you are connected to one SSO provider, and want to
		// connect to another, but you've never made an account on goreact.com
		// then it is incorrect to show this password modal. How do we "confirm" your existing
		// sso account before allowing you to make another? Can we even do that? Do we just not
		// force a password check if you don't have a goreact.com auth alias?
		// Can we force an oauth provider to make their user re-login even if they are still
		// authenticated through that provider?
		const user = this.User.model(angular.copy(this.user));
		const confirmPasswordDialogRef = this.dialog.open(ConfirmPasswordDialogComponent, {
			data: {
				user,
				sessionId: this.sessionId,
				provider
			} as ConfirmPasswordDialogData
		});
		confirmPasswordDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.window.location.href = result.url;
			}
		});
	}

	public unlinkSsoAccount (authProvider: AuthProvider) {
		// Needs a password if provider is not goreact and only has one other provider
		const requiresPassword = this.hasProvider(authProvider.provider_name) &&
			!this.hasAuthProviderGoReact() &&
			this.ssoProviders.length < 2;

		const dialogRef: MatDialogRef<UnlinkSsoDialogComponent, any> = this.dialog.open(UnlinkSsoDialogComponent, {
			data: {
				requiresPassword,
				authProvider
			}
		});

		// If we require a password, then we don't need to check the response from the UnlinkSsoDialogComponent
		// If we don't require a password, then the UnlinkSsoDialogComponent already unlinked and returned the response
		dialogRef.afterClosed().subscribe({
			next: (dialogUserResponse) => {
				if (requiresPassword) {
					const pwdDialogRef = this.dialog.open(ChangePasswordDialogComponent, {
						data: {
							user: this.user,
							currentUser: this.user,
							isUnlinking: true,
							authProvider
						}
					});
					pwdDialogRef.afterClosed().subscribe((result) => {
						if (result) {
							this.updateUserAuthAlias(result);
						}
					});
				} else if (dialogUserResponse) {
					this.updateUserAuthAlias(dialogUserResponse);
				}
			}
		});
	}

	public hasSsoProvider (): boolean {
		return this.ssoProviders.length > 0;
	}

	public hasProvider (provider: string): boolean {
		return this.ssoProviders.some((ssoProvider) => ssoProvider.provider_name === provider);
	}

	public hasAuthProviderGoReact (): boolean {
		return this.user.auth_aliases?.some((alias) =>
			alias.auth_provider.provider_name.toLowerCase() === AuthProviders.GOREACT
		);
	}

	public isEnvironmentLTI () {
		const environment: any = this.environmentVarsService.get(EnvironmentVarsService.VAR.ENVIRONMENT) || {};
		return environment.name === ENVIRONMENTS.LTI;
	}

	private initializeForm () {
		this.form = this.fb.group({
			userFirstName: new FormControl(this.user.first_name, [Validators.required]),
			userLastName: new FormControl(this.user.last_name, [Validators.required]),
			userEmail: new FormControl(this.user.email),
			language: new FormControl(this.currentLanguage)
		});

		this.form.valueChanges.pipe(
			debounceTime(DEBOUNCE_TIME),
			distinctUntilChanged()
		).subscribe((val) => {
			const hasNameChanges = val.userFirstName !== this.user.first_name ||
				val.userLastName !== this.user.last_name;
			let hasLanguageChanges = false;
			if (!this.hideLanguage) {
				hasLanguageChanges = val.language.code !== this.user.language;
			}
			this.shouldShowSaveButton$.next(hasNameChanges || hasLanguageChanges);
		});
	}

	private fetchLanguage () {
		this.ngxUserService.getLanguage().subscribe((languages) => {
			const lang = languages.user_language || languages.browser_language;
			this.languages = clientSettings.languages;
			this.currentLanguage = this.languages.find((l) => l.code === lang) ||
				this.languages.find((l) => l.code === this.$translate.fallbackLanguage());
			this.user.language = this.currentLanguage.code;
			this.form.get('language').setValue(this.currentLanguage);
		});
	}

	private setSsoProviders () {
		this.ssoProviders = this.user.auth_aliases
			?.map((alias) => alias.auth_provider)
			?.filter((provider) => provider.auth_type === 'oauth' && provider.is_web_app) ?? [];
	}

	private updateUserAuthAlias (userResponse) {
		if (userResponse.hasOwnProperty('auth_aliases')) {
			this.user.auth_aliases = userResponse.auth_aliases;
			this.userService.setCurrentUser(this.user);
			this.setSsoProviders();
			// We aren't triggering change detection updating a non-primitive type when using OnPush
			this.cdr.detectChanges();
		}
	}
}
