<form [formGroup]="form" (ngSubmit)="submit()" #content>
	<div class="modal-dialog edit-transcription-speakers-dialog-container">
		<div class="modal-content" cdkTrapFocus>
			<div class="goreact-modal-header">
				<h4 class="modal-title" translate="edit-transcription-speakers_title"></h4>
				<button type="button" class="close-btn" (click)="cancel()" [attr.aria-label]="'common_close' | translate">
					<i class="ficon-app-x"></i>
				</button>
			</div>
			<div class="goreact-modal-body">
				<ng-container *ngFor="let speaker of data.speakers;">
					<mat-form-field appearance="outline">
						<mat-label>
							<ng-container *ngIf="speaker.length === 1">
								{{ 'transcript-viewer_speaker' | translate : { speaker: speaker } }}
							</ng-container>
							<ng-container *ngIf="speaker.length > 1">
								{{ speaker }}
							</ng-container>
						</mat-label>
						<input type="text" matInput [formControlName]="speaker" maxlength="25"/>
						<mat-error *ngIf="form.get(speaker).hasError('required')">
							{{ 'edit-transcription-speakers_required' | translate }}
						</mat-error>
					</mat-form-field>
				</ng-container>
			</div>
			<div class="goreact-modal-footer">
				<button class="cancel-button tertiary-btn" (click)="cancel()" translate="common_cancel"></button>
				<button class="confirm-button primary-btn"
					type="submit"
					[disabled]="submitting$ | async"
					[focusOnFirstInvalidField]="{ formGroup: form, formWrapper: content }">
					{{
						((submitting$ | async) ?
							'feedback-session_controller-saving' :
							'common_save') | translate
					}}
				</button>
			</div>
		</div>
	</div>
</form>
