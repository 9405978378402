<div
	class="ngx-rich-text"
	[ngClass]="{
		'toolbar-below': toolbarBelow === 'withBorder',
		'toolbar-below-no-top-border': toolbarBelow === 'withoutBorder',
		'toolbar-extra-below': toolbarBelow === 'extra'
	}">
	<editor
		[attr.id]="uniqueId"
		[init]="tinymceOptions"
		(onBlur)="blurHandler($event)"
		(onFocus)="focusHandler($event)"
		(onInit)="initHandler($event)"
		(onKeyUp)="keyupHandler($event)"
		(onKeyDown)="keydownHandler($event)"
		[(ngModel)]="editorContent"
	></editor>
</div>
