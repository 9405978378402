import { Injectable } from '@angular/core';
import { StorageRefService } from './storage-ref.service';

@Injectable({
	providedIn: 'root'
})
export class GoStorageService {
	constructor (private storageRef: StorageRefService) {}

	/**
	 * Get JSON parsed value from storage at the specified key.
	 *
	 * Returns the string value if it cannot be parsed to JSON.
	 */
	public getItem (key: string, session = false): any {
		let item: string;
		if(session) {
			item = this.storageRef.sessionStorage.getItem(key);
		} else {
			item = this.storageRef.localStorage.getItem(key);
		}
		try {
			return JSON.parse(item);
		} catch {
			// Can't parse it, just return it
			return item;
		}
	}

	/**
	 * Set the specified key with the specified value.
	 *
	 * @param key
	 * @param value
	 * @param session
	 */
	public setItem (key: string, value: any, session: boolean = false) {
		if (session) {
			this.storageRef.sessionStorage.setItem(key, JSON.stringify(value));
		} else {
			this.storageRef.localStorage.setItem(key, JSON.stringify(value));
		}
	}

}
