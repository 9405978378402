import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Product } from 'go-modules/services/group/product';

export interface ExtendedProduct extends Product {
	actionTranslation?: string;
	hasAction?: boolean;
	upgradeRequestSent?: boolean;
}

@Component({
	selector: 'product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
	@Input() public product: ExtendedProduct;
	@Input() public actionTranslation: string;
	@Input() public isLast: boolean;
	@Input() public isDisabled: boolean;
	@Output() public action: EventEmitter<void> = new EventEmitter<void>();

	public digitsInfo: string;

	constructor () {}

	public getPrice (): number {
		return parseFloat(this.product.price);
	}

	public getUploadSize (): string {
		return `${this.product.licenseProduct.upload_limit_mb / 1024}`;
	}

	public getButtonClass () {
		const isSecondaryBtn = this.actionTranslation === 'license-plan-upgrade_renew_plan' ||
			(this.actionTranslation === 'common_purchase' && !this.isLast);

		return {
			'request-sent': this.product.upgradeRequestSent,
			'primary-btn': !isSecondaryBtn,
			'secondary-btn': isSecondaryBtn
		};
	}
}
