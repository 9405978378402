<div class="speed-grader-container">
	<button
		mat-icon-button
		class="left speed-grader-btn"
		wcag-ignore
		[attr.aria-label]="'feedback-session-speed-grader_previous' | translate: {
			current: getCurrentIndex() + 1,
			all: total
		}"
		[disabled]="shouldDisableSpeedGraderButtons() || shouldDisablePreviousBtn()"
		(click)="previous()">
		<i class="ficon-app-chevron-left"></i>
	</button>

	<small
		translate="feedback-session-speed-grader_of"
		[translateParams]="{
			current: getCurrentIndex() + 1,
			all: total
		}"
	></small>

	<button
		mat-icon-button
		class="right speed-grader-btn"
		wcag-ignore
		[attr.aria-label]="'feedback-session-speed-grader_next' | translate: {
			current: getCurrentIndex() + 1,
			all: total
		}"
		[disabled]="shouldDisableSpeedGraderButtons()"
		(click)="next()">
		<i class="ficon-app-chevron-right"></i>
	</button>
</div>
